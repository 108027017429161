import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

interface SubmitDataIssue {
  issue_type: string;
  provider_id: string;
  new_data?: object;
  comments?: string;
}

const useSubmitDataIssue = () => {
  return useMutation(
    [QUERY_KEYS.submitDataIssue],
    async (data: SubmitDataIssue) => {
      return await request({
        url: `/v1/account/data-issues/`,
        method: 'POST',
        data,
      });
    }
  );
};

export default useSubmitDataIssue;
