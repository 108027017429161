import { request } from 'src/api/request';

export const getVolumeByLocation = async (provider_id, params) => {
  if (!provider_id) return;
  return request({
    url: `/v1/providers/${provider_id}/volume-by-location${params}`,
    method: 'GET',
    params,
  });
};
