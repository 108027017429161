import { useState, useEffect, useMemo, useRef } from 'react';
import { styled, ClickAwayListener } from '@mui/material';
import {
  useNotification,
  useProspectSearch,
  useTable,
  useLogging,
} from 'src/context';
import {
  Dropdown,
  MenuButton,
  Menu,
  Box,
  Button,
  Typography,
  MenuItem,
  Tooltip,
  FormHelperText,
} from '@mui/joy';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { grey } from '@mui/material/colors';
import Selector from '../Selector';
import SelectorLabel from '../SelectorLabel';
import DateConfirmationDialog from '../DateConfirmationDialog';
import CheckIcon from '@mui/icons-material/Check';
import { sentenceCase } from 'src/utils';

const StyledMenuButton = styled(MenuButton)(({ theme }) => ({
  width: '100%',
  minWidth: '140px',
  flexShrink: 0,
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '1rem',
  lineHeight: '1.25rem',
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: '0.01071em',
  color: '#32383e',
  backgroundColor: '#fbfcfe',
  borderRadius: '0.5rem',
}));

const StyledMenuItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  fontSize: '1rem',
  fontWeight: 500,
  padding: '0.5rem 1rem',
  maxWidth: '21rem',
}));

interface VolumeDateToggleProps {
  table: string;
  enabledSearchIds: number[] | string[];
}

const VolumeDateToggle = ({
  table,
  enabledSearchIds,
}: VolumeDateToggleProps) => {
  const log = useLogging();
  const isInitialRender = useRef(true);
  const { prospectMode } = useProspectSearch();
  const { setNotification } = useNotification();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  // If every_table is selected, then this must apply to all tables.
  // If this_table is selected, then this must apply to the current table and will have a key of the table id.
  // For discovery, we don't want to show the trending button
  const isTableDiscovery = table === 'discovery';
  const { dateRange, setDateRange } = useTable();
  const currentTable = useMemo(() => {
    return dateRange?.every_table ? dateRange?.every_table : dateRange[table];
  }, [dateRange, table]);

  const initialTrending =
    currentTable?.isTrending && !isTableDiscovery
      ? currentTable?.isTrending
      : false;

  const [lte, setLte] = useState(currentTable?.lte || '2024');
  const [gte, setGte] = useState(currentTable?.gte || '2019');
  const [isTrending, setIsTrending] = useState(initialTrending || false);
  const [range, setRange] = useState(currentTable?.range || 'every_table');
  const [showDialog, setShowDialog] = useState(false);
  const [showErrors, setShowErrors] = useState({
    dateRange: false,
  });

  useEffect(() => {
    if (currentTable || currentTable?.range === 'this_table') return;
    // This means that there is a table set, but it's not this table and it's not every_table
    // so we need to make sure we set values for this table
    setLte('2024');
    setGte('2019');
    setIsTrending(false);
    setRange('this_table');
  }, [currentTable]);

  useEffect(() => {
    if (enabledSearchIds.length > 1 || enabledSearchIds.length === 0) {
      setIsTrending(false);
    }
  }, [enabledSearchIds]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (Object.keys(dateRange)?.length === 0) return;
    const showTheDialog = currentTable && currentTable?.range !== range;

    if (showTheDialog) {
      setShowDialog(true);
    } else {
      updateDateRange();
    }
  }, [lte, gte, isTrending, range]);

  // If prospect mode is sending or receiving, we need to disable 2019 and 2020 as it's not available
  useEffect(() => {
    if (prospectMode !== 'code_volume') {
      if (['2019', '2020'].includes(lte)) {
        setLte('2024');
      }
      if (['2019', '2020'].includes(gte)) {
        setGte('2021');
      }
    }
  }, [prospectMode]);

  const newOptions = useMemo(() => {
    return VOLUME_SELECT_OPTIONS.map((option) => {
      const isDisabled =
        prospectMode !== 'code_volume' &&
        (option.value === '2019' || option.value === '2020');

      return {
        ...option,
        disabled: isDisabled,
      };
    });
  }, [prospectMode]);

  const isTrendingDisabled = useMemo(() => {
    return (
      enabledSearchIds.length === 0 ||
      enabledSearchIds.length > 1 ||
      isTableDiscovery
    );
  }, [enabledSearchIds?.length, isTableDiscovery]);

  const tooltipTitle = useMemo(() => {
    // 1. disabled if table is discovery
    // 2. disabled if more than 1 search is selected
    // 3. disabled if no search is selected

    if (isTableDiscovery) {
      return 'Not available for Discovery.';
    } else if (enabledSearchIds.length > 1) {
      return 'Not available when multiple searches are applied.';
    } else if (enabledSearchIds.length === 0) {
      return 'Not available when no search is applied.';
    }
  }, [enabledSearchIds?.length, isTableDiscovery]);

  function toggleMenu(e) {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  function toggleMenu2(e) {
    e.stopPropagation();
    setAnchorEl2(anchorEl2 ? null : e.currentTarget);
  }

  function handleConfirm() {
    setShowDialog(false);
    updateDateRange();
  }

  function validateValues() {
    let isValid = false;

    const isLteValid = parseInt(lte) >= parseInt(gte);
    const isGteValid = parseInt(gte) <= parseInt(lte);

    if (currentTable?.lte !== lte) {
      isValid = true;
    }
    if (currentTable?.gte !== gte) {
      isValid = true;
    }
    if (currentTable?.isTrending !== isTrending) {
      isValid = true;
    }
    if (currentTable?.range !== range) {
      isValid = true;
    }

    if (isLteValid && isGteValid) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  }

  function updateDateRange() {
    if (!validateValues() || Object.keys(dateRange)?.length === 0) return;
    let newDateRange = { ...dateRange };
    const showNotification = currentTable?.range !== range;
    if (range === 'this_table' && table) {
      // remove the every_table key if it exists
      const { every_table, ...rest } = newDateRange;
      newDateRange = {
        ...rest,
        [table]: {
          gte,
          lte,
          isTrending,
          range,
        },
      };

      if (showNotification) {
        setNotification({
          title: 'Date Range Updated',
          message: `Default year range set for ${sentenceCase(table)}.`,
          type: 'success',
        });
      }
    } else if (range === 'every_table') {
      newDateRange = {
        every_table: {
          gte,
          lte,
          isTrending,
          range,
        },
      };

      if (showNotification) {
        setNotification({
          title: 'Date Range Updated',
          message: 'Default year range set.',
          type: 'success',
        });
      }
    }

    setDateRange(newDateRange);
    setShowErrors({ dateRange: false });
  }

  function updateTrending() {
    setAnchorEl(null);
    setIsTrending(!isTrending);
    const event = isTrending ? 'Stop Trending' : 'View Trending';
    log.event(event, {
      source: table,
    });
  }

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box sx={{ width: '100%' }}>
        <SelectorLabel label="Year Range" />
        <Dropdown>
          <StyledMenuButton
            variant="outlined"
            color={isTrending ? 'primary' : 'neutral'}
            endDecorator={
              <UnfoldMoreIcon
                fontSize="small"
                sx={{ marginRight: '-0.5rem' }}
              />
            }
            onClick={toggleMenu}
          >
            <Typography
              color={isTrending ? 'primary' : null}
              sx={{
                marginLeft: '-0.25rem',
              }}
            >
              {isTrending ? 'Trending' : `${gte} - ${lte}`}
            </Typography>
          </StyledMenuButton>
          <Menu
            placement="bottom-end"
            open={!!anchorEl}
            anchorEl={anchorEl}
            sx={{
              boxShadow: 'sm',
              flexGrow: 0,
              maxHeight: 300,
              width: '264px', // Fixed width is bad umkay
              overflow: 'auto',
              zIndex: isTableDiscovery ? 9999 : 1000, // This would not show any other way on the result lists
            }}
          >
            <StyledMenuItem>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.25rem',
                  paddingBottom: '0.25rem',
                  borderBottom: `1px solid ${grey[300]}`,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '0.5rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Selector
                    label="From"
                    options={newOptions}
                    value={gte}
                    setValue={(value) => {
                      const isGteValid = parseInt(value) <= parseInt(lte);
                      if (!isGteValid) {
                        setShowErrors({ dateRange: true });
                        setGte(value);
                        return;
                      }
                      setGte(value);
                      setShowErrors({ dateRange: false });
                      log.event('Year Range Updated', {
                        source: table,
                        side: 'From',
                        when: new Date().toLocaleString(),
                      });
                    }}
                  />
                  <Typography
                    level="body-md"
                    sx={{
                      marginTop: '1.5rem',
                    }}
                  >
                    -
                  </Typography>
                  <Selector
                    label="Through"
                    options={newOptions}
                    value={lte}
                    setValue={(value) => {
                      const isLteValid = parseInt(value) >= parseInt(gte);
                      if (!isLteValid) {
                        setShowErrors({ dateRange: true });
                        setLte(value);
                        return;
                      }
                      setLte(value);
                      setShowErrors({ dateRange: false });
                      log.event('Year Range Updated', {
                        source: table,
                        side: 'Through',
                        when: new Date().toLocaleString(),
                      });
                    }}
                  />
                </Box>
                {showErrors.dateRange && (
                  <FormHelperText>
                    <Typography level="body-xs" sx={{ color: 'red' }}>
                      Your From selection is more recent than your To selection.
                    </Typography>
                  </FormHelperText>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Dropdown>
                    <Button
                      variant="plain"
                      color="neutral"
                      size="sm"
                      endDecorator={
                        <ArrowDropDownIcon
                          fontSize="small"
                          sx={{ margin: '-0.5rem' }}
                        />
                      }
                      onClick={toggleMenu2}
                      disabled={isTrending}
                      aria-label="Set as Default Range"
                    >
                      <Typography
                        level="body-sm"
                        sx={{ color: '#555E68', fontWeight: 500 }}
                      >
                        Set as Default Range
                      </Typography>
                    </Button>
                    <Menu
                      placement="bottom-end"
                      open={!!anchorEl2}
                      anchorEl={anchorEl2}
                      sx={{
                        boxShadow: 'sm',
                        flexGrow: 0,
                        zIndex: 9999,
                        width: '220px',
                      }}
                    >
                      <MenuItem
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                        onClick={() => {
                          setAnchorEl2(null);
                          setRange('every_table');
                        }}
                      >
                        <Typography level="body-md">Everywhere</Typography>
                        {range === 'every_table' && (
                          <CheckIcon color="success" fontSize="small" />
                        )}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                        onClick={() => {
                          setAnchorEl2(null);
                          setRange('this_table');
                        }}
                      >
                        <Typography level="body-md">
                          For {sentenceCase(table)} only
                        </Typography>
                        {range === 'this_table' && (
                          <CheckIcon color="success" fontSize="small" />
                        )}
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: '0.5rem 0',
                }}
              >
                <Typography
                  level="body-xs"
                  sx={{
                    fontWeight: 700,
                    color: '#171A1C',
                    lineHeight: '1.245rem',
                  }}
                >
                  Compare Years
                </Typography>
                <Typography
                  level="body-xs"
                  sx={{
                    color: '#555E68',
                    fontSize: '0.75rem',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '1rem',
                  }}
                >
                  View year-over-year trends for the range selected.
                </Typography>
              </Box>
              <Tooltip
                placement="top-end"
                title={tooltipTitle}
                variant="soft"
                color="warning"
                arrow={true}
                disableFocusListener={!isTrendingDisabled}
                disableHoverListener={!isTrendingDisabled}
                disableTouchListener={!isTrendingDisabled}
                sx={{
                  mt: '0.5rem',
                  zIndex: 9999,
                }}
              >
                <span>
                  <Button
                    startDecorator={<TrendingUpIcon />}
                    variant="soft"
                    color={isTrending ? 'danger' : 'primary'}
                    fullWidth
                    onClick={updateTrending}
                    disabled={isTrendingDisabled}
                    aria-label="Trending"
                    size="sm"
                  >
                    {isTrending ? 'Stop Trending' : 'View Trend'}
                  </Button>
                </span>
              </Tooltip>
            </StyledMenuItem>
          </Menu>
        </Dropdown>
        <DateConfirmationDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          onConfirm={handleConfirm}
          range={range}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default VolumeDateToggle;
