import {
  useEffect,
  createContext,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import { useAuth } from 'src/context';
import { useGetMedTerritory } from 'src/hooks';
const MedScoutMapActionTypes: { [key: string]: string } = {
  SET_CURRENT_TERRITORY: 'SET_CURRENT_TERRITORY',
  SET_IS_EDITING: 'SET_IS_EDITING',
  SET_IS_CREATING: 'SET_IS_CREATING',
  SET_TERRITORY_POLYGONS: 'SET_TERRITORY_POLYGONS',
  SET_ZOOM: 'SET_ZOOM',
  SET_CENTER: 'SET_CENTER',
  SET_BOUNDS: 'SET_BOUNDS',
  SET_MAP_BOUNDS: 'SET_MAP_BOUNDS',
  SET_DRAWING_MODE: 'SET_DRAWING_MODE',
  SET_RESULTS_LIST_PARAMS: 'SET_RESULTS_LIST_PARAMS',
  SET_HOVERED_FEATURE: 'SET_HOVERED_FEATURE',
  SET_CLICKED_FEATURES: 'SET_CLICKED_FEATURES',
  SET_CURRENT_OVERLAY: 'SET_CURRENT_OVERLAY',
  SET_ADHOC_TERRITORY: 'SET_ADHOC_TERRITORY',
};

type MedScoutMapState = {
  currentTerritory: MedScout.Territory | null;
  isEditing: boolean;
  isCreating: boolean;
  territoryPolygons: {
    STATE: string[];
    COUNTY: string[];
    ZIPCODE: string[];
    DRAW: string[];
  };
  drawingMode: 'STATE' | 'COUNTY' | 'ZIPCODE' | 'DRAW' | null;
  zoom: number;
  center: {
    lat: number;
    lng: number;
  };
  bounds: [number[], number[]];
  mapBounds: [number[], number[], number[], number[], number[]] | null;
  resultsListParams: null;
  hoveredFeature: string | null;
  clickedFeatures: string[];
  currentOverlay: any;
  adhocTerritory: MedScout.Territory | null;
};

const MedScoutMapState: MedScoutMapState = {
  currentTerritory: null,
  isEditing: false,
  isCreating: false,
  territoryPolygons: {
    STATE: [],
    COUNTY: [],
    ZIPCODE: [],
    DRAW: [],
  },
  drawingMode: 'STATE',
  zoom: 4,
  center: {
    lat: 39.8097,
    lng: -98.5556,
  },
  bounds: [
    [-179.999999, -85.05112877980659],
    [179.999999, 85.05112877980659],
  ],
  mapBounds: null,
  resultsListParams: null,
  hoveredFeature: null,
  clickedFeatures: [],
  currentOverlay: null,
  adhocTerritory: null,
};

const MedScoutMapContext = createContext<{
  state: MedScoutMapState;
  dispatch: React.Dispatch<any>;
}>({
  state: MedScoutMapState,
  dispatch: () => null,
});

const MedScoutMapReducer = (state: MedScoutMapState, action: any) => {
  switch (action.type) {
    case MedScoutMapActionTypes.SET_CURRENT_TERRITORY: {
      return {
        ...state,
        currentTerritory: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_IS_EDITING: {
      return {
        ...state,
        isEditing: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_IS_CREATING: {
      return {
        ...state,
        isCreating: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_TERRITORY_POLYGONS: {
      return {
        ...state,
        territoryPolygons: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_DRAWING_MODE: {
      return {
        ...state,
        drawingMode: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_ZOOM: {
      return {
        ...state,
        zoom: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_CENTER: {
      return {
        ...state,
        center: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_BOUNDS: {
      return {
        ...state,
        bounds: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_MAP_BOUNDS: {
      return {
        ...state,
        mapBounds: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_RESULTS_LIST_PARAMS: {
      return {
        ...state,
        resultsListParams: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_HOVERED_FEATURE: {
      return {
        ...state,
        hoveredFeature: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_CLICKED_FEATURES: {
      return {
        ...state,
        clickedFeatures: action.payload,
      };
    }
    case MedScoutMapActionTypes.SET_CURRENT_OVERLAY: {
      return {
        ...state,
        currentOverlay: action.payload,
      };
    }

    case MedScoutMapActionTypes.SET_ADHOC_TERRITORY: {
      return {
        ...state,
        adhocTerritory: action.payload,
      };
    }

    default:
      return state;
  }
};

export const MedScoutMapProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(MedScoutMapReducer, MedScoutMapState);
  const { user } = useAuth();
  const { data: territory } = useGetMedTerritory(user.last_territory?.id);

  useEffect(() => {
    if (!territory) return;
    dispatch({
      type: MedScoutMapActionTypes.SET_CURRENT_TERRITORY,
      payload: territory,
    });
  }, [territory]);

  return (
    <MedScoutMapContext.Provider value={{ state, dispatch }}>
      {children}
    </MedScoutMapContext.Provider>
  );
};

export const useMedScoutMap = () => {
  const context = useContext(MedScoutMapContext);

  if (!context) {
    throw new Error('useMedScoutMap must be used within a MedScoutMapProvider');
  }

  const {
    state: {
      bounds,
      center,
      clickedFeatures,
      currentOverlay,
      currentTerritory,
      drawingMode,
      hoveredFeature,
      isCreating,
      isEditing,
      mapBounds,
      resultsListParams,
      territoryPolygons,
      zoom,
      adhocTerritory,
    },
    dispatch,
  } = context;

  const setCurrentTerritory = (territory: MedScout.Territory) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_CURRENT_TERRITORY,
      payload: territory,
    });
  };
  const setIsEditing = (isEditing: boolean) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_IS_EDITING,
      payload: isEditing,
    });
  };
  const setIsCreating = (isCreating: boolean) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_IS_CREATING,
      payload: isCreating,
    });
  };
  const setTerritoryPolygons = (polygons) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_TERRITORY_POLYGONS,
      payload: polygons,
    });
  };
  const setDrawingMode = (
    drawingMode: 'STATE' | 'COUNTY' | 'ZIPCODE' | 'DRAW' | null
  ) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_DRAWING_MODE,
      payload: drawingMode,
    });
  };
  const setZoom = (zoom: number) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_ZOOM,
      payload: zoom,
    });
  };

  const setCenter = (center: { lat: number; lng: number }) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_CENTER,
      payload: center,
    });
  };

  const setBounds = (bounds: [number[], number[]][]) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_BOUNDS,
      payload: bounds,
    });
  };

  const setMapBounds = (
    mapBounds: [number[], number[], number[], number[], number[]]
  ) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_MAP_BOUNDS,
      payload: mapBounds,
    });
  };

  const setResultsListParams = (params) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_RESULTS_LIST_PARAMS,
      payload: params,
    });
  };

  const setHoveredFeature = (feature) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_HOVERED_FEATURES,
      payload: feature,
    });
  };

  const setClickedFeatures = (features) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_CLICKED_FEATURES,
      payload: features,
    });
  };

  const setCurrentOverlay = (overlay) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_CURRENT_OVERLAY,
      payload: overlay,
    });
  };

  const setAdhocTerritory = (territory) => {
    dispatch({
      type: MedScoutMapActionTypes.SET_ADHOC_TERRITORY,
      payload: territory,
    });
  };

  return {
    adhocTerritory,
    bounds,
    center,
    clickedFeatures,
    currentOverlay,
    currentTerritory,
    drawingMode,
    hoveredFeature,
    isCreating,
    isEditing,
    mapBounds,
    resultsListParams,
    setAdhocTerritory,
    setBounds,
    setCenter,
    setClickedFeatures,
    setCurrentOverlay,
    setCurrentTerritory,
    setDrawingMode,
    setHoveredFeature,
    setIsCreating,
    setIsEditing,
    setMapBounds,
    setResultsListParams,
    setTerritoryPolygons,
    setZoom,
    territoryPolygons,
    zoom,
  };
};
