import useSWR from 'swr';
import { getCompanies } from './companiesApi';
import { QUERY_KEY } from '../constants';
import { useDashboard } from 'src/context';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const useCompanies = () => {
  const { companyQueryParams } = useDashboard();
  const searchParams = getSearchQueryParamsForAPI(companyQueryParams);

  return useSWR(
    [QUERY_KEY.COMPANIES, searchParams],
    () => getCompanies(searchParams),
    {
      keepPreviousData: true,
    }
  );
};

export default useCompanies;
