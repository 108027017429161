import { Typography } from '@mui/joy';
import React from 'react';

const Header = ({ field, ...props }) => {
  return (
    <Typography
      level="title-md"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '0.5rem',
      }}
    >
      {props?.colDef?.headerName}
    </Typography>
  );
};

export default Header;
