import { Box, Typography } from '@mui/joy';
import React from 'react';

const SecondaryAddressCell = ({ row }) => {
  return (
    <Box sx={{ width: '100%' }}>
      {row.secondary_addresses_v2?.map((address, index) => {
        const line2 = address.address_line_2
          ? `${address.address_line_2}, `
          : '';
        return (
          <Typography
            key={index}
            level="body-xs"
            sx={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: '0.5rem',
            }}
          >
            {`${address.address_line_1}, ${line2} ${address.city}, ${address.state}, ${address.zip_code}`}
          </Typography>
        );
      })}
    </Box>
  );
};

export default SecondaryAddressCell;
