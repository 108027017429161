import { request } from 'src/api/request';

function useGetExportedTerritory({
  territoryId,
}: {
  territoryId: number | string;
}) {
  return {
    getExport: async () => {
      return await request({
        url: `v1/accounts/territories/${territoryId}/export/`,
        method: 'GET',
        responseType: 'blob',
        rawResponse: true,
      }).then((res) => {
        // Extract filename from Content-Disposition header
        const filename = res?.headers['content-disposition']
          ?.split('filename=')[1]
          ?.split(';')[0]
          ?.replace(/["']/g, '');

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // Create an anchor element and set attributes for downloading
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename || 'export.csv'); // Use filename from Content-Disposition

        // Append to the document and trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        return filename;
      });
    },
  };
}

export default useGetExportedTerritory;
