import { useState, useEffect } from 'react';
import {
  Box,
  FormControlLabel,
  Typography,
  Menu,
  MenuItem,
  ListSubheader,
  Checkbox,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useLogging } from 'src/context';
import { grey } from '@mui/material/colors';
import {
  ContextCommands,
  AddToListButton,
  IconButton,
  ResultTypeSelector,
  VolumeSelectors,
} from 'src/components';
import { faArrowDownWideShort } from '@fortawesome/pro-solid-svg-icons';
import { useProspectSearch } from 'src/context';
import { sentenceCase } from 'src/utils';

interface ResultCommandsProps {
  hasResults;
  sortOptions;
  searchResults;
  setSortBy;
  loading;
  selectAllCheckboxRef;
  filteredResults;
  sortBy;
  multiSelectById;
  setMultiSelectById;
  multiAddClick;
}

const ResultCommands: React.FC<ResultCommandsProps> = ({
  hasResults,
  sortOptions,
  searchResults,
  setSortBy,
  loading,
  selectAllCheckboxRef,
  filteredResults,
  sortBy,
  multiSelectById,
  setMultiSelectById,
  multiAddClick,
}) => {
  const log = useLogging();
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLButtonElement>(
    null
  );
  const filterOpen = Boolean(filterAnchor);
  const searchResultsCount = searchResults?.count || 0;
  const { prospectVolumeType, prospectType } = useProspectSearch();

  const resultsText = prospectType === 'HCP' ? 'providers' : 'sites';
  const isChecked =
    Object.keys(multiSelectById).length === filteredResults.length;

  useEffect(() => {
    if (!prospectVolumeType) return;
    if (!sortBy?.key?.includes('claims') && !sortBy?.key?.includes('patients'))
      return;
    const findSortOptionsByKey = (keyToFind) => {
      for (const key in sortOptions) {
        const category = sortOptions[key];
        const sortOption = category.sortOptions.find(
          (option) => option.key === keyToFind
        );
        if (sortOption) {
          return sortOption;
        }
      }
      return null; // Return null if the key is not found
    };

    if (sortBy.key?.includes('highest')) {
      const foundSortOption = findSortOptionsByKey(
        `highest_${prospectVolumeType}`
      );
      if (foundSortOption) {
        setSortBy(foundSortOption);
      }
    } else if (sortBy.key?.includes('lowest')) {
      const foundSortOption = findSortOptionsByKey(
        `lowest_${prospectVolumeType}`
      );
      if (foundSortOption) {
        setSortBy(foundSortOption);
      }
    }
  }, [prospectVolumeType, sortOptions]);

  return (
    <Box
      sx={{
        position: 'sticky',
        top: -1,
        zIndex: 2,
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box
        className="d-flex"
        style={{
          alignItems: 'center',
          borderBottom: `1px solid ${grey[300]}`,
          padding: '0.5rem 1rem',
          backgroundColor: 'white',
          top: -1,
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ width: '32px' }} />
          <div
            style={{
              width: '15rem',
              height: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <ContextCommands
              show={Object.keys(multiSelectById).length > 0}
              sx={{ position: 'absolute' }}
            >
              <AddToListButton
                onClick={() => {
                  multiAddClick();
                }}
              />
            </ContextCommands>
            <ContextCommands
              show={Object.keys(multiSelectById).length === 0}
              sx={{ position: 'absolute' }}
            >
              {Object.keys(multiSelectById).length === 0 && (
                <ResultTypeSelector />
              )}
            </ContextCommands>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'white',
        }}
      >
        <Grid container sx={{ p: '8px' }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              p: '0.5rem',
            }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', pb: '0.5rem' }}>
                <CircularProgress size="1.5rem" />
              </Box>
            ) : (
              <>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: '1.25rem',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    pb: 0,
                  }}
                >
                  {searchResultsCount.toLocaleString('en-US')} {resultsText}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    color: 'text.secondary',
                  }}
                >
                  {`by ${
                    sortBy?.label ||
                    `Highest ${sentenceCase(prospectVolumeType)}`
                  }`}
                </Typography>
              </>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              p: '0 0.5rem',
            }}
          >
            <VolumeSelectors table="discovery" />
            <Box display="flex">
              {sortOptions && Object.keys(sortOptions)?.length > 0 && (
                <>
                  <IconButton
                    id="sort-dropdown"
                    icon={faArrowDownWideShort}
                    onClick={(e) => setFilterAnchor(e.currentTarget)}
                    label="Sort results"
                    aria-haspopup="true"
                    aria-expanded={filterOpen ? 'true' : undefined}
                    aria-controls={filterOpen ? 'sort-menu' : undefined}
                    style={{
                      width: '1.875rem',
                      height: '1.875rem',
                      marginTop: '1.5rem',
                    }}
                  />
                  <Menu
                    id="sort-menu"
                    open={filterOpen}
                    anchorEl={filterAnchor}
                    onClose={() => setFilterAnchor(null)}
                    sx={{
                      mt: '0.5rem',
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'sort-dropdown',
                    }}
                  >
                    {sortOptions.map((option) => {
                      return [
                        option.sortHeader && (
                          <ListSubheader>{option.sortHeader}</ListSubheader>
                        ),
                        option.sortOptions.map((sortOption) => {
                          return (
                            <MenuItem
                              key={sortOption.key}
                              onClick={() => {
                                log.event('resultsSortedOnSearch', {
                                  value: sortOption.key,
                                });
                                setSortBy(sortOption);
                                setFilterAnchor(null);
                              }}
                            >
                              {sortOption.label}
                            </MenuItem>
                          );
                        }),
                      ];
                    })}
                  </Menu>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: 'primary.light',
          p: '0.25rem 0.75rem',
          mb: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <FormControlLabel
          sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }}
          control={
            <Checkbox
              size="small"
              ref={selectAllCheckboxRef}
              checked={isChecked}
              disabled={loading || !hasResults}
              onChange={(e) => {
                selectAllCheckboxRef.current.indeterminate = false;
                if (e.currentTarget.checked) {
                  log.event('searchMultiSelectModeActivated');
                  setMultiSelectById(
                    filteredResults.reduce((acc, result) => {
                      acc[result.provider_id] = result;
                      return acc;
                    }, {})
                  );
                } else {
                  setMultiSelectById({});
                }
              }}
              id="select-all-results-checkbox"
              sx={{ py: '0.25rem' }}
            />
          }
          label="Select page"
        />
        <Typography>Volumes</Typography>
      </Box>
    </Box>
  );
};

export default ResultCommands;
