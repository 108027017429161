import { useMemo } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  List,
  styled,
} from '@mui/material';
import _ from 'lodash';
import { useProspectSearch, useLogging, useAuth } from 'src/context';
import {
  useGetSavedSearches,
  useEditSavedSearch,
  useDeleteSavedSearch,
} from 'src/hooks';
import { grey } from '@mui/material/colors';
import { InlineEdit, IconButton } from 'src/components';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const StyledListSubheader = styled(ListSubheader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.15rem;
`;
const SavedSearchItem = styled(ListItem)(() => ({
  '& .MuiListItemText-primary': {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
}));
const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  padding: '0 0.5rem',
  margin: 0,
  borderLeft: selected
    ? `4px solid ${theme.palette.highlight.main}`
    : '4px solid transparent',
  '&.Mui-selected': {
    backgroundColor: theme.palette.highlight.light,
  },
  '&:hover': {
    backgroundColor: theme.palette.highlight.light,
  },
}));
const StyledList = styled(List)(() => ({
  paddingTop: 0,
  '& .MuiListSubheader-root': {
    paddingLeft: '0.75rem',
    lineHeight: '1.5rem',
    borderBottom: `1px ${grey[100]} solid`,
  },
}));

const ProspectSearchItem: React.FC<{
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}> = ({ children, disabled, active, onClick }) => {
  return (
    <SavedSearchItem disablePadding>
      <StyledListItemButton
        disabled={disabled}
        selected={active}
        onClick={onClick}
      >
        <ListItemText>{children}</ListItemText>
      </StyledListItemButton>
    </SavedSearchItem>
  );
};

/**
 * SearchMenu
 * @description The global prospect search (saved search) menu.
 *
 * @return {*}
 */
const SearchMenu = () => {
  const log = useLogging();
  const { prospectSearch, setProspectSearch, setProspectFilters } =
    useProspectSearch();
  const { isLoading: isEditingSearch, mutateAsync: editSavedSearch } =
    useEditSavedSearch();
  const { isLoading: isDeletingSearch, mutateAsync: deleteSavedSearch } =
    useDeleteSavedSearch();
  const { data: savedSearches = [] } = useGetSavedSearches();
  const { user } = useAuth();

  const groupedSavedSearches = useMemo(
    () => ({
      org: savedSearches
        .filter((search) => search.org_search)
        .sort((a, b) => a.name.localeCompare(b.name)),
      personal: savedSearches
        .filter((search) => !search.org_search)
        .sort((a, b) => a.name.localeCompare(b.name)),
    }),
    [savedSearches]
  );

  function handleNewSearch() {
    setProspectFilters([]);
    setProspectSearch(null);
  }

  function handleSelect(prospectSearch) {
    setProspectSearch(prospectSearch);
    log.event('selectSavedSearch', {
      id: prospectSearch.id,
      name: prospectSearch.name,
    });
  }

  function handleSave(newName, id) {
    return editSavedSearch({
      searchId: id,
      name: newName,
    }).then((updatedSearch) => {
      if (id === prospectSearch?.id) {
        setProspectSearch(updatedSearch);
      }
      log.event('savedSearchEditSuccess');
    });
  }

  function handleDelete(id) {
    if (!confirm('Are you sure you want to delete this prospect search?')) {
      return;
    }
    return deleteSavedSearch({
      searchId: id,
    }).then(() => {
      if (id === prospectSearch?.id) {
        setProspectSearch(null);
      }
      log.event('savedSearchDeleteSuccess');
    });
  }
  const canEdit =
    user?.company?.manager ||
    user?.permissions?.is_manager ||
    user?.is_superuser;

  return (
    <StyledList>
      <StyledListSubheader aria-label="personal prospect searches">
        <span>Personal</span>
        <IconButton
          icon={faPlus}
          label="New search"
          sx={{ p: '0.25rem' }}
          onClick={handleNewSearch}
          aria-label="New Saved Search"
        />
      </StyledListSubheader>
      {_.isEmpty(groupedSavedSearches.personal) && (
        <ProspectSearchItem disabled>None Available</ProspectSearchItem>
      )}
      {_.map(groupedSavedSearches.personal, (savedSearch) => {
        return (
          <ProspectSearchItem
            key={savedSearch.id}
            active={savedSearch.id === prospectSearch?.id}
            onClick={() => handleSelect(savedSearch)}
            aria-label={savedSearch.search_name || savedSearch.name}
          >
            <InlineEdit
              iconOnly
              fullWidth
              onSave={(newName) => handleSave(newName, savedSearch.id)}
              onDelete={() => handleDelete(savedSearch.id)}
              isSaving={isEditingSearch || isDeletingSearch}
            >
              {savedSearch.search_name || savedSearch.name}
            </InlineEdit>
          </ProspectSearchItem>
        );
      })}
      <StyledListSubheader aria-label="company prospect searches">
        <span>Company</span>
        <IconButton
          icon={faPlus}
          sx={{ p: '0.25rem' }}
          label="New search"
          onClick={handleNewSearch}
        />
      </StyledListSubheader>
      {_.isEmpty(groupedSavedSearches.org) && (
        <ProspectSearchItem disabled>None Available</ProspectSearchItem>
      )}
      {_.map(groupedSavedSearches.org, (savedSearch) => {
        return (
          <ProspectSearchItem
            key={savedSearch.id}
            active={savedSearch.id === prospectSearch?.id}
            onClick={() => handleSelect(savedSearch)}
            aria-label={savedSearch.search_name || savedSearch.name}
          >
            <InlineEdit
              iconOnly
              fullWidth
              disabled={!canEdit}
              onSave={(newName) => handleSave(newName, savedSearch.id)}
              onDelete={() => handleDelete(savedSearch.id)}
              isSaving={isEditingSearch || isDeletingSearch}
            >
              {savedSearch.search_name || savedSearch.name}
            </InlineEdit>
          </ProspectSearchItem>
        );
      })}
    </StyledList>
  );
};

export default SearchMenu;
