import React from 'react';
import { Typography } from '@mui/joy';

const CityStateCell = ({ row }) => {
  return (
    <Typography level="body-sm" sx={{ paddingRight: '0.5rem' }}>
      {[row.city, row.state].join(', ')}
    </Typography>
  );
};

export default CityStateCell;
