import React from 'react';
import { styled, Box, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BladeCommandButton from './BladeCommandButton';

const BladeHeaderComponent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: theme.palette?.fill?.main,
  color: '#fff',
  position: 'relative',
  height: '4rem',
  padding: '0.5rem 0 0.5rem 1rem',
}));

const BladeTitle = styled(Typography)({
  flexGrow: 1,
  '&.MuiTypography-root': {
    color: '#fff',
    fontWeight: 500,
  },
});

const BladeCommandsComponent = styled(Stack)(({ theme }) => ({
  gap: '0.125rem',
  padding: `0 ${theme.spacing(1)}`,
  alignSelf: 'flex-start',
  '& .MuiMenuItem-root': {
    padding: 0,
  },
}));

interface BladeCommandsProps {
  commands?: React.ReactNode[];
  onClose: () => void;
  onExport?: () => void;
  onDownload?: () => void;
  route?: string;
  showExport?: boolean;
  download?: boolean;
}

const BladeCommands: React.FC<BladeCommandsProps> = ({
  commands,
  onClose = () => undefined,
  route,
  download,
  onExport,
  onDownload,
  showExport = false,
}) => {
  const { territoryAnalyticsBladeExport } = useFlags();

  function handleTearOff() {
    onClose();
    window.open(
      route,
      '_blank',
      'menubar=no,toolbar=no,location=no,directories=no,status=no,resizable=no,dependent,width=800,height=1024'
    );
  }

  return (
    <BladeCommandsComponent direction="row">
      {commands?.map((command, i) => (
        <React.Fragment key={i}>{command}</React.Fragment>
      ))}
      {download && <BladeCommandButton type="download" onClick={onDownload} />}
      {territoryAnalyticsBladeExport && showExport && (
        <BladeCommandButton type="export" onClick={onExport} />
      )}
      {route && <BladeCommandButton type="tearoff" onClick={handleTearOff} />}
      {onClose && <BladeCommandButton type="close" onClick={onClose} />}
    </BladeCommandsComponent>
  );
};

interface BladeHeaderProps {
  title: string;
  icon: any;
  slots?: {
    slot1?: React.ReactNode;
    slot2?: React.ReactNode;
    slot3?: React.ReactNode;
  };
  commands?: React.ReactNode[];
  onClose?: () => void;
  onExport?: () => void;
  onDownload?: () => void;
  route?: string;
}

const BladeHeader: React.FC<BladeHeaderProps> = ({
  title,
  icon,
  slots,
  onClose,
  commands,
  route,
  onExport,
  onDownload,
}) => {
  const showCommands = commands?.length > 0 || route || onClose;

  return (
    <BladeHeaderComponent>
      {icon && <Icon icon={icon} />}
      <BladeTitle variant="h5">{title}</BladeTitle>
      {slots?.slot1}
      {slots?.slot2}
      {slots?.slot3}
      {showCommands && (
        <BladeCommands
          onClose={onClose}
          route={route}
          onExport={onExport}
          commands={commands}
          showExport={!!route}
          download={!!route}
          onDownload={onDownload}
        />
      )}
    </BladeHeaderComponent>
  );
};

export default BladeHeader;
