import { createContext, ReactNode, useContext, useReducer } from 'react';

const DashboardActionTypes: { [key: string]: string } = {
  SET_COMPANY_QUERY_PARAMS: 'SET_COMPANY_QUERY_PARAMS',
  SET_USER_QUERY_PARAMS: 'SET_USER_QUERY_PARAMS',
  SET_SHOW_ADD_EDIT_USER_MODAL: 'SET_SHOW_ADD_EDIT_USER_MODAL',
  SET_SHOW_ADD_EDIT_COMPANY_MODAL: 'SET_SHOW_ADD_EDIT_COMPANY_MODAL',
  SET_SHOW_CONFIRM_DELETE_MODAL: 'SET_SHOW_CONFIRM_DELETE_MODAL',
};

type QueryParams = {
  q: string;
  page: number;
  pageSize: number;
  sort: string;
  order: string;
  sort_search?: string;
  sort_value?: string;
  active_only?: number;
  managers?: number;
  include_support_user?: number;
};

type DashboardState = {
  companyQueryParams: QueryParams;
  userQueryParams?: QueryParams;
  showAddEditUserModal?: {
    show: boolean;
    company: string;
    user: MedScout.User;
  };
  showAddEditCompanyModal?: {
    show: boolean;
    company: MedScout.Company;
  };
  showConfirmDeleteModal?: {
    show: boolean;
    company: string;
    user: MedScout.User;
  };
};

const companyState = {
  q: '',
  page: 1,
  pageSize: 50,
  sort: 'name',
  order: 'asc',
  sort_search: undefined,
  sort_value: undefined,
};

const userState = {
  q: '',
  page: 1,
  pageSize: 50,
  sort: 'first_name',
  order: 'asc',
  sort_search: undefined,
  sort_value: undefined,
  active_only: 1,
  include_support_user: 0,
};

const initialState: DashboardState = {
  companyQueryParams: companyState,
  userQueryParams: userState,
  showAddEditUserModal: {
    show: false,
    company: '',
    user: null,
  },
  showAddEditCompanyModal: {
    show: false,
    company: null,
  },
  showConfirmDeleteModal: {
    show: false,
    company: '',
    user: null,
  },
};

const DashboardContext = createContext<{
  state: DashboardState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const dashboardReducer = (state: DashboardState, action: any) => {
  switch (action.type) {
    case DashboardActionTypes.SET_COMPANY_QUERY_PARAMS: {
      return {
        ...state,
        companyQueryParams: action.payload,
      };
    }
    case DashboardActionTypes.SET_USER_QUERY_PARAMS: {
      return {
        ...state,
        userQueryParams: action.payload,
      };
    }
    case DashboardActionTypes.SET_SHOW_ADD_EDIT_USER_MODAL: {
      return {
        ...state,
        showAddEditUserModal: action.payload,
      };
    }
    case DashboardActionTypes.SET_SHOW_ADD_EDIT_COMPANY_MODAL: {
      return {
        ...state,
        showAddEditCompanyModal: action.payload,
      };
    }
    case DashboardActionTypes.SET_SHOW_CONFIRM_DELETE_MODAL: {
      return {
        ...state,
        showConfirmDeleteModal: action.payload,
      };
    }

    default:
      return state;
  }
};

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  return (
    <DashboardContext.Provider value={{ state, dispatch }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }

  const {
    state: {
      companyQueryParams,
      userQueryParams,
      showAddEditUserModal,
      showAddEditCompanyModal,
      showConfirmDeleteModal,
    },
    dispatch,
  } = context;

  const setCompanyQueryParams = (queryParams: QueryParams) => {
    dispatch({
      type: DashboardActionTypes.SET_COMPANY_QUERY_PARAMS,
      payload: queryParams,
    });
  };

  const setUserQueryParams = (queryParams: QueryParams) => {
    dispatch({
      type: DashboardActionTypes.SET_USER_QUERY_PARAMS,
      payload: queryParams,
    });
  };

  const setShowAddEditUserModal = (
    showAddEditUserModal: DashboardState['showAddEditUserModal']
  ) => {
    dispatch({
      type: DashboardActionTypes.SET_SHOW_ADD_EDIT_USER_MODAL,
      payload: {
        show: showAddEditUserModal.show,
        company: showAddEditUserModal.company,
        user: showAddEditUserModal.user || null,
      },
    });
  };

  const setShowAddEditCompanyModal = (
    showAddEditCompanyModal: DashboardState['showAddEditCompanyModal']
  ) => {
    dispatch({
      type: DashboardActionTypes.SET_SHOW_ADD_EDIT_COMPANY_MODAL,
      payload: showAddEditCompanyModal,
    });
  };

  const setShowConfirmDeleteModal = (
    showConfirmDeleteModal: DashboardState['showConfirmDeleteModal']
  ) => {
    dispatch({
      type: DashboardActionTypes.SET_SHOW_CONFIRM_DELETE_MODAL,
      payload: {
        show: showConfirmDeleteModal.show,
        company: showConfirmDeleteModal.company,
        user: showConfirmDeleteModal.user || null,
      },
    });
  };

  return {
    companyQueryParams,
    setCompanyQueryParams,
    userQueryParams,
    setUserQueryParams,
    showAddEditUserModal,
    setShowAddEditUserModal,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    showAddEditCompanyModal,
    setShowAddEditCompanyModal,
  };
};
