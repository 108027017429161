import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import {
  getSearchQueryParamsForAPI,
  serializeQueryArray,
} from 'src/utils/url.helpers';

interface ReferralDashboardProps extends MedScout.Sortable {
  providerId: string;
  direction?: 'inbound' | 'outbound';
  mode?: 'code_volume' | 'sending' | 'receiving';
  enabledSearchIds?: string[];
  enabled?: boolean;
  dateRange?: {
    lte: string;
    gte: string;
  };
}

const useReferralDashboard = ({
  providerId,
  direction = 'inbound',
  mode = 'code_volume',
  enabledSearchIds = [],
  enabled = false,
  sort,
  order,
  sort_search,
  sort_value,
  dateRange,
}: ReferralDashboardProps) => {
  let queryParams = getSearchQueryParamsForAPI({
    direction,
    mode,
    sort,
    order,
    sort_search,
    sort_value,
    dateRange,
  });

  // determine if the combination of direction and mode is correct
  const isValidCombination =
    (direction === 'inbound' && mode === 'sending') ||
    (direction === 'outbound' && mode === 'receiving') ||
    mode === 'code_volume';

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  const query = useQuery(
    [QUERY_KEYS.referrals, providerId, queryParams],
    () => {
      return request({
        url: `/v1/analytics/referrals/${providerId}/${queryParams}`,
        method: 'GET',
        data: {},
      });
    },
    {
      enabled: isValidCombination,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 60_000,
    }
  );

  return {
    ...query,
    getExport: async (file_format: 'csv' | 'xlsx', columns: string[]) => {
      const columnSelection = `?${serializeQueryArray('columns', columns)}`;
      let queryParams = getSearchQueryParamsForAPI(
        {
          direction,
          mode,
          sort,
          order,
          sort_search,
          sort_value,
          dateRange,
        },
        columnSelection
      );

      // determine if the combination of direction and mode is correct
      const isValidCombination =
        (direction === 'inbound' && mode === 'sending') ||
        (direction === 'outbound' && mode === 'receiving') ||
        mode === 'code_volume';

      const searchIdParams = enabledSearchIds
        .map(
          (id) =>
            `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
        )
        .join('');

      queryParams += searchIdParams;

      if (!isValidCombination) return;
      return await request({
        url: `/v1/analytics/referrals/${providerId}/export/${queryParams}`,
        method: 'GET',
        responseType: 'blob',
        rawResponse: true,
      }).then((response) => {
        // Extract filename from Content-Disposition header
        const filename = response?.headers['content-disposition']
          ?.split('filename=')[1]
          ?.split(';')[0]
          ?.replace(/["']/g, '');

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create an anchor element and set attributes for downloading
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename || 'export.csv'); // Use filename from Content-Disposition

        // Append to the document and trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        return filename;
      });
    },
  };
};

export default useReferralDashboard;
