import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { exists } from 'src/utils';

interface SyncHistoryParams extends MedScout.Pagination, MedScout.Sortable {
  mappingId: number | string;
  direction: string;
}

const useGetCRMSyncHistory = ({
  mappingId,
  direction,
  page,
  pageSize,
  sort,
  order,
}: SyncHistoryParams) => {
  const queryKey =
    direction === 'pull'
      ? INTEGRATION_KEYS.GET_SYNC_PULL_HISTORY
      : INTEGRATION_KEYS.GET_SYNC_PUSH_HISTORY;

  const queryParams = getSearchQueryParamsForAPI({
    page: page + 1,
    pageSize,
    sort,
    order,
    direction,
  });

  return useQuery(
    [queryKey],
    async () => {
      return await request({
        url: `/v1/integrations/mappings/${mappingId}/one-time-sync-trackers/${queryParams}`,
        method: 'GET',
        data: {
          mapping: mappingId,
          direction,
        },
      });
    },
    {
      enabled: exists(mappingId),
    }
  );
};

export default useGetCRMSyncHistory;
