import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEY } from 'src/hooks/medrm/constants';

interface FieldDefinition {
  id: string;
  label: string;
  description: string;
  input_type: string;
  nullable: boolean;
  optons: MedScout.Status[];
}

const useAddFieldDefinition = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: FieldDefinition) => {
      return request({
        url: `v1/medrm/object-definitions/${data.id}`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.MED_GET_FIELD);
      },
    }
  );
};

export default useAddFieldDefinition;
