import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

function useUploadTerritories() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: MedScout.Territory) => {
      return request({
        url: `v1/accounts/territories/`,
        method: 'POST',
        data: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.territories]);
      },
    }
  );
}

export default useUploadTerritories;
