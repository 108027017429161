import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEY } from './constants';
import { QUERY_KEYS } from 'src/hooks/search/constants';
import { QUERY_KEYS as LISTS_KEYS } from '../lists/constants';
import { PROFILE_QUERY_MAP } from '../profiles';

const useUpdateMedRMStatus = ({ id }) => {
  const queryClient = useQueryClient();
  return useMutation(
    [QUERY_KEY.MED_UPDATE_OBJECT, id],
    (data: Omit<MedScout.CRMObject, 'object_id'>) => {
      if (!id) return;

      return request({
        url: `v1/integrations/crm-links/${data.crmLinkId}/`,
        method: 'PATCH',
        data: {
          ...data,
          object_id: id,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(QUERY_KEYS.search);
          },
        });

        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              PROFILE_QUERY_MAP.get('profile_details')
            );
          },
        });

        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(LISTS_KEYS.providerListItems);
          },
        });
      },
    }
  );
};

export default useUpdateMedRMStatus;
