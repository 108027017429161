import React from 'react';
import {
  DialogActions,
  Button,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Box,
} from '@mui/joy';

const DateConfirmationDialog = ({ open, onClose, onConfirm, range }) => {
  const rangeMap = {
    every_table:
      'Are you sure you want to set this range as default for all tables?',
    this_table:
      'This will apply to this table only, removing any settings that apply to everywhere. Are you sure?',
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>Confirm change in range.</DialogTitle>
        <DialogContent>
          <Box sx={{ padding: '1rem 0', maxWidth: '30rem' }}>
            <p>{rangeMap[range]}</p>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="soft" color="primary" onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="soft" color="neutral" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default DateConfirmationDialog;
