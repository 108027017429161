import { Box } from '@mui/material';
import { useMemo } from 'react';
import { PushToCRMGroup, MedRMStatus } from 'src/components';
import { useGetMappings } from 'src/hooks';

const CrmCell = ({ row }) => {
  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const crmTypes = useMemo(() => {
    return Array.from(
      new Set(
        allMappings?.results
          ?.filter((mapping) => mapping.enabled)
          .map((mapping) => mapping.crm?.toLowerCase())
      )
    );
  }, [allMappings]);

  const isMedRM = crmTypes.includes('medrm');
  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      sx={{
        width: '100%',
      }}
    >
      {isMedRM ? (
        <MedRMStatus provider={row} />
      ) : (
        <PushToCRMGroup provider={row} variant="compact" />
      )}
    </Box>
  );
};

export default CrmCell;
