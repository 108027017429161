import { Box, Input } from '@mui/joy';
import { SelectorLabel } from './DataIssueStyles';

interface DataIssueEmailProps {
  email: string;
  setEmail: (value: string) => void;
}

const DataIssueEmail = ({ email = '', setEmail }: DataIssueEmailProps) => {
  return (
    <Box sx={{ mt: 2 }}>
      <SelectorLabel>
        If you found the correct email, please provide it bleow:
      </SelectorLabel>
      <Input
        placeholder="Email"
        value={email || ''}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};

export { DataIssueEmail };
