import { Box, Checkbox, List, ListItem, Typography } from '@mui/joy';
import React from 'react';
import { useCRM } from 'src/context';
import GridStatusFilterMenuItem from './GridStatusFilterMenuItem';
import GridStatusFilterMenuHeader from './GridStatusFilterMenuHeader';

const GridStatusFilterMenuGroup = ({ bucket, statusField, groupedOptions }) => {
  const {
    statusFilterOptions,
    setStatusFilterOptions,
    selectedBuckets,
    setSelectedBuckets,
  } = useCRM();

  // Select all options in a bucket
  const handleSelectAll = ({ bucket }: { bucket: string }) => {
    if (!bucket) return;

    const options = statusField?.options
      ?.filter((option) => {
        return option.bucket === bucket;
      })
      .map((option) => option.value);

    // If the bucket was previously selected, remove all its options from statusFilterOptions
    // If the bucket was not previously selected, add all its options to statusFilterOptions
    const isBucketSelected = selectedBuckets?.includes(bucket);
    const newOptions = isBucketSelected
      ? statusFilterOptions.filter((option) => !options.includes(option))
      : [
          ...statusFilterOptions,
          ...options.filter((option) => !statusFilterOptions.includes(option)),
        ];

    setStatusFilterOptions(newOptions);

    // If the bucket was previously selected, remove it from selectedBuckets
    // If the bucket was not previously selected, add it to selectedBuckets
    const newBuckets = isBucketSelected
      ? selectedBuckets?.filter((b) => b !== bucket)
      : [...(selectedBuckets || []), bucket];

    setSelectedBuckets(newBuckets);
  };

  return (
    <List size="sm">
      <ListItem nested>
        <ListItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handleSelectAll({ bucket })}
        >
          <Checkbox
            size="sm"
            checked={selectedBuckets?.includes(bucket)}
            onChange={() => handleSelectAll({ bucket })}
          />
          <GridStatusFilterMenuHeader bucket={bucket} />
        </ListItem>
        <List>
          {groupedOptions[bucket]?.map((option, index) => (
            <GridStatusFilterMenuItem
              key={`option_${index}`}
              option={option}
              bucket={bucket}
            />
          ))}
        </List>
      </ListItem>
    </List>
  );
};

export default GridStatusFilterMenuGroup;
