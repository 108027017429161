import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';
import { QUERY_KEYS } from '../search/constants';
import { QUERY_KEYS as LISTS_KEYS } from '../lists/constants';
import { PROFILE_QUERY_MAP } from 'src/hooks';

const usePushToCRM = ({ id }) => {
  const queryClient = useQueryClient();

  return useMutation(
    [INTEGRATION_KEYS.PUSH_TO_CRM, id],
    (data: Omit<MedScout.CRMObject, 'object_id'>) => {
      if (!id) return;

      return request({
        url: '/v1/integrations/push-to-crm/',
        method: 'POST',
        data: {
          ...data,
          object_id: id,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.search);
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              PROFILE_QUERY_MAP.get('profile_details')
            );
          },
        });
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(LISTS_KEYS.providerListItems);
          },
        });
      },
    }
  );
};

export default usePushToCRM;
