import { Box, Typography } from '@mui/joy';
import React from 'react';
import Link from 'next/link';
import { getMapUrl } from './utils';

interface GoogleStaticMapProps {
  polygons: any[];
  id: string;
  size: string;
}

const GoogleStaticMap = ({ polygons, id, size }: GoogleStaticMapProps) => {
  const mapUrl = getMapUrl({ polygons, size });

  if (!mapUrl) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0.5rem',
        }}
      >
        <Typography level="body-md" sx={{ color: 'white' }}>
          No map data
        </Typography>
      </Box>
    );
  }

  return (
    <Link href={`search/${id}`}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        aria-label="View On Map"
      >
        <img
          src={mapUrl}
          alt="Map"
          width="100%"
          height="100%"
          aria-label="Map Image"
        />
      </Box>
    </Link>
  );
};

export default GoogleStaticMap;
