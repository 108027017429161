import React from 'react';
import {
  Box,
  Card,
  Grid,
  Input,
  CardContent,
  List,
  ListItem,
  CircularProgress,
  Typography,
} from '@mui/joy';
import { useGetSearch } from 'src/hooks';
import { useDebounce } from 'use-debounce';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useRouter } from 'next/router';

const AdminSearch = () => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const { data: search, isLoading } = useGetSearch(debouncedSearchTerm);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleClick = (item) => {
    if (item.type === 'company') {
      router.push(`/admin/companies/${item?.id}`);
    } else if (item.type === 'user') {
      router.push(`/admin/companies/${item?.company_id}/users/${item?.id}`);
    }
  };

  const hasNoResults =
    search?.length === 0 && !isLoading && searchTerm.length > 2;
  const isSearching = searchTerm.length > 2;

  return (
    <Grid container spacing={1} sx={{ marginBottom: '1.5rem' }}>
      <Grid xs={12} md={6}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', padding: '1rem 0' }}>
              <Input
                aria-label="Search"
                placeholder="Search Companies and Users"
                fullWidth
                value={searchTerm}
                onChange={handleSearch}
                startDecorator={<SearchRounded />}
                endDecorator={
                  isLoading ? (
                    <CircularProgress size="sm" />
                  ) : isSearching ? (
                    <CloseRounded
                      fontSize="small"
                      onClick={handleClearSearch}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : null
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {isSearching && search?.length > 0 ? (
                <Box
                  sx={{
                    maxHeight: '20rem',
                    overflow: 'hidden',
                    overflowY: 'auto',
                  }}
                >
                  <List
                    sx={{
                      padding: 0,
                    }}
                  >
                    {search?.map((item, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          padding: '1rem 0.5rem',
                          borderBottom: `1px solid ${grey[300]}`,
                          '&:last-of-type': {
                            borderBottom: 'none',
                          },
                          '&:hover': {
                            background: grey[100],
                            cursor: 'pointer',
                            fontWeight: 600,
                          },
                          transition: 'all 0.2s easeInOut',
                        }}
                        onClick={() => handleClick(item)}
                      >
                        <Typography level="body-sm">{item.label}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ) : (
                !isSearching &&
                !hasNoResults && (
                  <Box
                    sx={{
                      padding: '0.5rem',
                    }}
                  >
                    <Typography level="body-sm">
                      Start typing to search for companies and users.
                    </Typography>
                  </Box>
                )
              )}

              {hasNoResults && (
                <Box
                  sx={{
                    padding: '0.5rem',
                  }}
                >
                  <Box>
                    <Typography level="title-md">
                      Looks like we don&apos;t have any matches for &quot;
                      {debouncedSearchTerm}&quot;.
                    </Typography>
                    <ul>
                      <li>
                        <Typography level="body-sm">
                          Try a different word or phrase.
                        </Typography>
                      </li>
                      <li>
                        <Typography level="body-sm">
                          Check your search for typos.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminSearch;
