import { Box, Grid, Typography } from '@mui/joy';
import React from 'react';
import { GridVolumeColumnMenu } from '../TableControls/components';
interface TableSecondaryHeaderProps {
  columns: any[];
  columnVisibilityModel: any;
  setColumnVisibilityModel: any;
  showVolumeMenu?: boolean;
  variant?: 'regular' | 'compact';
}

const TableSecondaryHeader = ({
  columns,
  columnVisibilityModel,
  setColumnVisibilityModel,
  showVolumeMenu = false,
  variant = 'regular',
}: TableSecondaryHeaderProps) => {
  const isCompact = variant === 'compact';
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container alignItems="center">
        {!isCompact && (
          <Grid xs={9.5}>
            <Typography
              level="h4"
              sx={{
                color: '#171A1C',
              }}
            >
              Apply Codes & Filters
            </Typography>
            <Typography
              component={'p'}
              level="title-sm"
              sx={{
                color: '#555E68',
              }}
            >
              See Claims & Patient volumes for your selected criteria.
            </Typography>
          </Grid>
        )}
        <Grid
          xs={isCompact ? 12 : 2.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {showVolumeMenu && (
            <GridVolumeColumnMenu
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableSecondaryHeader;
