import { useState } from 'react';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import revisionInfo from './revision-content';
import _ from 'lodash';
import { useLogging, useAuth } from 'src/context';
import { useAcceptTerms } from 'src/hooks';
import { isAfter } from 'date-fns';
import { DialogHeader } from 'src/components';

const TermsAndConditionsModal = ({
  showOverride,
  onHide,
}: {
  showOverride?;
  onHide?;
}) => {
  const { user } = useAuth();
  const [inlineError, setInlineError] = useState('');
  const log = useLogging();

  const { isLoading: isAcceptingTerms, mutateAsync: acceptTerms } =
    useAcceptTerms();

  const toggleModal = () => {
    setInlineError('');
    onHide && onHide();
  };

  const localShow = user?.company?.terms_accepted_at
    ? isAfter(
        new Date(revisionInfo.revisionDate),
        new Date(user?.company?.terms_accepted_at)
      )
    : true;

  return (
    <div>
      <Modal
        show={_.isBoolean(showOverride) ? showOverride : localShow}
        onHide={toggleModal}
        scrollable
        size="lg"
        backdrop="static"
        keyboard={false}
        style={{ zIndex: 9999 }}
      >
        <DialogHeader>End User Agreement</DialogHeader>
        <Modal.Body>
          <revisionInfo.ContentComponent />
          <Alert className="mt-3" variant="danger" show={!!inlineError}>
            {inlineError}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isAcceptingTerms}
            onClick={async () => {
              try {
                await acceptTerms({
                  termsVersion: revisionInfo.revisionName,
                });

                log.event('termsAccepted', {
                  revisionDate: revisionInfo.revisionDate,
                  revisionName: revisionInfo.revisionName,
                });
                toggleModal();
              } catch (err) {
                setInlineError(err.message);
              }
            }}
            aria-label="Accept Terms and Conditions"
          >
            {isAcceptingTerms ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Accept</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TermsAndConditionsModal;
