import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEY } from '../constants';

const useGetObjectDefinitionsList = ({ id, objectId }) => {
  return useQuery([QUERY_KEY.MED_OBJECTS_LIST], () => {
    return request({
      url: `v1/medrm/object-definitions/${objectId}/fields/${id}/`,
      method: 'GET',
    });
  });
};

export default useGetObjectDefinitionsList;
