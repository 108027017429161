import { Checkbox, Chip, ListItem } from '@mui/joy';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { hexToRgb } from 'src/components/ColorPicker/color.functions';
import { useCRM } from 'src/context';
const GridStatusFilterMenuItem = ({ option, bucket }) => {
  const { statusFilterOptions, selectedBuckets, setStatusFilterOptions } =
    useCRM();

  // Select a single option
  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return;
    // if the option is already selected, remove it
    // if the option is not selected, add it
    const option = e.target.value;
    const newOptions = statusFilterOptions.includes(option)
      ? statusFilterOptions.filter((o) => o !== option)
      : [...statusFilterOptions, option];

    setStatusFilterOptions(newOptions);
  };

  // Handle List Item Click
  const handleOptionClick = (option: string) => {
    if (!option) return;
    const newOptions = statusFilterOptions.includes(option)
      ? statusFilterOptions.filter((o) => o !== option)
      : [...statusFilterOptions, option];

    setStatusFilterOptions(newOptions);
  };

  return (
    <ListItem
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 1.25rem',
        cursor: 'pointer',
      }}
      onClick={() => handleOptionClick(option.value)}
    >
      <Checkbox
        size="sm"
        value={option.value}
        disabled={selectedBuckets?.includes(bucket)}
        checked={statusFilterOptions?.includes(option.value)}
        onChange={handleSelect}
      />
      <Chip
        size="sm"
        variant="soft"
        startDecorator={
          <CircleRoundedIcon
            sx={{
              fontSize: '0.7rem',
            }}
          />
        }
        sx={{
          backgroundColor: hexToRgb(option.color, 0.2),
          color: option.color,
        }}
      >
        {option.label}
      </Chip>
    </ListItem>
  );
};

export default GridStatusFilterMenuItem;
