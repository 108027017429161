import {
  Card,
  Box,
  styled,
  CardContent,
  FormGroup,
  Typography,
  TextField,
  Select,
  useTheme,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
} from '@mui/material';
import { useAuth, useDiscoveryMap } from 'src/context';
import ToolArea from '../ToolArea';
import TerritoryPanelToggleButton from './TerritoryPanelToggleButton';
import { grey } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Alert } from 'react-bootstrap';
import { DISCOVERY_BLADE_NAMES } from '../../constants';
import { useGetAllTerritories, useGetAllUsers } from 'src/hooks';
import { useMemo } from 'react';
import { exists } from 'src/utils';

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: grey[300],
  fontWeight: 600,
}));

const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  marginBlock: '0.5rem',
}));

const CardControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.8rem',
    fontWeight: 600,
  },
}));

const CardLabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface TerritoryPanelFormProps {
  adhocTerritory: any;
  territory: any;
  error?: string;
  onCancel: any;
  onReset: any;
  isCanonical: any;
  editingTerritory: any;
  newTerritoryName: any;
  saveEditTerritory: any;
  saveNewTerritory: any;
  selectedTerritoryRep?: string;
  setDeleteTerritory: any;
  setIsCanonical: any;
  setNewTerritoryName: any;
  setSelectedTerritoryRep: any;
  isLoading: any;
  showValidation: any;
  showRepList: any;
  showTerritory: any;
  toggleDrawer?: { drawer: string };
  onToggleDrawer: any;
}

const TerritoryPanelForm: React.FC<TerritoryPanelFormProps> = ({
  adhocTerritory,
  territory,
  error,
  onCancel,
  onReset,
  isCanonical,
  editingTerritory,
  newTerritoryName,
  saveEditTerritory,
  saveNewTerritory,
  selectedTerritoryRep,
  setDeleteTerritory,
  setIsCanonical,
  setNewTerritoryName,
  setSelectedTerritoryRep,
  isLoading,
  showValidation,
  showRepList,
  showTerritory,
  toggleDrawer,
  onToggleDrawer,
}) => {
  const { user } = useAuth();
  const {
    tempSelectedCounties,
    tempSelectedZipCodes,
    tempTerritoryCoordinates,
    tempSelectedStates,
  } = useDiscoveryMap();
  const theme = useTheme();

  const { data: myTerritoryData, isLoading: isLoadingTerritories } =
    useGetAllTerritories();

  const { data: allUsersData, isLoading: isLoadingUsers } = useGetAllUsers(
    user?.company?.id?.toString()
  );

  // const repValuesAndLabels = useMemo(() => {
  //   if (!myTerritoryData) return [];

  //   // get uniuqe reps from owner value is owner id and label is owner first name and last name
  //   // without lodash
  //   return myTerritoryData
  //     .map((territory) => {
  //       return {
  //         value: territory.owner.id,
  //         label: `${territory.owner.first_name} ${territory.owner.last_name}`,
  //       };
  //     })
  //     .filter(
  //       (rep, index, self) =>
  //         index === self.findIndex((t) => t.value === rep.value)
  //     )
  //     .sort((a, b) => a.label.localeCompare(b.label));
  // }, [myTerritoryData]);

  const repValuesAndLabels = useMemo(() => {
    if (!allUsersData) return [];

    // get uniuqe reps from owner value is owner id and label is owner first name and last name
    // without lodash
    return allUsersData
      .map((user) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        };
      })
      .filter(
        (rep, index, self) =>
          index === self.findIndex((t) => t.value === rep.value)
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [allUsersData, user]);

  const title = !!editingTerritory ? 'Manage Territory' : 'Add Territory';

  const isManager =
    Boolean(user?.company?.manager) ||
    Boolean(user?.permissions?.is_manager) ||
    user?.is_superuser;

  const isManagerWithReps = isManager && repValuesAndLabels.length > 0;

  const hasPolygons =
    exists(editingTerritory?.polygons) ||
    exists(editingTerritory?.old_polygons);
  const hasCoordinates =
    exists(tempTerritoryCoordinates) ||
    exists(tempSelectedCounties) ||
    exists(tempSelectedZipCodes) ||
    exists(tempSelectedStates);

  const isDisabled = !!editingTerritory
    ? !hasPolygons
    : !hasCoordinates || !newTerritoryName || isLoading;
  const isRepsList = toggleDrawer?.drawer !== DISCOVERY_BLADE_NAMES.REP_LIST;
  const isTerritory = toggleDrawer?.drawer !== DISCOVERY_BLADE_NAMES.TERRITORY;

  return (
    <Card>
      <CardHeader>{title}</CardHeader>
      <CardContent>
        <CardSubTitle>
          {!!editingTerritory ? (
            <span>
              To update <strong>{territory?.name}</strong> click and drag the
              points on the map.
            </span>
          ) : (
            <span>
              Select or create a territory to begin your filtered search.
            </span>
          )}
        </CardSubTitle>
        <ToolArea />
        <FormGroup>
          <CardLabelContainer>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 600 }}>
              Territory Name
            </Typography>
            <TerritoryPanelToggleButton
              isSet={showRepList}
              onToggleDrawer={() =>
                onToggleDrawer(DISCOVERY_BLADE_NAMES.REP_LIST, isRepsList)
              }
              label="Existing Territories"
            />
          </CardLabelContainer>
          <TextField
            type="text"
            size="small"
            aria-label="Territory Name"
            placeholder="e.g. Central Texas"
            error={showValidation && !newTerritoryName}
            helperText={
              showValidation && !newTerritoryName
                ? 'Territory name is required.'
                : undefined
            }
            value={newTerritoryName}
            onChange={(e) => setNewTerritoryName(e.target.value)}
          />
        </FormGroup>
        {isManagerWithReps && (
          <FormGroup sx={{ mt: 2 }}>
            <Typography
              sx={{ fontSize: '0.8rem', fontWeight: 600, mb: '0.5rem' }}
            >
              Assigned Rep
            </Typography>
            <Select
              size="small"
              aria-label="Assigned Rep Select"
              value={selectedTerritoryRep}
              onChange={(e) => {
                setSelectedTerritoryRep(parseInt(e.target.value, 10));
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                marginThreshold: 0, // fixes weird left offset on paper
                PaperProps: {
                  sx: {
                    marginTop: '0.25rem',
                    width: '20rem',
                    maxHeight: '20rem',
                    backgroundColor: theme.palette.background.paper,
                  },
                },
              }}
              inputProps={{
                'aria-label': 'Assigned Rep Input',
              }}
            >
              <MenuItem value={user?.id}>
                Assigned to Me ({user?.first_name} {user?.last_name})
              </MenuItem>
              {repValuesAndLabels.map((rep) => {
                if (rep.value === user?.id) return null;

                return (
                  <MenuItem key={rep.value} value={rep.value}>
                    {rep.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
        )}
        {isManager && (
          <FormGroup sx={{ mt: 1 }}>
            <CardControlLabel
              control={
                <Checkbox
                  size="small"
                  aria-label="Assigned Territory"
                  checked={isCanonical}
                  onChange={(e) => {
                    const checked = e.currentTarget.checked;
                    setIsCanonical(checked);
                  }}
                />
              }
              label="Assigned Territory (Manager Editable Only)"
            />
          </FormGroup>
        )}
        <Alert className="mt-3" variant="danger" show={!!error}>
          {error}
        </Alert>
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pr: '1rem',
        }}
      >
        <TerritoryPanelToggleButton
          isSet={showTerritory}
          onToggleDrawer={() =>
            onToggleDrawer(DISCOVERY_BLADE_NAMES.TERRITORY, isTerritory)
          }
          label="Territory Analytics"
          disabled={!adhocTerritory}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
        }}
      >
        <Button
          variant="outlined"
          color="error"
          disabled={isLoading}
          onClick={
            !!editingTerritory
              ? () => {
                  setDeleteTerritory(territory);
                }
              : onReset
          }
          aria-label="Territory Delete/Clear"
        >
          {!!editingTerritory ? (
            <Icon icon={faTrash} size="sm" style={{ marginBottom: 3 }} />
          ) : (
            'Clear'
          )}
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Button
            variant="outlined"
            aria-label="Territory Cancel"
            sx={{
              color: grey[700],
              borderColor: grey[700],
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isLoading}
            onClick={!!editingTerritory ? saveEditTerritory : saveNewTerritory}
            aria-label={`Territory ${!!editingTerritory ? 'Update' : 'Add'}`}
            disabled={isDisabled}
          >
            {!!editingTerritory ? 'Update' : 'Add'} Territory
          </LoadingButton>
        </Box>
      </Box>
    </Card>
  );
};

export default TerritoryPanelForm;
