import { styled } from '@mui/joy';
import { grey } from '@mui/material/colors';
import React from 'react';

const CustomRow = styled('tr')(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    background: grey[100],
  },
}));

const TableRow = ({ children, ...props }) => {
  return <CustomRow {...props}>{children}</CustomRow>;
};

export default TableRow;
