import { useState, useEffect } from 'react';
import {
  Avatar,
  AvatarGroup,
  Menu,
  MenuItem,
  IconButton,
  Box,
  styled,
  Typography,
  Tooltip,
} from '@mui/material';
import { useGetProviderLists } from 'src/hooks';
import { stringToColor } from 'src/utils/color.helpers';

interface AvatarFilterGroupProps {
  max?: number;
  onFilter: (values: any[] | null) => void;
}

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  '&.MuiAvatar-root': {
    border: active
      ? `2px solid ${theme.palette.primary.main}`
      : '2px solid white',
  },
}));

const AvatarFilterGroup = ({ max = 15, onFilter }: AvatarFilterGroupProps) => {
  const { data: providerLists } = useGetProviderLists();
  const [active, setActive] = useState<any[] | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!active) return;
    onFilter(active);
  }, [active]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleFilter = (id: number) => {
    setActive((prev) => {
      if (!prev) return [id];
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });

    handleClose();
  }

  // Get unique owners from providerLists
  const getOwners = () => {
    if (!providerLists) return [];
    const owners = providerLists.results.map((list) => list.owner);
    // Remove duplicates
    const uniqueOwners = Array.from(new Set(owners.map((a) => a.id))).map(
      (id) => {
        return owners.find((a) => a.id === id);
      }
    );

    return uniqueOwners.sort((a, b) => {
      return a.first_name.localeCompare(b.first_name);
    });
  }

  const getInitials = (name: string) => {
    const color = stringToColor(name);
    return {
      sx: {
        bgcolor: color,
        cursor: 'pointer',
      },
      // Because if you don't have an image, you need to show the initials and it won't do that without an alt.
      children: (
        <Typography>{`${name.split(' ')[0][0]}${
          name.split(' ')[1][0]
        }`}</Typography>
      ),
    };
  }

  // Gets the remaining avatars that are not displayed in the AvatarGroup
  const remainingAvatars = getOwners().slice(max - 1);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <AvatarGroup
        max={max}
        renderSurplus={(surplus) => (
          <IconButton onClick={handleClick}>
            <Avatar alt="More" sx={{ backgroundColor: 'primary.secondary' }}>
              +{surplus}
            </Avatar>
          </IconButton>
        )}
      >
        {getOwners().map((avatar, index) => (
          <Tooltip
            key={index}
            title={`${avatar.first_name} ${avatar.last_name}`}
            arrow
          >
            <StyledAvatar
              onClick={() => handleFilter(avatar.id)}
              active={active?.includes(avatar.id)}
              {...getInitials(`${avatar.first_name} ${avatar.last_name}`)}
            />
          </Tooltip>
        ))}
      </AvatarGroup>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {remainingAvatars.map((avatar, index) => (
          <MenuItem key={index} onClick={() => handleFilter(avatar.id)}>
            <StyledAvatar
              active={active?.includes(avatar.id)}
              {...getInitials(`${avatar.first_name} ${avatar.last_name}`)}
            />
            <Typography
              sx={{ ml: 1 }}
            >{`${avatar.first_name} ${avatar.last_name}`}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AvatarFilterGroup;
