import useSWR, { mutate } from 'swr';
import { request } from 'src/api/request';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';

// recursive for each list
const fetchAllInList = async (id, cacheKey) => {
  let url = `v1/provider-lists/lists/${id}/items/`;
  let allResults = [];

  while (url) {
    const response = await request({
      url,
      method: 'GET',
    });

    allResults = [...allResults, ...response.results];

    mutate(
      cacheKey,
      (existingData = []) => {
        // combine the existing data with the new data
        const updatedData = [...existingData, ...response.results];
        // remove duplicates by id
        const uniqueData = new Set(updatedData.map((item) => item.id));
        return updatedData.filter((item) => uniqueData.has(item.id));
      },
      false
    );

    // split at api/ or null, null will halt the loop
    url = response?.next?.split('api/')[1] || null;
  }
  return allResults;
};

// recursive fetcher
const fetcher = async (ids, cacheKey) => {
  const promises = ids.map((id) => fetchAllInList(id, cacheKey));
  return Promise.all(promises).then((results) => results.flat());
};

const useGetRepProspectLists = (prospectListIds) => {
  const cacheKey =
    prospectListIds && prospectListIds.length > 0
      ? [PROSPECT_OVERVIEW_QUERY_KEYS.rep_prospect_lists, prospectListIds]
      : null;
  return useSWR(
    cacheKey
      ? [PROSPECT_OVERVIEW_QUERY_KEYS.rep_prospect_lists, prospectListIds]
      : null,
    () => fetcher(prospectListIds, cacheKey),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
      dedupingInterval: 30000,
    }
  );
};

export default useGetRepProspectLists;
