import React from 'react';
import { Typography, styled } from '@mui/joy';

const CustomLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

const SelectorLabel = ({ label }) => {
  return <CustomLabel>{label}</CustomLabel>;
};

export default SelectorLabel;
