import { Typography } from '@mui/joy';
import React from 'react';

const SpecialityCell = ({ row }) => {
  const specialty = row?.specialty || row?.provider?.specialty || 'n/a';

  return (
    <Typography
      level="body-sm"
      sx={{
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '0.5rem',
      }}
    >
      {specialty}
    </Typography>
  );
};

export default SpecialityCell;
