/**
 * @description Convert hex color to rgba
 * @param hex string
 * @param alpha number
 * @returns string
 */
export function hexToRgb(hex: string, alpha = 1) {
  if (!hex) return 'rgba(69, 90, 100, 0.2)'; // Default to black if no color is provided
  hex = hex.replace('#', '');
  const r = parseInt(hex?.substring(0, 2), 16);
  const g = parseInt(hex?.substring(2, 4), 16);
  const b = parseInt(hex?.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
