import React, { useEffect, useState } from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';
import { Typography } from '@mui/joy';
import { Collapse, styled } from '@mui/material';

const TerritoryHeader = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 500,
  borderBottom: `1px ${theme.palette.grey[200]} solid`,
  marginTop: 2,
  padding: '0.5rem 0.75rem',
  color: theme.palette.grey[700],
  cursor: 'pointer',
}));

const TerritoryGroup = ({ activeTerritory, params }) => {
  const [expandList, setExpandList] = useState(null);

  useEffect(() => {
    const isActive = params.children.some(
      (territory) => territory?.props?.option?.id === activeTerritory?.id
    );
    if (isActive) {
      setExpandList(params.key);
    }
  }, [activeTerritory, params]);
  return (
    <>
      <TerritoryHeader
        onClick={() =>
          setExpandList((prev) => {
            return prev === params.key ? null : params.key;
          })
        }
      >
        {params.group} <ExpandMoreRounded />
      </TerritoryHeader>
      <Collapse in={expandList === params.key}>{params.children}</Collapse>
    </>
  );
};

export default TerritoryGroup;
