import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

const useGetDataIssueDetails = ({ dataIssueId }) => {
  return useQuery(
    [QUERY_KEYS.dataIssueDetails, dataIssueId],
    () => {
      return request({
        url: `/v1/account/data-issues/${dataIssueId}/`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: !!dataIssueId,
      onSuccess: (dataIssue) => {
        dataIssue.newData = dataIssue.new_data ? JSON.parse(dataIssue.new_data) : {}
        return dataIssue
      }
    }
  );
};

export default useGetDataIssueDetails;
