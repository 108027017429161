import { Box, styled } from '@mui/joy';
import {
  ReferringTreatingToggle,
  VolumeTypeToggle,
  VolumeDateToggle,
  VolumeEntityTypeToggle,
} from './components';

const VolumeSelectorsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexShrink: 0,
  overflow: 'auto',
}));

interface VolumeSelectorsProps {
  showTreatingReferring?: boolean;
  showEntityType?: boolean;
  table?: string | null;
  enabledSearchIds?: number[] | string[];
}

// TODO: Joy UI dropdown does not work on Disocvery page, no ideas.. yet
const VolumeSelectors = ({
  showTreatingReferring = false,
  showEntityType = false,
  table = null,
  enabledSearchIds = [],
}: VolumeSelectorsProps) => {
  return (
    <Box
      sx={{
        flex: 1,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      }}
    >
      <VolumeSelectorsContainer
        sx={{
          justifyContent: 'flex-start',
        }}
      >
        {showTreatingReferring && (
          <ReferringTreatingToggle enabledSearchIds={enabledSearchIds} />
        )}
        {showEntityType && <VolumeEntityTypeToggle />}
        <VolumeTypeToggle table={table} enabledSearchIds={enabledSearchIds} />
        <VolumeDateToggle table={table} enabledSearchIds={enabledSearchIds} />
      </VolumeSelectorsContainer>
    </Box>
  );
};

export default VolumeSelectors;
