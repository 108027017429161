import React from 'react';
import { Box, Typography } from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const OverlayColumn = ({ ...props }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#E5F3FB',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          padding: '1rem',
        }}
      >
        <Box
          sx={{
            marginTop: '1.5rem',
            width: '200px',
          }}
        >
          <Typography
            level="body-sm"
            color="neutral"
            sx={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
          >
            No Saved Search filters applied. Click the &quot;+&quot; button
            above to add.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OverlayColumn;
