import { useEffect, useRef } from 'react';
import { Box, Typography, styled } from '@mui/joy';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import {
  GridAddToListButton,
  GridColumnMenu,
  GridExportMenu,
  GridStatusFilterMenu,
  GridProviderDeleteButton,
  GridTrendingButton,
} from './components';
import { Container } from '@mui/joy';
import { useTable } from 'src/context';

const ControlLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

interface TableControlsProps {
  isExporting?: boolean;
  onExport?: (type: string) => void;
  columns: any[];
  columnVisibilityModel: any;
  setColumnVisibilityModel: any;
  showExportButton?: boolean;
  showFilterButton?: boolean;
  showDeleteButton?: boolean;
  showAddToListButton?: boolean;
  showTrendingButton?: boolean;
  searchPlaceholder?: string;
}

const TableControls = ({
  isExporting = false,
  onExport,
  columns,
  columnVisibilityModel,
  setColumnVisibilityModel,
  showExportButton = true,
  showFilterButton = false,
  showDeleteButton = false,
  showAddToListButton = false,
  showTrendingButton = false,
  searchPlaceholder = 'by Name, Specialty or Location',
}: TableControlsProps) => {
  const ref = useRef(null);
  const { setShowIcon } = useTable();
  const MIN_WIDTH = 600;

  // We want to show icons differently per table, basing this currently off width
  useEffect(() => {
    if (!ref?.current) return;

    const handleResize = () => {
      const showIcon = ref.current.offsetWidth < MIN_WIDTH;
      setShowIcon(showIcon);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        flex: 1,
        flexShrink: 0,
      }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{ flex: 2, display: 'flex', alignItems: 'center', gap: '0.5rem' }}
        >
          <Box
            sx={{
              flex: 1,
              flexGrow: 4,
              maxWidth: '400px',
            }}
          >
            <ControlLabel>Search List</ControlLabel>
            <GridToolbarQuickFilter
              variant="outlined"
              fullWidth
              sx={{
                width: '100%',
              }}
              InputProps={{
                startAdornment: (
                  <ManageSearchRoundedIcon
                    sx={{ color: '#cdd7e1', background: 'white' }}
                    fontSize="medium"
                  />
                ),
                endAdornment: null,
                placeholder: searchPlaceholder,
                fullWidth: true,
                sx: {
                  borderRadius: '0.375rem',
                  height: '2.25rem',
                  width: '100%',
                  maxWidth: '400px',
                  border: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fieldset: {
                    border: '1px solid #cdd7e1',
                    /* --joy-shadow-xs */
                    boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
                    width: '100%',
                    maxWidth: '400px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                  input: {
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    letterSpacing: '0.01071em',
                    color: '#32383e',
                    width: '100%',
                    maxWidth: '400px',
                    backgroundColor: 'white',
                  },
                  '&:hover': {
                    border: 0,
                    fieldset: {
                      border: '1px solid #cdd7e1',
                    },
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              marginTop: '1.5rem',
              display: 'flex',
              gap: '0.25rem',
            }}
          >
            {showFilterButton && <GridStatusFilterMenu />}
            {showDeleteButton && <GridProviderDeleteButton />}
            {showAddToListButton && <GridAddToListButton />}
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              whiteSpace: 'nowrap',
              gap: '0.5rem',
              mt: '1.5rem',
              ml: '0.5rem',
            }}
          >
            {showExportButton && (
              <GridExportMenu onExport={onExport} isExporting={isExporting} />
            )}
            <GridColumnMenu
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
            />
            {showTrendingButton && <GridTrendingButton />}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TableControls;
