import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Alert, Button, Form, Spinner, ModalProps } from 'react-bootstrap';
import { useAddSavedSearch } from 'src/hooks';
import { useLogging, useProspectSearch, useAuth } from 'src/context';
import { IconButton } from 'src/components';
import { faSearchPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
} from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const NewSavedSearchDialog: React.FC<ModalProps> = ({ show, onHide }) => {
  const { user } = useAuth();
  const { prospectFilters, prospectType, setProspectSearch } =
    useProspectSearch();
  const manager =
    user?.permissions?.is_manager ||
    user?.company?.manager ||
    user?.is_superuser; // fixes so that MedScout users can save searches as company.

  const [inlineError, setInlineError] = useState('');
  const [scope, setScope] = useState<'personal' | 'company'>('personal');
  const [newName, setNewName] = useState('');
  const log = useLogging();

  const { isLoading: isSavingSearch, mutateAsync: addSavedSearch } =
    useAddSavedSearch();

  useEffect(() => {
    if (!show) {
      setInlineError('');
      setNewName('');
    }
  }, [show]);

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>
        <Box>
          <Icon icon={faSearchPlus} />
          &nbsp;New Saved Search
        </Box>
        <IconButton icon={faTimes} onClick={() => onHide()} />
      </DialogTitle>
      <DialogContent onKeyDown={(e) => e.stopPropagation()}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                setNewName(e.target.value);
              }}
              placeholder="e.g. Cardiac CPT® Codes"
              aria-label="Saved Search Name"
            />
          </Form.Group>
          {manager && (
            <Form.Group className="mt-3">
              <Form.Label>Availability</Form.Label>
              <Form.Check
                type="radio"
                label="Personal"
                id="personal"
                checked={scope === 'personal'}
                onChange={() => {
                  setScope('personal');
                }}
                aria-label="Personal Saved Search"
              />
              <Form.Check
                type="radio"
                label="Company Wide"
                id="company-wide"
                checked={scope === 'company'}
                onChange={() => {
                  setScope('company');
                }}
                aria-label="Company Wide Saved Search"
              />
            </Form.Group>
          )}
        </Form>
        <Alert className="mt-3" variant="danger" show={!!inlineError}>
          {inlineError}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outline-muted"
          onClick={() => {
            onHide();
          }}
          aria-label="Cancel New Saved Search"
        >
          Cancel
        </Button>
        <Button
          aria-label="Create Saved Search"
          disabled={!newName || !scope}
          onClick={async () => {
            log.event('createNewSavedSearch');
            const groupedFiltersByContext = _.reduce(
              prospectFilters,
              (result, { context, title, value }) => {
                // due to name being used for saved search, if it is a 'name' filter we need to utilize entity field
                const conditionalContext =
                  context === 'name' ? 'entity' : context;
                if (!result[conditionalContext]) {
                  result[conditionalContext] = [{ title, value }];
                } else {
                  result[conditionalContext] = [
                    ...result[conditionalContext],
                    { title, value },
                  ];
                }

                return result;
              },
              {}
            );

            try {
              const newSearch = await addSavedSearch({
                name: newName.trim(),
                orgSearch: scope === 'company',
                company_id: user?.company?.id?.toString(),
                contentType: prospectType,
                groupedFiltersByContext,
              });

              setProspectSearch(newSearch);
              onHide();
            } catch (err) {
              // TODO: throw to Sentry
              setInlineError(err.message);
            }
          }}
        >
          {isSavingSearch ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Create Saved Search</span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSavedSearchDialog;
