import React from 'react';
import { AddRounded, PublicRounded } from '@mui/icons-material';
import { ListItem, Typography } from '@mui/joy';
import { useTheme } from '@mui/material';

const TerritoryItem = ({ option, activeTerritory, props }) => {
  const theme = useTheme();
  const iconMap = {
    'Map Without Territory': <PublicRounded fontSize="small" />,
    'Add a Territory': <AddRounded fontSize="small" />,
  };
  const hasIcon = iconMap[option.name];

  return (
    <ListItem
      {...props}
      key={option.id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        fontSize: '0.9rem',
        fontWeight: option.id === activeTerritory?.id ? 600 : 400,
        borderLeft: `4px ${
          option.id === activeTerritory?.id
            ? theme.palette.highlight.main
            : 'white'
        } solid`,
        '&:hover': {
          cursor: 'pointer',
          borderLeft: `4px ${
            option.id !== activeTerritory?.id
              ? theme.palette.grey[400]
              : theme.palette.highlight.main
          } solid`,
        },
        color: theme.palette.grey[700],
        backgroundColor: 'white !important',
      }}
      aria-label={option.name}
    >
      {hasIcon && iconMap[option.name]}
      <span>{option.name}</span>
      {option?.is_canonical && (
        <Typography
          level="body-sm"
          sx={{ color: theme.palette.grey[500], fontWeight: 600 }}
        >
          (assigned)
        </Typography>
      )}
    </ListItem>
  );
};

export default TerritoryItem;
