import { forwardRef } from 'react';
import { Popover } from 'react-bootstrap';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { useStore } from 'src/store/store';
import { exists } from 'src/utils';

const tooltipLineStyle = { paddingTop: 2, paddingBottom: 2 };
const sourceInfoViewMap = {
  center: 'centerProfile',
  hcp: 'physicianProfile',
  inbound_patient_patterns: 'inboundPatientReferrals',
  outbound_patient_patterns: 'outboundPatientReferrals',
  affiliations: 'hospitalAffiliations',
  referrals: 'historicPatientReferrals',
};

const DataInfoPopover = forwardRef<
  HTMLDivElement,
  {
    view: string;
    tooltipData?;
    popper?;
    children?;
    show?: boolean;
  }
>(({ view, tooltipData, popper, children, show, ...props }, ref) => {
  const dataSourcesInfo = useStore((store: any) => store.dataSourcesInfo);
  const dataSourceTooltipInfo =
    dataSourcesInfo[sourceInfoViewMap[view] || view];

  let dataArray = [];
  if (dataSourceTooltipInfo) {
    dataArray = Array.isArray(dataSourceTooltipInfo)
      ? dataSourceTooltipInfo
      : [dataSourceTooltipInfo];
  } else if (tooltipData) {
    dataArray = Array.isArray(tooltipData) ? tooltipData : [tooltipData];
  }

  return (
    <Popover ref={ref} body id="popover-contained" {...props}>
      <div style={{ maxHeight: 250, overflowY: 'auto' }}>
        {dataArray.map((tooltipDataItem, idx) => {
          tooltipDataItem =
            tooltipDataItem.key &&
            (dataSourcesInfo?.[tooltipDataItem.key] ?? undefined)
              ? {
                  ...tooltipDataItem,
                  ...dataSourcesInfo[tooltipDataItem.key],
                }
              : tooltipDataItem;

          const isFirst = idx === 0;

          const fullCoverageText = Array.isArray(tooltipDataItem.coverage?.full)
            ? (tooltipDataItem.coverage?.full || []).join(', ')
            : tooltipDataItem.coverage?.full;
          const partialCoverageText = Array.isArray(
            tooltipDataItem.coverage?.partial
          )
            ? (tooltipDataItem.coverage?.partial || []).join(', ')
            : tooltipDataItem.coverage?.partial;

          return (
            <div
              style={{ fontSize: 12 }}
              key={`${tooltipDataItem.header}_${idx}`}
            >
              {!isFirst && <hr style={{ margin: 2 }} />}
              {tooltipDataItem.header && (
                <div style={{ color: COLOR_MAP['blue-dark'] }}>
                  <strong>{tooltipDataItem.header}</strong>
                </div>
              )}
              {tooltipDataItem.subHeader && (
                <div style={tooltipLineStyle}>{tooltipDataItem.subHeader}</div>
              )}
              {fullCoverageText && (
                <div style={tooltipLineStyle}>
                  <strong>Full Coverage</strong>
                  <br />
                  {fullCoverageText}
                </div>
              )}
              {partialCoverageText && (
                <div style={tooltipLineStyle}>
                  <strong>Partial Coverage (Hospital Setting)</strong>
                  <br />
                  {partialCoverageText}
                </div>
              )}
              {exists(tooltipDataItem.coverage?.enrichedData) && (
                <div style={tooltipLineStyle}>
                  <strong>MedScout Enriched</strong>
                  <br />
                  <span>
                    {Object.keys(tooltipDataItem.coverage?.enrichedData)
                      ?.map(
                        (key) =>
                          tooltipDataItem.coverage?.enrichedData[key].label
                      )
                      .join(', ')}
                  </span>
                </div>
              )}
              {tooltipDataItem.updateFrequency && (
                <div style={tooltipLineStyle}>
                  <strong>Updated</strong>
                  <br />
                  {tooltipDataItem.updateFrequency}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Popover>
  );
});
DataInfoPopover.displayName = 'DataInfoPopover';

export default DataInfoPopover;
