import React, { useCallback } from 'react';
import { faUserNinja } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@mui/joy';
import { useRouter } from 'next/router';
import { useAuth } from 'src/context';
import { useUserImpersonation } from 'src/hooks';

interface ImpersonateButtonProps {
  userId: string;
  variant?: 'regular' | 'compact' | 'icon';
}

const ImpersonateButton = ({
  userId,
  variant = 'regular',
}: ImpersonateButtonProps) => {
  const { user } = useAuth();
  const router = useRouter();

  const { impersonate } = useUserImpersonation();

  const isMedScoutUser = user?.is_superuser;

  const handleImpersonate = useCallback(
    async (id) => {
      await impersonate(id);

      router.push('/search');
    },
    [impersonate, router]
  );

  if (!isMedScoutUser) return null;

  switch (variant) {
    case 'regular': {
      return (
        <Button
          size="sm"
          variant="outlined"
          color="primary"
          onClick={() => handleImpersonate(userId)}
          startDecorator={<Icon icon={faUserNinja} />}
          disabled={!isMedScoutUser || !userId}
        >
          Support
        </Button>
      );
    }

    case 'compact': {
      return (
        <IconButton
          size="sm"
          variant="soft"
          color="primary"
          onClick={() => handleImpersonate(userId)}
          disabled={!isMedScoutUser || !userId}
        >
          <Icon icon={faUserNinja} />
        </IconButton>
      );
    }
    case 'icon': {
      return (
        <IconButton
          onClick={() => handleImpersonate(userId)}
          sx={{
            width: '1.75rem',
            height: '1.75rem',
            minWidth: '1.75rem',
            minHeight: '1.75rem',
            maxWidth: '1.75rem',
            maxHeight: '1.75rem',
            backgroundColor: 'transparent',
            color: '#636B74',
            '&:hover': {
              color: 'var(--joy-palette-primary-500, #0B6BCB)',
              backgroundColor: 'transparent',
            },
          }}
          disabled={!isMedScoutUser || !userId}
        >
          <Icon
            icon={faUserNinja}
            style={{
              fontSize: '0.9rem',
            }}
          />
        </IconButton>
      );
    }

    default:
      break;
  }
};

export default ImpersonateButton;
