import React, { useEffect, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, Typography, CircularProgress, Grid } from '@mui/joy';
import { useAuth, useDashboard } from 'src/context';
import { useGetAllCompanies, useCompanyUsers } from 'src/hooks';

const CompanyUserSelector = () => {
  const { user, isSuperUser } = useAuth();
  const { control, setValue } = useFormContext();
  const { userQueryParams, setUserQueryParams } = useDashboard();
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  const companyId = isSuperUser ? selectedCompany?.value : user?.company?.id;

  const { data: companies, isLoading: isCompanyLoading } = useGetAllCompanies();
  const { data: companyUsers, isLoading: isUsersLoading } =
    useCompanyUsers(companyId);

  useEffect(() => {
    if (!isSuperUser) return;
    setUserQueryParams({ ...userQueryParams, include_support_user: 1 });
    return () => {
      setUserQueryParams({ ...userQueryParams, include_support_user: 0 });
    };
  }, []);

  // convert companies and companyUsers to value and label
  const companyIdAndValue = useMemo(() => {
    if (!companies || companies.length === 0) return [];

    return companies?.map((company) => ({
      value: company?.id,
      label: company?.name,
    }));
  }, [companies]);

  const userIdAndValue = useMemo(() => {
    return companyUsers?.results?.map((user) => ({
      value: user?.id,
      label: `${user?.first_name} ${user?.last_name}`,
    }));
  }, [companyUsers]);

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  const handleUserChange = (event, newValue) => {
    setValue('user', newValue?.value); // we don't need the full object
  };

  const handleIsOptionEqualToValue = (option, value) =>
    option.value === value.value;

  const handleEndDecorator = (loading) => {
    return loading ? (
      <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
    ) : null;
  };

  return (
    <Grid container spacing={1} sx={{ mb: 1 }}>
      {isSuperUser && (
        <Grid xs={6}>
          <Typography level="body-sm" sx={{ fontWeight: 600, pb: '0.25rem' }}>
            Company
          </Typography>
          <Autocomplete
            disableClearable
            loading={!companies || isCompanyLoading}
            placeholder="Select a company"
            value={selectedCompany || null}
            onChange={handleCompanyChange}
            options={companyIdAndValue || []}
            isOptionEqualToValue={handleIsOptionEqualToValue}
            endDecorator={
              isCompanyLoading ? (
                <CircularProgress
                  size="sm"
                  sx={{ bgcolor: 'background.surface' }}
                />
              ) : null
            }
          />
        </Grid>
      )}
      {companyUsers?.results?.length > 0 && (
        <Grid xs={6}>
          <Typography level="body-sm" sx={{ fontWeight: 600, pb: '0.25rem' }}>
            User
          </Typography>
          <Controller
            name="user"
            control={control}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                loading={!companyUsers || isUsersLoading}
                placeholder="Select a user"
                value={
                  field.value
                    ? userIdAndValue?.find((u) => u.value === field.value) ||
                      null
                    : null
                }
                onChange={handleUserChange}
                options={userIdAndValue || []}
                isOptionEqualToValue={handleIsOptionEqualToValue}
                endDecorator={() =>
                  handleEndDecorator(!companyUsers || isUsersLoading)
                }
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CompanyUserSelector;
