import { useState } from 'react';
import { IconButton, Button } from '@mui/joy';
import { useProspectSelection, useTable } from 'src/context';
import { AddProviderDialog } from 'src/components';
import { useMediaQuery, useTheme } from '@mui/material';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';

const GridAddToListButton = () => {
  const {
    state: [newProviders],
    selectedRows: [rowsAreSelected, setRowsAreSelected],
  } = useProspectSelection();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { showIcon } = useTable();
  const [showAddProvidersDialog, setShowAddProvidersDialog] = useState(false);

  // Structure newProviders correctly.
  // If provider is nested move it to the top level and disregard everything else.
  // test if provider is nested
  const providers = newProviders.map((provider) => {
    if (provider.provider) {
      return {
        ...provider.provider,
      };
    }
    return provider;
  });

  return (
    <>
      {isMobile || showIcon ? (
        <IconButton
          variant="soft"
          color="neutral"
          sx={{
            fontWeight: 900,
          }}
          onClick={() => setShowAddProvidersDialog(true)}
          disabled={!providers.length}
          aria-label="Add to List"
        >
          <PlaylistAddRoundedIcon fontSize="small" />
        </IconButton>
      ) : (
        <Button
          variant="soft"
          color="neutral"
          sx={{
            fontWeight: 900,
            display: 'flex',
            whiteSpace: 'nowrap',
          }}
          onClick={() => setShowAddProvidersDialog(true)}
          disabled={!newProviders.length}
          startDecorator={<PlaylistAddRoundedIcon />}
        >
          Add
        </Button>
      )}
      {showAddProvidersDialog && (
        <AddProviderDialog
          onHide={() => {
            setRowsAreSelected(!rowsAreSelected);
            setShowAddProvidersDialog(false);
          }}
          newProviders={{
            providers,
            ui_fromMap: false,
            onSuccess: () => null,
          }}
        />
      )}
    </>
  );
};

export default GridAddToListButton;
