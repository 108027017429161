import { Select, Option, Box } from '@mui/joy';
import { SelectorLabel } from './DataIssueStyles';
import { DataIssueTypes } from './types';

interface DataIssueTypeSelectorProps {
  providerType: string;
  issueType: DataIssueTypes;
  onChange?: (value: string) => void;
}

const DataIssueTypeSelector = ({
  providerType,
  issueType,
  onChange,
}: DataIssueTypeSelectorProps) => {
  const menuItems = [
    { value: 'address', label: 'Address or Location' },
    { value: 'phone', label: 'Phone' },
    // only show email for HCPs
    providerType === 'hcp' ? { value: 'email', label: 'Email' } : null,
    { value: 'other', label: 'Other' },
  ].filter(Boolean);

  return (
    <Box
      sx={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', mt: 2 }}
    >
      <SelectorLabel>Issue Type:</SelectorLabel>
      <Select
        disabled={!onChange}
        value={issueType}
        onChange={(e, val) => (onChange ? onChange(val) : null)}
        placeholder="Select an issue type"
        sx={{ minWidth: '250px' }}
        slotProps={{
          listbox: {
            sx: {
              // since we are in a modal, the menu should be on top of everything
              zIndex: 9999,
            },
          },
        }}
      >
        {menuItems.map((item) => (
          <Option key={item.value} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

export { DataIssueTypeSelector };
