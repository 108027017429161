import React, { useEffect } from 'react';
import { ButtonGroup, Button } from '@mui/joy';
import { useMedScoutMap } from 'src/context';

const buttons = [
  {
    label: 'State',
    value: 'STATE',
  },
  {
    label: 'County',
    value: 'COUNTY',
  },
  {
    label: 'Zipcode',
    value: 'ZIPCODE',
  },
  {
    label: 'Draw',
    value: 'DRAW',
  },
];
const DrawActionButtons = () => {
  const { currentTerritory, isEditing, drawingMode, setDrawingMode } =
    useMedScoutMap();

  const handleDrawingMode = (mode: string | null) => {
    setDrawingMode(mode as 'STATE' | 'COUNTY' | 'ZIPCODE');
  };

  useEffect(() => {
    if (!currentTerritory || !isEditing) return;
    if (currentTerritory.source?.states?.length > 0) {
      setDrawingMode('STATE');
    } else if (currentTerritory.source?.counties?.length > 0) {
      setDrawingMode('COUNTY');
    } else if (currentTerritory.source?.zip_codes?.length > 0) {
      setDrawingMode('ZIPCODE');
    } else if (currentTerritory.source?.drawn) {
      setDrawingMode('DRAW');
    }
  }, [currentTerritory, isEditing]);
  return (
    <ButtonGroup
      variant="solid"
      color="neutral"
      aria-label="outlined primary button group"
      sx={{ width: '100%', padding: '0.5rem 0' }}
    >
      {buttons.map((button) => (
        <Button
          key={button.value}
          fullWidth
          onClick={() => handleDrawingMode(button.value)}
          variant={drawingMode === button.value ? 'solid' : 'outlined'}
        >
          {button.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default DrawActionButtons;
