import { useState, useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';
import GoogleMapReact from 'google-map-react';
import MapMarker from './components/MapMarker';
import useSupercluster from 'use-supercluster';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import mapStyles from './map-styles.json';
import { useGetBoundaries } from 'src/hooks';
import {
  DC_STATE_ID,
  DRAW_MODES,
  MAX_ZOOM,
  MAX_WINDOW_WIDTH,
  VA_STATE_ID,
} from './constants';

import {
  useLogging,
  useDiscoveryMap,
  useProspectSearch,
  useNotification,
} from 'src/context';
import HoverData from './components/HoverData';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';
import { getConvertedTerritoryData } from 'src/components/Map/utils/ConvertStateData';

export const getAllPolygonBounds = ({ polygons, maps }) => {
  const bounds = new maps.LatLngBounds();
  _.forEach(polygons, (polygon) => {
    const paths = polygon.getPaths();
    // forEach are defined methods, no replaceable by lodash
    paths?.forEach((path) => {
      // forEach are defined methods, no replaceable by lodash
      path?.forEach((latLng) => {
        bounds.extend(latLng);
      });
    });
  });
  return bounds;
};

interface MapProps {
  points;
  mapZoomCallback?;
  center?;
  disableClusters?: boolean;
  onAddToList?;
  showCreateTools?: boolean;
  containToTerritories?: boolean;
  normalizedProspectListData?;
  hoveredProviderId?;
}

const Map: React.FC<MapProps> = ({
  points,
  mapZoomCallback,
  center,
  disableClusters = false,
  onAddToList = null,
  showCreateTools = false,
  containToTerritories = false,
  normalizedProspectListData = null,
  hoveredProviderId = null,
  children = null,
}) => {
  const mapRef = useRef<{ map; maps; drawingManager }>(null);
  const {
    addTempSelectedStates,
    addTempSelectedCounties,
    addTempSelectedZipCodes,
    addTempTerritoryCoordinates,
    editingTerritory,
    removeTempSelectedStates,
    removeTempSelectedCounties,
    removeTempSelectedZipCodes,
    clearOverlay,
    createNewTerritoryMode,
    setClearOverlay,
    selectedTerritories,
    tempSelectedStates,
    tempSelectedCounties,
    tempSelectedZipCodes,
    tempTerritoryCoordinates,
    drawingManagerDrawMode,
    setDrawingManagerDrawMode,
    setBoundariesLoading,
    existingTerritories,
  } = useDiscoveryMap();

  const { width } = useWindowSize();
  const MIN_ZOOM = width > MAX_WINDOW_WIDTH ? 5 : 4;
  const { prospectSearch } = useProspectSearch();
  // drawingModeReference utilized for eventListners on GeoJSON data layers of Google Maps
  const drawingModeRef = useRef({});
  const { setNotification } = useNotification();
  const [latestDrawnPoly, setLatestDrawnPoly] = useState(null);

  // Layers for the GeoJSON data
  const stateGeoJsonLayerRef = useRef<{ data; features: any[] }>(null);
  const countyGeoJsonLayerRef = useRef<{ data; features: any[] }>(null);
  const stateZipCodesGeoJsonLayerRef = useRef<{ data; features: any[] }>(null);
  const temporaryGeoJsonLayerRef = useRef<{ data; features: any[] }>(null);

  const log = useLogging();

  const defaultCenter =
    center && !_.isArray(center)
      ? center
      : {
          lat: 37.0902,
          lng: -95.7129,
        };

  const [mapAvailable, setMapAvailable] = useState(false);
  const [originalZoom, setOriginalZoom] = useState(null);
  const [zoom, setZoom] = useState(center ? 13 : MIN_ZOOM);
  const [bounds, setBounds] = useState(null);
  const [clusterBounds, setClusterBounds] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [addedTerritories, setAddedTerritories] = useState<{
    [id: string]: { polygons; id };
  }>(null);
  const [visiblePoints, setVisiblePoints] = useState([]);
  const [mapState, setMapState] = useState(null);
  const [hoverData, setHoverData] = useState(null);
  const [lastProspectId, setLastProspectId] = useState(null);
  const [lastTerritoryId, setLastTerritoryId] = useState(null);

  const typeNameMap = {
    [DRAW_MODES.COUNTY]: 'counties',
    [DRAW_MODES.STATE]: 'states',
    [DRAW_MODES.ZIP]: 'zip_codes',
  };

  const updateOnMapMove = !editingTerritory && !createNewTerritoryMode;

  // types have to be plural, zip_codes, counties, states
  const {
    data: boundariesData,
    isLoading,
    isFetching,
  } = useGetBoundaries({
    bounding_box: drawingManagerDrawMode === DRAW_MODES.ZIP ? bounds : null,
    type: typeNameMap[drawingManagerDrawMode],
    enabled: !!bounds && !!drawingManagerDrawMode,
  });

  useEffect(() => {
    setBoundariesLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    return () => {
      // on dismount ensure there aren't any errant polygon references on the map
      clearMap();
    };
  }, []);

  // This useEffect seems to set the drawing mode on the map
  useEffect(() => {
    if (!mapAvailable) return;
    // if the boundaries data is not the right type, return
    const typeData = boundariesData?.boundaries?.every((boundary) => {
      const currentType =
        drawingManagerDrawMode === DRAW_MODES.ZIP
          ? 'zip_code'
          : drawingManagerDrawMode;
      return boundary.type === currentType.toLowerCase();
    });

    if (
      !typeData &&
      createNewTerritoryMode &&
      drawingManagerDrawMode !== DRAW_MODES.DRAW_POLY
    )
      return;

    setMapDrawState();
    const loadCountyGeoData =
      drawingManagerDrawMode === DRAW_MODES.STATE ||
      drawingManagerDrawMode === DRAW_MODES.COUNTY;

    // unload and deselect any data layers that exist
    if (!showCreateTools || !loadCountyGeoData) {
      resetOverlay();
      setClearOverlay(true);
    } else if (clearOverlay) {
      // reset map state is true, but we're not unloading the layers, so we need to set it to false
      resetOverlay();
      setClearOverlay(false);
    }

    handleDrawingManagerLoad();
    mapRef.current.map.panBy(0, 0);
  }, [
    boundariesData,
    clearOverlay,
    drawingManagerDrawMode,
    editingTerritory,
    mapAvailable,
    showCreateTools,
  ]);

  async function handleDrawingManagerLoad() {
    if (!mapAvailable) return;

    const googleMapsBoundaries =
      boundariesData?.boundaries?.map((boundary) => {
        return {
          type: 'Feature',
          properties: {
            id: boundary.id,
            name: boundary.label,
          },
          geometry: {
            type: 'Polygon',
            coordinates: boundary.geometry,
          },
        };
      }) || [];
    switch (drawingManagerDrawMode) {
      case DRAW_MODES.STATE:
        {
          if (!mapRef.current) return;
          resetOverlay();

          if (!stateGeoJsonLayerRef.current) {
            const stateGeoJson = new mapRef.current.maps.Data();
            const stateFeatures = stateGeoJson.addGeoJson({
              type: 'FeatureCollection',
              features: googleMapsBoundaries,
            });

            stateGeoJson.setStyle(function (feature) {
              let color = COLOR_MAP['gray'];
              let fillOpacity = 0.1;
              if (feature.getProperty('isSelected')) {
                color = COLOR_MAP['blue'];
                fillOpacity = 0.3;
              }
              return {
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 0.5,
                fillColor: color,
                fillOpacity,
              };
            });

            stateGeoJson.addListener('mouseover', onGeoJsonMouseOver);
            stateGeoJson.addListener('mouseout', function () {
              setHoverData(null);
              stateGeoJson.revertStyle();
            });

            stateGeoJson.addListener('click', onGeoJsonClick);

            stateGeoJson.setMap(mapRef.current.map);

            stateGeoJsonLayerRef.current = {
              data: stateGeoJson,
              features: stateFeatures,
            };
          }
          // switched back to state, and want to add the state layer back to the map if available
          stateGeoJsonLayerRef.current?.data?.setMap(mapRef.current.map);
        }
        break;
      case DRAW_MODES.COUNTY:
        {
          if (!mapRef.current) return;
          resetOverlay();
          if (
            !countyGeoJsonLayerRef.current &&
            !_.isEmpty(boundariesData?.boundaries)
          ) {
            const countyGeoJson = new mapRef.current.maps.Data();
            const countyFeatures = countyGeoJson.addGeoJson({
              type: 'FeatureCollection',
              features: googleMapsBoundaries,
            });

            if (tempSelectedStates.length > 0) {
              // Converts state to counties
              setBoundariesLoading(true);
              try {
                const data = await getConvertedTerritoryData({
                  type: 'counties',
                  states: tempSelectedStates,
                  bounding_box: null,
                });

                const countyIds = data.boundaries.map((county) => county.id);

                countyFeatures.forEach((feature) => {
                  const featureCountyId = feature.getProperty('id');
                  if (countyIds.includes(featureCountyId)) {
                    feature.setProperty('isSelected', true);
                  }
                });

                addTempSelectedCounties({ items: countyIds });
              } catch (error) {
                log.event(`Error converting state to counties`, {
                  source: 'handleDrawingManagerLoad',
                  error,
                });
                setNotification({
                  title: 'Error converting state to counties',
                  message: 'Error converting state to counties',
                  type: 'error',
                });
              } finally {
                setBoundariesLoading(false);
              }
            }

            countyGeoJson.setStyle(function (feature) {
              let color = COLOR_MAP['gray'];
              let fillOpacity = 0.1;
              if (feature.getProperty('isSelected')) {
                color = COLOR_MAP['blue'];
                fillOpacity = 0.3;
              }
              return {
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 0.5,
                fillColor: color,
                fillOpacity,
              };
            });

            countyGeoJson.addListener('mouseover', onGeoJsonMouseOver);
            countyGeoJson.addListener('mouseout', function () {
              setHoverData(null);
              countyGeoJson.revertStyle();
            });

            countyGeoJson.addListener('click', onGeoJsonClick);

            countyGeoJson.setMap(mapRef.current.map);

            countyGeoJsonLayerRef.current = {
              data: countyGeoJson,
              features: countyFeatures,
            };
          }

          // switched back to county, and want to add the county layer back to the map if available
          countyGeoJsonLayerRef.current?.data?.setMap(mapRef.current.map);
        }
        break;
      case DRAW_MODES.ZIP:
        {
          if (!mapRef.current) return;
          resetOverlay();
          const zipGeoJson = new mapRef.current.maps.Data();
          const zipFeatures = zipGeoJson.addGeoJson({
            type: 'FeatureCollection',
            features: googleMapsBoundaries,
          });

          // This maintains the selected zip codes on the map when boundaries change
          if (
            tempSelectedZipCodes?.length &&
            (!!createNewTerritoryMode || !!editingTerritory)
          ) {
            const zipIds = tempSelectedZipCodes.map((zip) => zip);
            zipFeatures.forEach((feature) => {
              const featureZipId = feature.getProperty('id');
              if (zipIds.includes(featureZipId)) {
                feature.setProperty('isSelected', true);
              }
            });

            addTempSelectedZipCodes({ items: zipIds });
          } else if (tempSelectedStates.length > 0) {
            // Converts state to zip codes
            setBoundariesLoading(true);
            try {
              const data = await getConvertedTerritoryData({
                type: 'zip_codes',
                states: tempSelectedStates,
                bounding_box: null,
              });

              const zipIds = data.boundaries.map((county) => county.id);

              zipFeatures.forEach((feature) => {
                const featureZipId = feature.getProperty('id');
                if (zipIds.includes(featureZipId)) {
                  feature.setProperty('isSelected', true);
                }
              });

              addTempSelectedZipCodes({ items: zipIds });
            } catch (error) {
              log.event(`Error converting state to zip codes`, {
                source: 'handleDrawingManagerLoad',
                error,
              });
              setNotification({
                title: 'Error converting state to zip codes',
                message: 'Error converting state to zip codes',
                type: 'error',
              });
            } finally {
              setBoundariesLoading(false);
            }
          }

          zipGeoJson.setStyle(function (feature) {
            let color = COLOR_MAP['gray'];
            let fillOpacity = 0.1;
            if (feature.getProperty('isSelected')) {
              color = COLOR_MAP['blue'];
              fillOpacity = 0.3;
            }
            return {
              strokeColor: color,
              strokeOpacity: 0.8,
              strokeWeight: 0.5,
              fillColor: color,
              fillOpacity,
            };
          });

          zipGeoJson.addListener('mouseover', onGeoJsonMouseOver);
          zipGeoJson.addListener('mouseout', function () {
            setHoverData(null);
            zipGeoJson.revertStyle();
          });

          zipGeoJson.addListener('click', onGeoJsonClick);

          zipGeoJson.setMap(mapRef.current.map);

          stateZipCodesGeoJsonLayerRef.current = {
            data: zipGeoJson,
            features: zipFeatures,
          };

          stateZipCodesGeoJsonLayerRef.current?.data?.setMap(
            mapRef.current.map
          );
        }
        break;
      case DRAW_MODES.DRAW_POLY:
        if (editingTerritory) {
          const paths =
            editingTerritory?.polygons || editingTerritory?.old_polygons;

          if (!paths.length) return;
          const tempCoords = paths?.flatMap((path) =>
            path.map((latLng) => [latLng.lng, latLng.lat])
          );
          addTempTerritoryCoordinates({ items: [tempCoords] });
        }
        break;
      default:
        break;
    }
  }

  // This useEffect seems to handle the overlay of the territory on the map
  useEffect(() => {
    if (!mapAvailable) return;
    if (latestDrawnPoly) {
      setDrawingManagerDrawMode(DRAW_MODES.MOVE);
      latestDrawnPoly.setMap(null);
    }

    if (editingTerritory) {
      // Clear the map

      const source = editingTerritory?.source;

      if (
        source?.counties?.length > 0 &&
        drawingManagerDrawMode === DRAW_MODES.COUNTY
      ) {
        clearMap();

        // get ids of the counties
        const modifiedCountyIds = source?.counties?.map((county) => county.id);
        addTempSelectedCounties({ items: modifiedCountyIds });
        // isSelected to the county in the geojson layer
        countyGeoJsonLayerRef.current?.features?.forEach((feature) => {
          const featureCountyId = feature.getProperty('id');
          if (modifiedCountyIds.includes(featureCountyId)) {
            feature.setProperty('isSelected', true);
          }
        });

        countyGeoJsonLayerRef.current?.data?.setMap(mapRef.current.map);

        // update map to show the selected counties
        mapRef.current?.map?.panBy(0, 0);
      } else if (
        source?.states?.length > 0 &&
        drawingManagerDrawMode === DRAW_MODES.STATE
      ) {
        clearMap();

        // get ids of the states
        const modifiedStateIds = source?.states?.map((state) => state.id);

        addTempSelectedStates({ items: modifiedStateIds });
        // isSelected to the state in the geojson layer
        stateGeoJsonLayerRef.current?.features?.forEach((feature) => {
          const featureStateId = feature.getProperty('id');
          if (
            modifiedStateIds.includes(featureStateId) ||
            (modifiedStateIds.includes(VA_STATE_ID) &&
              featureStateId === DC_STATE_ID)
          ) {
            feature.setProperty('isSelected', true);
          }
        });

        stateGeoJsonLayerRef.current?.data?.setMap(mapRef.current.map);

        // update map to show the selected states
        mapRef.current?.map?.panBy(0, 0);
      } else if (
        source?.zip_codes?.length > 0 &&
        drawingManagerDrawMode === DRAW_MODES.ZIP
      ) {
        clearMap();

        // get ids of the zip codes
        const modifiedZipIds = source?.zip_codes?.map((zip) => zip.id);

        addTempSelectedZipCodes({ items: modifiedZipIds });

        // isSelected to the zip code in the geojson layer
        stateZipCodesGeoJsonLayerRef.current?.features?.forEach((feature) => {
          const featureZipId = feature.getProperty('id');
          if (modifiedZipIds.includes(featureZipId)) {
            feature.setProperty('isSelected', true);
          }
        });

        stateZipCodesGeoJsonLayerRef.current?.data?.setMap(mapRef.current.map);

        // update map to show the selected zip codes
        mapRef.current?.map?.panBy(0, 0);
      } else if (
        source?.drawn &&
        drawingManagerDrawMode === DRAW_MODES.DRAW_POLY
      ) {
        territoryHydration();
      }
    } else if (createNewTerritoryMode) {
      clearMap();
    } else {
      territoryHydration();
    }
    // set any temp territories
    handleExistingTerritoriesDisplay();

    // update the map to show the selected states
    mapRef.current?.map?.panBy(0, 0);
  }, [
    mapAvailable,
    mapRef,
    selectedTerritories,
    existingTerritories,
    editingTerritory,
    createNewTerritoryMode,
    drawingManagerDrawMode,
    boundariesData,
  ]);

  // This useEffect seems to handle the points on the map
  useEffect(() => {
    if (points && mapAvailable && updateOnMapMove) {
      const currentMapBounds = mapRef.current.map.getBounds();
      const temp = [];
      const tempAddedToMapById = {};
      _.forEach(points, (point) => {
        const lat = parseFloat(point?.lat);
        const lng = parseFloat(point?.lng);
        // if lat and lng are not valid do not add them as visible points
        if (!lat || !lng) return;

        const latLng = new mapRef.current.maps.LatLng({ lat, lng });
        const isVisibleOnMapBounds = currentMapBounds.contains(latLng);

        if (!isVisibleOnMapBounds || tempAddedToMapById[point.id]) return;

        const ui_prospectLists =
          _.get(normalizedProspectListData, point.provider_id) || [];

        if (_.isEmpty(addedTerritories)) {
          tempAddedToMapById[point.id] = true;
          temp.push({ ...point, ui_prospectLists });
        }

        _.forEach(addedTerritories, ({ polygons }) => {
          if (tempAddedToMapById[point.id]) return;

          const territoryContainsLocation = _.some(polygons, (poly) => {
            return mapRef.current.maps.geometry.poly.containsLocation(
              latLng,
              poly
            );
          });
          if (!containToTerritories || territoryContainsLocation) {
            tempAddedToMapById[point.id] = true;
            temp.push({ ...point, ui_prospectLists });
          }
        });
      });

      setVisiblePoints(temp);
    }
  }, [points, mapAvailable, mapState, addedTerritories, updateOnMapMove]);

  // If a map center is passed over (typically when we're mapping a specific physician or center), default to that.
  // If center is an array then we'll want the map to center on a marker but set bounds to be on all
  // Otherwise, default to the middle of the country in Kansas

  const canUpdateBounds = () => {
    if (editingTerritory || createNewTerritoryMode) return false;
    const territoryId = selectedTerritories[0]?.id;
    const prospectId = prospectSearch?.id;

    let updatedBounds = true;

    const shouldUpdateBounds =
      !lastProspectId || !lastTerritoryId || lastTerritoryId !== territoryId;

    if (shouldUpdateBounds) {
      updatedBounds = true;
    } else if (
      (prospectSearch && prospectId !== lastProspectId) ||
      lastTerritoryId === territoryId
    ) {
      updatedBounds = false;
    }

    // Update lastTerritoryId and lastProspectId if they have changed
    setLastTerritoryId((prev) => (territoryId !== prev ? territoryId : prev));
    setLastProspectId((prev) => (prospectId !== prev ? prospectId : prev));

    return updatedBounds;
  };

  // This handles the territories in the RepsTerritoriesAndLists
  function handleExistingTerritoriesDisplay() {
    const tempTerritories = existingTerritories.filter(
      (territory) => territory.id !== editingTerritory?.id
    );

    // tempTerritories to a new layer UNDER the editing territory
    if (tempTerritories.length > 0) {
      const tempTerritoriesAddedById: { [id: string]: { polygons; id } } = {};

      _.forEach(tempTerritories, (territory) => {
        const isNew = !!territory?.polygons;
        const paths = isNew ? territory?.polygons : territory?.old_polygons;

        const isMultiPolygon = Array.isArray(paths?.[0]?.[0]);

        if (_.isEmpty(paths)) return;

        const notAddedToTempList = !tempTerritoriesAddedById[territory.id];
        const previouslyVisibleTerritory = addedTerritories?.[territory.id];
        if (previouslyVisibleTerritory) {
          _.forEach(previouslyVisibleTerritory?.polygons, (poly) => {
            poly.setMap(null);
          });
        }
        if (notAddedToTempList) {
          const isCurrentEditingTerritory =
            editingTerritory?.id === territory.id;
          const polygonColor = isCurrentEditingTerritory
            ? COLOR_MAP['blue-dark']
            : COLOR_MAP['gray-dark'];

          const territoryWithPolygon = {
            ...territory,
            polygons: isMultiPolygon
              ? _.map(paths, (path) => {
                  return new mapRef.current.maps.Polygon({
                    paths: path,
                    strokeColor: territory.color || polygonColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: territory.color || polygonColor,
                    fillOpacity: 0.25,
                    editable: isCurrentEditingTerritory,
                    zIndex: isCurrentEditingTerritory ? 10 : 1,
                    // provide the correct feedback to the user in both edit and non editable mode
                    clickable: false,
                  });
                })
              : [
                  new mapRef.current.maps.Polygon({
                    // array of { lat, lng }
                    paths,
                    strokeColor: territory.color || polygonColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: territory.color || polygonColor,
                    fillOpacity: 0.25,
                    editable: isCurrentEditingTerritory,
                    zIndex: isCurrentEditingTerritory ? 10 : 1,
                    // provide the correct feedback to the user in both edit and non editable mode
                    clickable: false,
                  }),
                ],
          };

          tempTerritoriesAddedById[territory.id] = territoryWithPolygon;
          _.forEach(territoryWithPolygon.polygons, (poly) => {
            mapRef.current.maps.event.addListener(
              poly,
              'mouseup',
              function (evt) {
                if (!_.isNil(evt.vertex) || !_.isNil(evt.path)) {
                  const updatedCoordinates = _.map(
                    territoryWithPolygon.polygons,
                    (relatedPoly) => {
                      const tempCoords = [];
                      // forEach is a defined method, not replaceable by lodash
                      relatedPoly.getPaths().forEach((path) => {
                        // forEach is a defined method, not replaceable by lodash
                        path.forEach((latLng) => {
                          tempCoords.push([latLng.lng(), latLng.lat()]);
                        });
                      });

                      return tempCoords;
                    }
                  );
                  addTempTerritoryCoordinates({
                    items: updatedCoordinates,
                  });
                }
              }
            );
            poly.setMap(mapRef.current.map);
          });
        }
      });

      setAddedTerritories((prevAddedTerritories) => {
        // clear existing polygons that are no longer active
        _.forEach(prevAddedTerritories, (territory) => {
          if (!tempTerritoriesAddedById[territory.id]) {
            _.forEach(territory.polygons, (poly) => {
              poly.setMap(null);
            });
          }
        });

        return tempTerritoriesAddedById;
      });

      // I don't think we need this.
      if (!_.isEmpty(tempTerritoriesAddedById)) {
        const allPolygonBounds = getAllPolygonBounds({
          polygons: _.flatten(
            _.map(tempTerritoriesAddedById, (territory) => {
              return territory.polygons;
            })
          ),
          maps: mapRef.current.maps,
        });

        if (canUpdateBounds()) {
          mapRef.current.map.fitBounds(allPolygonBounds);
        }
      }
    }
  }

  function promptForCreationReset(modeClicked: string) {
    if (drawingManagerDrawMode === modeClicked) {
      // do not prompt alerts if it is the same mode
      return true;
    }

    if (!drawingManagerDrawMode || drawingManagerDrawMode === DRAW_MODES.MOVE) {
      // if we're already in move state do not prompt alerts
      return true;
    }

    if (
      (modeClicked === DRAW_MODES.COUNTY || modeClicked === DRAW_MODES.STATE) &&
      (drawingManagerDrawMode === DRAW_MODES.COUNTY ||
        drawingManagerDrawMode === DRAW_MODES.STATE)
    ) {
      // state and county can switch between modes without issue
      return true;
    }

    if (
      _.size(tempTerritoryCoordinates) ||
      _.size(tempSelectedCounties) ||
      _.size(tempSelectedZipCodes)
    ) {
      return confirm(
        'Are you sure you want to change territory creation modes? It will reset your progress.'
      );
    }

    return true;
  }

  function onGeoJsonMouseOver(event) {
    this.revertStyle();

    if (drawingModeRef.current === DRAW_MODES.COUNTY) {
      setHoverData({
        name: event.feature.getProperty('name'),
      });
      this.overrideStyle(event.feature, {
        strokeWeight: 2,
        strokeOpacity: 0.8,
        fillOpacity: 0.5,
      });
    }

    if (drawingModeRef.current === DRAW_MODES.STATE) {
      const stateId = event.feature?.getProperty('name');
      setHoverData(null);

      stateGeoJsonLayerRef.current?.features?.forEach((feature) => {
        const featureStateId = feature.getProperty('name');
        if (
          stateId === featureStateId ||
          (stateId === VA_STATE_ID && featureStateId === DC_STATE_ID)
        ) {
          this.overrideStyle(feature, {
            strokeWeight: 1,
            strokeOpacity: 0.8,
            fillOpacity: 0.4,
          });
        }
      });
    }

    // zip code hover
    if (drawingModeRef.current === DRAW_MODES.ZIP) {
      setHoverData({
        name: event.feature.getProperty('name'),
      });
      this.overrideStyle(event.feature, {
        strokeWeight: 2,
        strokeOpacity: 0.8,
        fillOpacity: 0.5,
      });
    }
  }

  function onGeoJsonClick(event) {
    this.revertStyle();

    if (
      drawingModeRef.current === DRAW_MODES.COUNTY ||
      drawingModeRef.current === DRAW_MODES.STATE
    ) {
      if (drawingModeRef.current === DRAW_MODES.COUNTY) {
        const modifiedIds = [];
        const newSelectedValue = !event.feature.getProperty('isSelected');
        const countyId = event.feature.getProperty('id');
        modifiedIds.push(countyId);
        event.feature.setProperty('isSelected', newSelectedValue);

        if (newSelectedValue) {
          addTempSelectedCounties({ items: modifiedIds });
        } else {
          removeTempSelectedCounties({ items: modifiedIds });
        }
        log.event('territoryGeoJsonElementClicked', {
          selected: newSelectedValue,
          state: event.feature.getProperty('name'),
          county: event.feature.getProperty('name'),
          mode: drawingModeRef.current,
        });
      }

      if (drawingModeRef.current === DRAW_MODES.STATE) {
        const modifiedStateIds = [];
        const newSelectedValue = !event.feature.getProperty('isSelected');
        const stateId = event.feature.getProperty('id');
        stateGeoJsonLayerRef.current.features.forEach((feature) => {
          const featureStateId = feature.getProperty('id');
          if (
            stateId === featureStateId ||
            (stateId === VA_STATE_ID && featureStateId === DC_STATE_ID)
          ) {
            const countyId = feature.getProperty('id');
            modifiedStateIds.push(countyId);
            feature.setProperty('isSelected', newSelectedValue);
          }
        });

        if (newSelectedValue) {
          addTempSelectedStates({ items: modifiedStateIds });
        } else {
          removeTempSelectedStates({ items: modifiedStateIds });
        }
        log.event('territoryGeoJsonElementClicked', {
          selected: newSelectedValue,
          state: event.feature.getProperty('name'),
          county: event.feature.getProperty('name'),
          mode: drawingModeRef.current,
        });
      }
    } else if (drawingModeRef.current === DRAW_MODES.ZIP) {
      const modifiedZipIds = [];
      const newSelectedValue = !event.feature.getProperty('isSelected');
      const zipId = event.feature.getProperty('id');
      modifiedZipIds.push(zipId);
      event.feature.setProperty('isSelected', newSelectedValue);

      if (newSelectedValue) {
        addTempSelectedZipCodes({ items: modifiedZipIds });
      } else {
        removeTempSelectedZipCodes({ items: modifiedZipIds });
      }

      log.event('territoryGeoJsonElementClicked', {
        selected: newSelectedValue,
        state: event.feature.getProperty('state_name'),
        zip: event.feature.getProperty('name'),
        mode: drawingModeRef.current,
      });
    }
  }

  function setMapDrawState() {
    if (!mapAvailable) return;

    // Set drawing mode
    drawingModeRef.current = drawingManagerDrawMode;
    const reset = promptForCreationReset(drawingManagerDrawMode);
    if (!reset) return;

    // Determine draw mode and state
    const { OverlayType } = mapRef.current.maps.drawing;
    const drawMode =
      drawingManagerDrawMode === DRAW_MODES.DRAW_POLY && !editingTerritory
        ? OverlayType.POLYGON
        : null;
    const isStateCounty =
      drawingManagerDrawMode === DRAW_MODES.STATE ||
      drawingManagerDrawMode === DRAW_MODES.COUNTY;

    // Set drawing options
    mapRef?.current?.drawingManager.setOptions({
      drawingMode: drawMode,
    });

    // Remove selected items
    removeTempSelectedZipCodes({ removeAll: true });
    removeTempSelectedCounties({ removeAll: true });
    removeTempSelectedStates({ removeAll: true });
    // if (!isStateCounty) removeTempSelectedCounties({ removeAll: true });

    // Clear previous drawn polygon
    if (latestDrawnPoly) {
      latestDrawnPoly.setMap(null);
      setLatestDrawnPoly(null);
      addTempTerritoryCoordinates({ items: [] });
    }
  }

  function territoryHydration() {
    if (!mapAvailable) return;
    const tempTerritoriesAddedById: { [id: string]: { polygons; id } } = {};

    _.forEach(selectedTerritories, (territory) => {
      const isNew = !!territory?.polygons;
      const paths = isNew ? territory?.polygons : territory?.old_polygons;
      // something we need to address in the new map, but I think the catch in territory creation will handle this
      // this will only be a just in case scenario
      // if (paths && paths[0].length < 2) {
      //   // set the center of the map
      //   setMapCenter({
      //     lat: 37.0902,
      //     lng: -95.7129,
      //   });

      //   // set the paths to undefined
      //   paths = undefined;
      // }

      const isMultiPolygon = Array.isArray(paths?.[0]?.[0]);

      if (_.isEmpty(paths)) return;

      const notAddedToTempList = !tempTerritoriesAddedById[territory.id];
      const previouslyVisibleTerritory = addedTerritories?.[territory.id];
      if (previouslyVisibleTerritory) {
        _.forEach(previouslyVisibleTerritory?.polygons, (poly) => {
          poly.setMap(null);
        });
      }
      if (notAddedToTempList) {
        const isCurrentEditingTerritory = editingTerritory?.id === territory.id;
        const polygonColor = isCurrentEditingTerritory
          ? COLOR_MAP['blue-dark']
          : COLOR_MAP['gray-dark'];

        const territoryWithPolygon = {
          ...territory,
          polygons: isMultiPolygon
            ? _.map(paths, (path) => {
                return new mapRef.current.maps.Polygon({
                  paths: path,
                  strokeColor: territory.color || polygonColor,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: territory.color || polygonColor,
                  fillOpacity: 0.25,
                  editable: isCurrentEditingTerritory,
                  zIndex: isCurrentEditingTerritory ? 10 : 1,
                  // provide the correct feedback to the user in both edit and non editable mode
                  clickable: false,
                });
              })
            : [
                new mapRef.current.maps.Polygon({
                  // array of { lat, lng }
                  paths,
                  strokeColor: territory.color || polygonColor,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: territory.color || polygonColor,
                  fillOpacity: 0.25,
                  editable: isCurrentEditingTerritory,
                  zIndex: isCurrentEditingTerritory ? 10 : 1,
                  // provide the correct feedback to the user in both edit and non editable mode
                  clickable: false,
                }),
              ],
        };

        tempTerritoriesAddedById[territory.id] = territoryWithPolygon;
        _.forEach(territoryWithPolygon.polygons, (poly) => {
          mapRef.current.maps.event.addListener(
            poly,
            'mouseup',
            function (evt) {
              if (!_.isNil(evt.vertex) || !_.isNil(evt.path)) {
                const updatedCoordinates = _.map(
                  territoryWithPolygon.polygons,
                  (relatedPoly) => {
                    const tempCoords = [];
                    // forEach is a defined method, not replaceable by lodash
                    relatedPoly.getPaths().forEach((path) => {
                      // forEach is a defined method, not replaceable by lodash
                      path.forEach((latLng) => {
                        tempCoords.push([latLng.lng(), latLng.lat()]);
                      });
                    });

                    return tempCoords;
                  }
                );

                addTempTerritoryCoordinates({ items: updatedCoordinates });
              }
            }
          );
          poly.setMap(mapRef.current.map);
        });
      }
    });

    setAddedTerritories((prevAddedTerritories) => {
      // clear existing polygons that are no longer active
      _.forEach(prevAddedTerritories, (territory) => {
        if (!tempTerritoriesAddedById[territory.id]) {
          _.forEach(territory.polygons, (poly) => {
            poly.setMap(null);
          });
        }
      });

      return tempTerritoriesAddedById;
    });

    if (!_.isEmpty(tempTerritoriesAddedById)) {
      const allPolygonBounds = getAllPolygonBounds({
        polygons: _.flatten(
          _.map(tempTerritoriesAddedById, (territory) => {
            return territory.polygons;
          })
        ),
        maps: mapRef.current.maps,
      });

      if (canUpdateBounds()) {
        mapRef.current.map.fitBounds(allPolygonBounds);
      }
    }
  }

  function resetOverlay() {
    // This resets the map
    stateGeoJsonLayerRef.current?.data?.setMap(null);
    countyGeoJsonLayerRef.current?.data?.setMap(null);
    stateZipCodesGeoJsonLayerRef.current?.data?.setMap(null);
    temporaryGeoJsonLayerRef.current?.data?.setMap(null);

    countyGeoJsonLayerRef.current?.features?.forEach((feature) => {
      feature.setProperty('isSelected', false);
    });
    stateGeoJsonLayerRef.current?.features?.forEach((feature) => {
      feature.setProperty('isSelected', false);
    });
    stateZipCodesGeoJsonLayerRef.current?.features?.forEach((feature) => {
      feature.setProperty('isSelected', false);
    });
    temporaryGeoJsonLayerRef.current?.features?.forEach((feature) => {
      feature.setProperty('isSelected', false);
    });

    removeTempSelectedZipCodes({ removeAll: true });
    removeTempSelectedCounties({ removeAll: true });
    removeTempSelectedStates({ removeAll: true });
    // !isStateCounty && removeTempSelectedCounties({ removeAll: true });

    if (latestDrawnPoly) {
      latestDrawnPoly.setMap(null);
      setLatestDrawnPoly(null);
      addTempTerritoryCoordinates({ items: [] });
    }

    stateGeoJsonLayerRef.current = null;
    countyGeoJsonLayerRef.current = null;
    stateZipCodesGeoJsonLayerRef.current = null;
    temporaryGeoJsonLayerRef.current = null;
  }

  function clearMap() {
    _.forEach(addedTerritories, (territory) => {
      _.forEach(territory.polygons, (poly) => {
        poly.setMap(null);
      });
    });
  }

  const onChange = (state) => {
    if (!state) return;
    setZoom(state.zoom);

    setBounds([
      [state.bounds.nw.lat, state.bounds.nw.lng],
      [state.bounds.se.lat, state.bounds.se.lng],
    ]);
    // Cluster bounds are in a different format than the bounds for territory data
    // TODO: Refactor to use the same format, need to talk to Jim about this
    setClusterBounds([
      state.bounds.nw.lng,
      state.bounds.se.lat,
      state.bounds.se.lng,
      state.bounds.nw.lat,
    ]);

    setMapState(state);

    // React was not happy with this inside of setMapState callback
    !!mapZoomCallback && mapZoomCallback(state);
  };

  // Take all of the points on our map and split them into clusters
  const clusterObjects = _.map(visiblePoints, (point) => {
    return {
      details: point,
      properties: {
        cluster: false,
        id: point.id,
        count: point.count,
        provider_id: point.provider_id,
        hovered: `${hoveredProviderId}` === `${point.provider_id}`,
      },
      geometry: {
        type: 'Point',
        coordinates: [point.lng, point.lat],
      },
    };
  });

  // Initialize the clusters
  let radius = 75;
  if (disableClusters || zoom >= MAX_ZOOM) {
    radius = 0;
  } else if (zoom < MAX_ZOOM && zoom > 14) {
    radius = 25;
  } else if (zoom < 11 && zoom > 8) {
    radius = 50;
  }

  const { clusters, supercluster } = useSupercluster({
    points: clusterObjects,
    bounds: clusterBounds,
    zoom,
    options: {
      clickableIcons: false,
      radius,
      maxZoom: MAX_ZOOM,
      map: (props) => {
        return {
          provider_id: props.provider_id || props.id,
          hovered: props.hovered,
          count: props.count,
        };
      },
      reduce: (acc, props) => {
        acc.hovered = acc.hovered || props.hovered;
        if (!acc.count) acc.count = 1;

        if (!props.count) props.count = 1;

        acc.count += props.count;
        return acc;
      },
    },
  });

  // Zoom and expand to a level that automatically breaks the clusters apart when one is clicked on
  const clusterClick = (clusterId, lat, lng) => {
    const zoomLevel = supercluster.getClusterExpansionZoom(clusterId);
    const expansionZoom = isNaN(zoomLevel)
      ? Math.min(zoom + 2, MAX_ZOOM)
      : zoomLevel;

    if (expansionZoom >= MAX_ZOOM && zoom === expansionZoom - 1) {
      // When we have hit max zoom and a cluster is clicked on w/ the same zoom level
      // Focus the marker, to display info for any locations under the cluster
      // maintain toggle functionality
      setActiveMarker(activeMarker === clusterId ? null : clusterId);
    } else {
      // Not at max zoom for cluster, zoom to attempt showing more fine grained markers
      // Remove any previously focused marker
      mapRef.current.map.panTo({ lat, lng });
      mapRef.current.map.setZoom(expansionZoom);
      setActiveMarker(null);
    }
  };

  // Callback for when a marker is clicked
  // If the one that's clicked is the active one, deactivate it to hide the info box
  const markerClick = (markerId: string) => {
    const marker = markerId === activeMarker ? null : markerId;
    setActiveMarker(marker);
  };

  const MarkerSet = clusters.map((cluster, index) => {
    const [lng, lat] = cluster.geometry.coordinates;
    const { cluster: isCluster, count } = cluster.properties;
    const showClusterMarkerInfo = isCluster && zoom === MAX_ZOOM;

    if (isCluster) {
      return (
        <MapMarker
          id={cluster.id}
          key={`cluster_${cluster.id}_${index}`}
          type={'cluster'}
          lat={lat}
          lng={lng}
          isHovered={cluster.properties.hovered}
          clusterLeaves={
            showClusterMarkerInfo && supercluster.getLeaves(cluster.id, 200)
          }
          pointCount={count}
          onClick={clusterClick}
          onAddToList={onAddToList}
        />
      );
    }

    return (
      <MapMarker
        type={'point'}
        id={cluster.details.provider_id}
        key={`point_${cluster.details.provider_id}_${index}`}
        lat={lat}
        lng={lng}
        onClick={markerClick}
        details={cluster.details}
        pointCount={count}
        isHovered={cluster.properties.hovered || cluster.details.hovered}
        onAddToList={onAddToList}
      />
    );
  });

  return (
    <>
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
            libraries: ['places', 'geometry', 'drawing'],
          }}
          defaultCenter={defaultCenter}
          defaultZoom={MIN_ZOOM}
          zoom={zoom}
          onChange={onChange}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            const drawingManager = new maps.drawing.DrawingManager({
              drawingMode: null,
              drawingControl: false,
              drawingControlOptions: {
                position: maps.ControlPosition.TOP_CENTER,
                drawingModes: [maps.drawing.OverlayType.POLYGON],
              },
              polygonOptions: {
                strokeColor: COLOR_MAP['blue-dark'],
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: COLOR_MAP['blue-dark'],
                fillOpacity: 0.25,
                editable: true,
                clickable: false,
              },
            });

            mapRef.current = { map, maps, drawingManager };

            // on initialization if the center is an array of points, center the map initially on all points
            if (_.isArray(center)) {
              const bounds = new maps.LatLngBounds();
              _.forEach(center, (centeredMarker) => {
                bounds.extend({
                  lat: centeredMarker.lat,
                  lng: centeredMarker.lng,
                });
              });
              map.fitBounds(bounds);
            }
            setMapAvailable(true);

            maps.event.addListener(
              drawingManager,
              'overlaycomplete',
              function (event) {
                if (event.type === maps.drawing.OverlayType.POLYGON) {
                  const newPoly = event.overlay;
                  const tempCoords = [];
                  // forEach is a defined method, not replaceable by lodash
                  newPoly.getPaths().forEach((path) => {
                    if (path.length < 3) {
                      // user has aborted drawing and can't progress easily, clear poly and try again
                      return false;
                    } else {
                      // forEach is a defined method, not replaceable by lodash
                      path.forEach((latLng) => {
                        tempCoords.push([latLng.lng(), latLng.lat()]);
                      });
                    }
                  });

                  if (_.isEmpty(tempCoords)) {
                    setLatestDrawnPoly(null);
                    newPoly.setMap(null);
                    addTempTerritoryCoordinates({ items: [] });
                    return;
                  }

                  setLatestDrawnPoly(newPoly);
                  addTempTerritoryCoordinates({ items: tempCoords });

                  maps.event.addListener(newPoly, 'mouseup', function (evt) {
                    if (!_.isNil(evt.vertex) || !_.isNil(evt.path)) {
                      const coords = [];
                      // forEach is a defined method, not replaceable by lodash
                      newPoly.getPaths().forEach((path) => {
                        // forEach is a defined method, not replaceable by lodash
                        path.forEach((latLng) => {
                          coords.push([latLng.lng(), latLng.lat()]);
                        });
                      });
                      addTempTerritoryCoordinates({ items: coords });
                    }
                  });

                  drawingManager.setOptions({ drawingMode: null });
                }
              }
            );
            drawingManager.setMap(map);
          }}
          options={{
            styles: showCreateTools || editingTerritory ? mapStyles : null,
            scrollwheel: true,
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            fullscreenControl: false,
            zoomControlOptions: {
              position: global.google?.maps?.ControlPosition?.RIGHT_TOP || 3,
            },
          }}
        >
          {updateOnMapMove && MarkerSet}
        </GoogleMapReact>
        {children}
      </div>
      {hoverData && <HoverData data={hoverData} />}
    </>
  );
};

export default Map;
