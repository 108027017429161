import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel } from '@mui/material';

interface ControlledSwitchProps {
  name: string;
  label?: string;
  rules?: any;
  required?: boolean;
  value?: boolean;
  errorText?: string;
  disabled?: boolean;
}

const ControlledSwitch = ({ ...props }: ControlledSwitchProps) => {
  const { control } = useFormContext();

  if (!props) return null;

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={props.value || false}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={field.onChange}
                disabled={props.disabled || false}
              />
            }
            label={props.label}
          />
        );
      }}
    />
  );
};

export default ControlledSwitch;
