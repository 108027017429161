// use Mui Joy color palette
// https://mui.com/material-ui/customization/color/

export const colorPalette = {
  red: {
    main: '#d32f2f',
    light1: '#e57373',
    light2: '#ff8a80',
    dark1: '#c62828',
    dark2: '#b71c1c',
  },
  pink: {
    main: '#c2185b',
    light1: '#ec407a',
    light2: '#f48fb1',
    dark1: '#ad1457',
    dark2: '#880e4f',
  },
  purple: {
    main: '#7b1fa2',
    light1: '#ba68c8',
    light2: '#ce93d8',
    dark1: '#6a1b9a',
    dark2: '#4a148c',
  },
  deepPurple: {
    main: '#512da8',
    light1: '#9575cd',
    light2: '#b39ddb',
    dark1: '#4527a0',
    dark2: '#311b92',
  },
  indigo: {
    main: '#303f9f',
    light1: '#7986cb',
    light2: '#9fa8da',
    dark1: '#283593',
    dark2: '#1a237e',
  },
  blue: {
    main: '#1976d2',
    light1: '#64b5f6',
    light2: '#90caf9',
    dark1: '#1565c0',
    dark2: '#0d47a1',
  },
  lightBlue: {
    main: '#0288d1',
    light1: '#4fc3f7',
    light2: '#81d4fa',
    dark1: '#0277bd',
    dark2: '#01579b',
  },
  cyan: {
    main: '#0097a7',
    light1: '#4dd0e1',
    light2: '#80deea',
    dark1: '#00838f',
    dark2: '#006064',
  },
  teal: {
    main: '#00796b',
    light1: '#4db6ac',
    light2: '#80cbc4',
    dark1: '#00695c',
    dark2: '#004d40',
  },
  green: {
    main: '#388e3c',
    light1: '#81c784',
    light2: '#a5d6a7',
    dark1: '#2e7d32',
    dark2: '#1b5e20',
  },
  lightGreen: {
    main: '#689f38',
    light1: '#aed581',
    light2: '#c5e1a5',
    dark1: '#558b2f',
    dark2: '#33691e',
  },
  lime: {
    main: '#afb42b',
    light1: '#dce775',
    light2: '#e6ee9c',
    dark1: '#9e9d24',
    dark2: '#827717',
  },
  yellow: {
    main: '#fbc02d',
    light1: '#fff176',
    light2: '#fff59d',
    dark1: '#f9a825',
    dark2: '#f57f17',
  },
  amber: {
    main: '#ffa000',
    light1: '#ffd54f',
    light2: '#ffe082',
    dark1: '#ff8f00',
    dark2: '#ff6f00',
  },
  orange: {
    main: '#f57c00',
    light1: '#ffb74d',
    light2: '#ffcc80',
    dark1: '#ef6c00',
    dark2: '#e65100',
  },
  deepOrange: {
    main: '#e64a19',
    light1: '#ff8a65',
    light2: '#ffab91',
    dark1: '#d84315',
    dark2: '#bf360c',
  },
  brown: {
    main: '#5d4037',
    light1: '#a1887f',
    light2: '#bcaaa4',
    dark1: '#4e342e',
    dark2: '#3e2723',
  },
  grey: {
    main: '#616161',
    light1: '#9e9e9e',
    light2: '#bdbdbd',
    dark1: '#424242',
    dark2: '#212121',
  },
  blueGrey: {
    main: '#455a64',
    light1: '#78909c',
    light2: '#90a4ae',
    dark1: '#37474f',
    dark2: '#263238',
  },
};
