import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  CircularProgress,
  Button,
} from '@mui/joy';
import { EditRounded, LocalFireDepartmentRounded } from '@mui/icons-material';
import { AddEditTerritory, TerritoryItem } from './components';
import { TerritoryGroup } from 'src/modules/Discovery/components/TerritoryPanel/components';
import {
  useAuth,
  useLogging,
  useMedScoutMap,
  useNotification,
} from 'src/context';
import { useGetAllMedTerritories, getTerritory } from 'src/hooks';

export const NO_TERRITORY_ACTIVE_SELECTION = {
  id: 'USE_MAP_NO_TERRITORY',
  name: 'Map Without Territory',
};

const TerritoryPanel = ({
  showRepList,
  showTerritory,
  toggleDrawer,
  onToggleDrawer,
}) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { user, setUser } = useAuth();
  const {
    currentTerritory,
    setCurrentTerritory,
    isEditing,
    setIsEditing,
    isCreating,
    setIsCreating,
  } = useMedScoutMap();

  const { data: myTerritoryData, isLoading: isLoadingTerritories } =
    useGetAllMedTerritories();

  const currentlySetTerritory = useMemo(() => {
    return (
      myTerritoryData?.find((territory) => {
        return territory.id === currentTerritory?.id;
      }) || null
    );
  }, [currentTerritory, myTerritoryData]);

  const combinedData = useCallback(() => {
    const currentTerritories =
      myTerritoryData && myTerritoryData?.length > 0 ? myTerritoryData : [];
    const repTerritories = currentTerritories
      ?.filter((rep) => rep.owner?.id !== user.id)
      ?.sort((a, b) => {
        if (a.is_canonical !== b.is_canonical) {
          return a.is_canonical ? -1 : 1;
        }
        return a.name.localeCompare(b.name);
      })
      ?.map((territory) => ({
        ...territory,
        repName: `${territory.owner.first_name} ${territory.owner.last_name}`,
      }))
      ?.sort((a, b) => a.repName.localeCompare(b.repName));

    const myTerritories = currentTerritories
      ?.filter((territory) => territory.owner?.id === user.id)
      .concat({
        id: NO_TERRITORY_ACTIVE_SELECTION.id,
        value: NO_TERRITORY_ACTIVE_SELECTION.id,
        name: NO_TERRITORY_ACTIVE_SELECTION.name,
      })
      ?.sort((a, b) => {
        if (a.value === 'USE_MAP_NO_TERRITORY') return -1;

        return a.name.localeCompare(b.name);
      });

    myTerritories?.unshift({
      id: 'addNew',
      value: 'addNew',
      name: 'Add a Territory',
    });

    return [...myTerritories, ...repTerritories];
  }, [myTerritoryData, user]);

  const handleTerritoryEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleTerritoryChange = async (
    e: React.ChangeEvent,
    territory: any
  ) => {
    if (!territory) return;

    if (territory.id === 'addNew') {
      setIsCreating(true);
      return;
    } else if (territory.id === NO_TERRITORY_ACTIVE_SELECTION.id) {
      log.event('useMapWithNoTerritorySelected');
      setCurrentTerritory(null);
      setUser({ ...user, last_territory: null });
      return;
    }

    try {
      const territoryData = await getTerritory(territory.id);
      setCurrentTerritory(territoryData);
      setUser({ ...user, last_territory: territoryData });
    } catch (err) {
      console.log('Error getting territory', err);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        padding: '0.5rem',
      }}
    >
      {isEditing || isCreating ? (
        <AddEditTerritory
          showRepList={showRepList}
          showTerritory={showTerritory}
          toggleDrawer={toggleDrawer}
          onToggleDrawer={onToggleDrawer}
        />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            disableClearable
            value={currentlySetTerritory || NO_TERRITORY_ACTIVE_SELECTION}
            options={combinedData() || []}
            loading={isLoadingTerritories}
            clearOnBlur
            getOptionLabel={(option) => option.name}
            onChange={handleTerritoryChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            groupBy={(option) => option.repName}
            renderGroup={(params) => (
              <Box key={params.key}>
                {params.group && (
                  <TerritoryGroup
                    params={params}
                    activeTerritory={currentTerritory}
                  />
                )}
                {!params.group && params.children}
              </Box>
            )}
            renderOption={(props, option) => (
              <TerritoryItem
                key={option.id}
                props={props}
                option={option}
                activeTerritory={currentTerritory}
              />
            )}
            sx={{
              minWidth: '250px',
              borderTopLeftRadius: 'sm', // Round top-left corner
              borderBottomLeftRadius: 'sm', // Round bottom-left corner
              borderTopRightRadius: 0, // Flat top-right corner
              borderBottomRightRadius: 0, // Flat bottom-right corner
            }}
          />
          <IconButton
            variant="outlined"
            color="neutral"
            onClick={handleTerritoryEdit}
            disabled={isLoadingTerritories}
            sx={{
              backgroundColor: 'white',
              borderTopLeftRadius: 0, // Flat top-left corner
              borderBottomLeftRadius: 0, // Flat bottom-left corner
              borderTopRightRadius: 'sm', // Round top-right corner
              borderBottomRightRadius: 'sm', // Round bottom-right corner
            }}
          >
            {isLoadingTerritories ? (
              <CircularProgress size="sm" />
            ) : (
              <EditRounded fontSize="small" />
            )}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default TerritoryPanel;
