import useSWR from 'swr';
import { getLists } from './listsApi';
import { QUERY_KEYS } from './constants';

const useGetLists = () => {
  return useSWR([QUERY_KEYS.GET_LISTS], () => getLists(), {
    keepPreviousData: true,
  });
};

export default useGetLists;
