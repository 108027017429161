import { Box, Input } from '@mui/joy';
import { SelectorLabel } from './DataIssueStyles';

interface DataIssuePhoneProps {
  phone: string;
  setPhone: (value: string) => void;
}

const DataIssuePhone = ({ phone, setPhone }: DataIssuePhoneProps) => {
  return (
    <Box sx={{ mt: 2 }}>
      <SelectorLabel>
        If you found the correct phone number, please provide it bleow:
      </SelectorLabel>
      <Input
        placeholder="Phone number"
        value={phone || ''}
        onChange={(e) => setPhone(e.target.value)}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};

export { DataIssuePhone };
