import React from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';
import {
  FileDownloadRounded,
  SimCardDownloadRounded,
  OpenInNewRounded,
  CloseRounded,
} from '@mui/icons-material';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  width: '1.50rem',
  height: '1.50rem',
  minWidth: '1.50rem',
  minHeight: '1.50rem',
  maxWidth: '1.50rem',
  maxHeight: '1.50rem',
  '&:hover': {
    background: 'none !important',
    color: theme.palette?.highlight?.main,
  },
}));

const BladeCommandButton = ({
  type,
  onClick,
}: {
  type: string;
  onClick: () => void;
}) => {
  const iconMap = {
    download: SimCardDownloadRounded,
    export: FileDownloadRounded,
    tearoff: OpenInNewRounded,
    close: CloseRounded,
  };

  const iconTitleMap = {
    download: 'Download',
    export: 'Export',
    tearoff: 'Tear Off',
    close: 'Close Blade',
  };

  const iconAriaLabelMap = {
    download: 'Download',
    export: 'Export',
    tearoff: 'Tear Off',
    close: 'Close Blade',
  };

  const IconComponent = iconMap[type];
  if (!IconComponent) return null;

  return (
    <Tooltip title={iconTitleMap[type]} placement="bottom" arrow>
      <span>
        <StyledIconButton
          key={type}
          size="small"
          onClick={onClick}
          aria-label={iconAriaLabelMap[type]}
        >
          <IconComponent
            sx={{
              fontSize: '1.25rem',
            }}
          />
        </StyledIconButton>
      </span>
    </Tooltip>
  );
};

export default BladeCommandButton;
