import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Select, Option, FormHelperText, SelectProps } from '@mui/joy';

interface ControlledJoySelectProps extends SelectProps<any, any> {
  options: any[];
  rules?: any;
  errorText?: string;
}

const ControlledJoySelect = ({ ...props }: ControlledJoySelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!props) return null;

  const errorMessage = props?.errorText || 'This field is required';
  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: props.required ? errorMessage : false,
        ...props.rules,
      }}
      render={({ field }) => {
        return (
          <>
            <Select
              {...field}
              value={field.value || ''}
              placeholder={props.placeholder || 'Select a manager'}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              slotProps={{
                listbox: {
                  sx: {
                    maxHeight: 250,
                  },
                },
              }}
              disabled={props.disabled}
            >
              {props?.options?.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            <FormHelperText sx={{ ml: 0 }}>
              {!!errors[props.name] && (errors[props.name]?.message as string)}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default ControlledJoySelect;
