import React, { useRef, useState, useEffect } from 'react';
import { Grid, Typography, styled } from '@mui/material';
import _ from 'lodash';
import { GoogleStaticMap } from 'src/components/GoogleStaticMap';
import { handleNumericValue } from 'src/utils';

const DataLabel = styled(Typography)`
  font-size: 0.65rem;
  font-weight: 300;
  font-style: italic;
  color: white;
  padding-bottom: 0.5em;
`;
const DataValue = styled(Typography)`
  color: white;
  font-size: 0.85rem;
  padding-bottom: 0.25em;
  padding-left: 0.5rem;
`;
const VolumeGroup = styled('dl')({
  display: 'flex',
  flexWrap: 'wrap',
  color: 'white',
  alignItems: 'center',
  margin: '0',
  paddingLeft: '0.5rem',
  '& dt, & dd': {
    padding: 0,
    margin: 0,
  },
  '& dt': {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    fontWeight: 300,
    fontSize: '1rem',
  },
  '& dd': {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
});

interface TerritorySubheaderProps {
  data: any;
  volumeData: any;
}

const TerritorySubheader: React.FC<TerritorySubheaderProps> = ({
  data,
  volumeData,
}) => {
  const [mapSize, setMapSize] = useState('');
  const mapCellRef = useRef(null);

  useEffect(() => {
    function sizeMap() {
      if (mapCellRef.current) {
        setMapSize(
          `${mapCellRef.current.clientWidth}x${mapCellRef.current.clientHeight}`
        );
      }
    }

    window.addEventListener('resize', () => {
      sizeMap();
    });
    sizeMap();
  }, []);

  if (!data) return null;
  const isNew = !!data?.polygons;
  const newData = isNew ? data?.polygons : data?.old_polygons;
  return (
    <Grid container sx={{ border: '1px #597992 solid', minHeight: '10rem' }}>
      <Grid
        item
        xs={6}
        sx={{
          borderRight: '1px #597992 solid',
          borderCollapse: 'collapse',
          padding: '0.5rem',
        }}
      >
        <DataLabel>Associated Volumes:</DataLabel>

        {volumeData.map((result, i) => {
          return (
            <VolumeGroup key={`totalVolume_${i}`}>
              <dt>{_.truncate(result.label, { length: 32 })}</dt>
              <dd>{handleNumericValue(result.value)}</dd>
            </VolumeGroup>
          );
        })}
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          borderRight: '1px #597992 solid',
          borderCollapse: 'collapse',
          padding: '0.5rem',
        }}
      >
        <DataLabel>Owner:</DataLabel>
        <DataValue variant="body1">{`${data.owner?.first_name} ${data.owner?.last_name}`}</DataValue>
      </Grid>
      <Grid ref={mapCellRef} item xs={3}>
        {mapSize && newData && (
          <GoogleStaticMap polygons={newData} id={data.id} size={mapSize} />
        )}
      </Grid>
    </Grid>
  );
};

export default TerritorySubheader;
