import { Box, styled } from '@mui/joy';
import { GridToolbarContainerProps } from '@mui/x-data-grid-premium';
import { grey } from '@mui/material/colors';

interface ToolbarProps extends GridToolbarContainerProps {
  onExport?: (type: string) => void;
  onRemove?: () => void;
  headerSlots?: {
    slot1?: React.ReactNode;
    slot2?: React.ReactNode;
    slot3?: React.ReactNode;
    slot4?: React.ReactNode;
  };
}

const ToolbarRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  height: '6rem',
}));

const TableToolbar = ({ ...props }: ToolbarProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        borderBottom: `1px solid ${grey[300]}`,
        height: '13rem',
        background: props.headerSlots?.slot3 ? 'white' : '#E5F3FB', // TODO: bullshit solution Jon
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: '1fr 1fr',
        }}
      >
        {props.headerSlots?.slot1 && (
          <ToolbarRow sx={{ borderBottom: `1px solid ${grey[300]}` }}>
            {props.headerSlots.slot1}
          </ToolbarRow>
        )}

        {props.headerSlots?.slot2 && (
          <ToolbarRow>{props.headerSlots.slot2}</ToolbarRow>
        )}
      </Box>
    </Box>
  );
};

export default TableToolbar;
