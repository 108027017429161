import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

export const getBoundaries = async ({ bounding_box = [], drawingMode }) => {
  if (!drawingMode || drawingMode === 'DRAW') return;

  const queryParams = getSearchQueryParamsForAPI({
    bounding_box,
  });

  const typeNameMap = {
    COUNTY: 'counties',
    STATE: 'states',
    ZIPCODE: 'zip_codes',
  };

  return await request({
    url: `v1/accounts/boundaries/${typeNameMap[drawingMode]}${queryParams}`,
    data: {},
    method: 'GET',
  });
};

export const getMedMapSearchResults = async (queryParams) => {
  if (!queryParams) return;

  return await request({
    url: `/v1/query/map${queryParams}`,
    data: {},
    method: 'GET',
  });
};
