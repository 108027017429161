import React, { useEffect, useState } from 'react';
import { Map, useMap, useApiIsLoaded } from '@vis.gl/react-google-maps';
import {
  useMedScoutMap,
  useProspectSearch,
  useDiscoveryMap,
} from 'src/context';
import { MedMapContent } from './components/MedMapContent';
import { Box, CircularProgress } from '@mui/joy';
import {
  BladeToggle,
  TerritoryPanel,
  MedScoutLoadingIcon,
  TerritoryBlade,
  AddProviderDialog,
} from 'src/components';
// TODO: moved this to a real provider
import { SelectedProspectProvider } from 'src/modules/Discovery/context/selectedProspects';
import {
  useGetDashboards,
  useGetMedBoundaries,
  useGetMedMapSearchResults,
} from 'src/hooks';
import { MedMapMarkers } from './components/MedMapMarkers';
import { MAX_WINDOW_WIDTH, MAX_ZOOM } from 'src/components/Map/constants';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';

import { DISCOVERY_BLADE_NAMES } from 'src/modules/Discovery/constants';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/pro-solid-svg-icons';
import { styled } from '@mui/material';
import {
  NO_TERRITORY_ACTIVE_SELECTION,
  RepsListBlade,
  ResultsBlade,
} from 'src/modules/Discovery/components';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: 1,
  backgroundColor: 'rgba(0,0,0,0.15)',
});

const LoadingContent = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '0.625rem',
  paddingRight: '0.625rem',
  paddingLeft: '0.625rem',
});

const drawerWidth = 40;

const isLoaded = () => {
  return (
    typeof google !== 'undefined' &&
    typeof google.maps !== 'undefined' &&
    typeof google.maps.Map !== 'undefined'
  );
};

const DiscoveryMap = () => {
  const apiIsLoaded = useApiIsLoaded();
  const map = useMap();
  const { width } = useWindowSize();
  const MIN_ZOOM = width > MAX_WINDOW_WIDTH ? 5 : 4;

  const {
    prospectFilters: filters,
    prospectType,
    prospectMode,
  } = useProspectSearch(); // has to be in a SelectedProsectProvider

  const {
    editingTerritory,
    selectedTerritories,
    setSelectTerritories,
    createNewTerritoryMode,
  } = useDiscoveryMap();

  const [newProviders, setNewProviders] = useState(null);

  const [mapApiIsLoading, setMapApiIsLoading] = React.useState(true);
  const [activeMarker, setActiveMarker] = useState<number | null>(null);
  const [toggleDrawer, setToggleDrawer] = useState<{
    drawer: string;
    open: boolean;
  } | null>(null);

  useEffect(() => {
    if (!apiIsLoaded) return;
    setMapApiIsLoading(false);
  }, [apiIsLoaded, map]);

  const { isLoading } = useGetMedBoundaries();
  const { isLoading: isLoadingResults } = useGetMedMapSearchResults();
  const { data: dashboardData } = useGetDashboards();
  const {
    zoom,
    center,
    mapBounds,
    setBounds,
    setMapBounds,
    setZoom,
    currentTerritory,
    drawingMode,
    isEditing,
    isCreating,
    resultsListParams,
    setResultsListParams,
    adhocTerritory,
  } = useMedScoutMap();

  // get current territory paths
  const isNew = !!currentTerritory?.polygons;
  const paths = isNew
    ? currentTerritory?.polygons
    : currentTerritory?.old_polygons;

  useEffect(() => {
    if (!currentTerritory) {
      setSelectTerritories([]);
    }
    setToggleDrawer({
      drawer: DISCOVERY_BLADE_NAMES.RESULTS,
      open: true,
    });

    // TODO: This is temporary until we remove the old map
    if (currentTerritory?.id) {
      // check if currentTerritory is in selectedTerritories
      // don't add it if it is
      const isAlreadySelected = selectedTerritories.some(
        (territory) => territory.id === currentTerritory.id
      );

      if (!isAlreadySelected) {
        setSelectTerritories([currentTerritory]);
      }
    }
  }, [currentTerritory]);

  useEffect(() => {
    let containsNonExclusionPaymentsFilter = false;

    const groupedFiltersByContext = filters.reduce(
      (result, { context, value }) => {
        if (!containsNonExclusionPaymentsFilter) {
          // [id, min, max] value pattern for payments, when max is defined as 0 this is an 'exclusion payment filter'
          containsNonExclusionPaymentsFilter =
            context !== 'payments' ? true : (value?.['2'] ?? null) !== 0;
        }

        if (!result[context]) {
          result[context] = context === 'year' ? value : [value];
        } else {
          result[context] = [...result[context], value];
        }

        return result;
      },
      {}
    );

    const sortedParams = Object.keys(groupedFiltersByContext).reduce(
      (result, key) => {
        const currentParams = groupedFiltersByContext[key];
        if (Array.isArray(currentParams)) {
          result[key] = Array.from(new Set(currentParams)).sort();
        } else {
          result[key] = currentParams;
        }
        return result;
      },
      {}
    );

    const selectedTerritoryIds = selectedTerritories.reduce(
      (result, territory) => {
        if (territory?.id?.toString() !== NO_TERRITORY_ACTIVE_SELECTION.id) {
          result.push(territory?.id);
        }
        return result;
      },
      []
    );

    const encodedParams = getSearchQueryParamsForAPI({
      type: prospectType,
      mode: prospectMode,
      ex_pr: 0,
      map: selectedTerritoryIds,
      zoom: zoom || 4,
      ...sortedParams,
    });

    setResultsListParams({
      queryParams: encodedParams,
      enabled: true,
    });
  }, [prospectType, prospectMode, filters, selectedTerritories, zoom]);

  useEffect(() => {
    if (!map || drawingMode === 'DRAW' || !paths) return;

    const bounds = new google.maps.LatLngBounds();
    paths.forEach((path) => {
      path.forEach((point) => {
        bounds?.extend(point);
      });
    });

    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
    map.setZoom(map.getZoom());
  }, [drawingMode, map, paths]);

  const handleBoundsChanged = (bounds: any) => {
    if (!bounds || !apiIsLoaded) return;
    const { north, south, east, west } = bounds.detail.bounds;
    if (!north || !south || !east || !west) return;

    // allow allow 4 decimal places

    const newWest = parseFloat(west.toFixed(4));
    const newNorth = parseFloat(north.toFixed(4));
    const newEast = parseFloat(east.toFixed(4));
    const newSouth = parseFloat(south.toFixed(4));

    setBounds([
      [north, west],
      [south, east],
    ]);

    setMapBounds([
      [newWest, newNorth],
      [newEast, newNorth],
      [newEast, newSouth],
      [newWest, newSouth],
      [newWest, newNorth],
    ]);
  };

  const handleZoomChanged = (zoom: any) => {
    if (!zoom || !apiIsLoaded) return;
    const newZoom = zoom?.detail?.zoom || MIN_ZOOM;
    setZoom(newZoom);
  };

  const noop = () => {
    return;
  };
  const addToList = async (providers, fromMap = false, onSuccess = noop) => {
    setNewProviders({
      providers: Array.isArray(providers)
        ? structuredClone(providers)
        : [{ ...providers }],
      ui_fromMap: fromMap,
      onSuccess,
    });
  };

  const handleToggleDrawer = (name = null, open = null) => {
    setToggleDrawer((prev) => {
      if (!name) return null;
      if (prev?.drawer === name) {
        return {
          ...prev,
          open: !prev.open,
        };
      }
      return {
        drawer: name,
        open: !!open,
      };
    });
  };

  const handleActiveMarker = (provider_id: number | string) => {
    setActiveMarker(Number(provider_id));
  };

  const showMarkers = !isEditing && !isCreating;

  if (!isLoaded() || mapApiIsLoading || !apiIsLoaded) {
    return (
      <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <SelectedProspectProvider>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <Map
            mapId="discovery-map"
            style={{ width: '100%', height: '100%' }}
            defaultCenter={center}
            defaultZoom={MIN_ZOOM}
            zoom={zoom}
            gestureHandling={'greedy'}
            disableDefaultUI
            onBoundsChanged={handleBoundsChanged}
            onZoomChanged={handleZoomChanged}
            fullscreenControl={false}
            maxZoom={MAX_ZOOM}
            minZoom={MIN_ZOOM}
            scrollwheel={true}
            zoomControl={true}
            zoomControlOptions={{
              position: global.google?.maps?.ControlPosition?.RIGHT_TOP || 3,
            }}
          >
            {showMarkers && (
              <MedMapMarkers
                activeMarker={activeMarker}
                setActiveMarker={setActiveMarker}
                addToList={addToList}
              />
            )}
            <MedMapContent />
          </Map>
          <TerritoryPanel
            showRepList={
              toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.REP_LIST &&
              toggleDrawer?.open
            }
            showTerritory={
              !!adhocTerritory &&
              toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.TERRITORY &&
              toggleDrawer?.open
            }
            toggleDrawer={toggleDrawer}
            onToggleDrawer={handleToggleDrawer}
          />
          {toggleDrawer && (
            <BladeToggle
              open={toggleDrawer?.open}
              drawer={toggleDrawer?.drawer}
              toggleOpen={() => handleToggleDrawer(toggleDrawer?.drawer)}
            >
              <Icon
                icon={
                  toggleDrawer?.open ? faAngleDoubleRight : faAngleDoubleLeft
                }
              />
            </BladeToggle>
          )}
          {/* TODO: Debounce the bounds, I think they are causing excessive load times */}
          {(isLoading || isLoadingResults) && (
            <LoadingContainer>
              <LoadingContent>
                <MedScoutLoadingIcon />
              </LoadingContent>
            </LoadingContainer>
          )}
        </Box>
        {map && mapBounds && (
          <>
            <ResultsBlade
              open={
                toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.RESULTS &&
                toggleDrawer?.open
              }
              onClose={() => handleToggleDrawer(toggleDrawer?.drawer)}
              addToList={addToList}
              contentType={prospectType}
              searchQueryParams={resultsListParams}
              mapState={{
                bounds: mapBounds,
              }}
              onResultHover={handleActiveMarker}
              width={drawerWidth}
              updateOnMapMove={!editingTerritory && !createNewTerritoryMode}
            />
            <TerritoryBlade
              territoryId={'adhoc-territory'}
              open={
                !!adhocTerritory &&
                toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.TERRITORY &&
                toggleDrawer?.open
              }
              onClose={() => handleToggleDrawer(toggleDrawer?.drawer)}
              dashboardId={dashboardData?.results[0]?.id}
              adhoc={adhocTerritory}
              width={drawerWidth}
            />
            <RepsListBlade
              open={
                toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.REP_LIST &&
                toggleDrawer?.open
              }
              onClose={() => handleToggleDrawer(toggleDrawer?.drawer)}
              width={drawerWidth}
            />
          </>
        )}
      </Box>
      {newProviders && (
        <AddProviderDialog
          onHide={() => setNewProviders(null)}
          newProviders={newProviders}
        />
      )}
    </SelectedProspectProvider>
  );
};

export default DiscoveryMap;
