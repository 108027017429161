import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

const useGetPayerMix = ({ id }) => {
  return useQuery(
    [QUERY_KEYS.PAYER_MIX, id],
    async () => {
      return await request({
        url: `v1/providers/${id}/payer-mix/`,
        method: 'GET',
      });
    },
    {
      enabled: !!id,
    }
  );
};

export default useGetPayerMix;
