import { Typography } from '@mui/joy';
import React from 'react';

const TrendVolumeHeader = ({ field, ...props }) => {
  return (
    <Typography
      level="title-sm"
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {props?.colDef?.headerName}
    </Typography>
  );
};

export default TrendVolumeHeader;
