import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

interface GetDataIssueSuggestions {
  issueType: string;
  providerId: string;
  providerType: string;
}

const useGetDataIssueSuggestions = ({
  issueType,
  providerId,
  providerType,
}: GetDataIssueSuggestions) => {
  return useQuery(
    [QUERY_KEYS.dataIssueSuggestions, issueType, providerId],
    async () => {
      const response = await request({
        url: `/v1/${providerType}/${providerId}/${issueType}`,
        method: 'GET',
      });

      // we want primary address first
      response.sort((a, b) => b.is_primary - a.is_primary);

      return response;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: !!issueType && !!providerId && !!providerType,
    }
  );
};

export default useGetDataIssueSuggestions;
