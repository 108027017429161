import { useQuery } from 'react-query';
import { QUERY_KEYS } from './constants';
import { request } from 'src/api/request';

function useGetMyTeam() {
  return useQuery(
    [QUERY_KEYS.my_team],
    () => {
      return request({
        url: '/v1/account/current-user/team',
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export default useGetMyTeam;
