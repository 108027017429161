import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import {
  getSearchQueryParamsForAPI,
  serializeQueryArray,
} from 'src/utils/url.helpers';

interface GetProviderListItems
  extends MedScout.Pagination,
    MedScout.Sortable,
    MedScout.Searchable {
  listId: string | number;
  enabledSearchIds?: string[];
  mode?: 'code_volume' | 'sending' | 'receiving';
  enabled?: boolean;
  dateRange?: {
    lte: string;
    gte: string;
  };
  statusFilters?: string[];
  include_unpushed?: number;
}

/**
 * @description Returns a list of all items in the given provider list
 * Standard pagination parameters
 * Search and sort parameters are not supported on this API but they can be added if needed
 * @param listId
 */
const useGetProviderListItems = ({
  listId,
  page,
  pageSize,
  sort,
  order,
  sort_search,
  sort_value,
  q,
  enabledSearchIds = [],
  mode = 'code_volume',
  enabled = true,
  dateRange,
  statusFilters = [],
  include_unpushed = 0,
}: GetProviderListItems) => {
  let queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    sort_search,
    sort_value,
    page: page + 1,
    pageSize,
    mode,
    q,
    dateRange,
    include_unpushed,
  });

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  const statusFilterParams = statusFilters
    ?.sort((a, b) => a.localeCompare(b))
    ?.map(
      (status) =>
        `&${encodeURIComponent('crm_status')}=${encodeURIComponent(status)}`
    )
    ?.join('');

  queryParams += statusFilterParams;

  const query = useQuery(
    [QUERY_KEYS.providerListItems, listId, queryParams],
    async () => {
      return await request({
        url: `/v1/provider-lists/lists/${listId}/items/${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: !!listId && enabled,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    ...query,
    getExport: async (file_format: 'csv' | 'xlsx', columns: string[]) => {
      const columnSelection = `?${serializeQueryArray('columns', columns)}`;
      const searchIdParams = enabledSearchIds
        .map(
          (id) =>
            `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
        )
        .join('');
      let queryParams = getSearchQueryParamsForAPI(
        {
          sort,
          order,
          page: page + 1,
          pageSize,
          sort_search,
          mode,
          q,
          dateRange,
          file_format,
          include_unpushed,
        },
        columnSelection
      );

      queryParams += searchIdParams;

      const statusFilterParams = statusFilters
        ?.sort((a, b) => a.localeCompare(b))
        ?.map(
          (status) =>
            `&${encodeURIComponent('crm_status')}=${encodeURIComponent(status)}`
        )
        ?.join('');

      queryParams += statusFilterParams;

      return await request({
        url: `/v1/provider-lists/lists/${listId}/items/export/${queryParams}`,
        method: 'GET',
        responseType: 'blob',
        rawResponse: true,
      }).then((response) => {
        // Extract filename from Content-Disposition header
        const filename = response?.headers['content-disposition']
          ?.split('filename=')[1]
          ?.split(';')[0]
          ?.replace(/["']/g, '');

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create an anchor element and set attributes for downloading
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename || 'export.csv'); // Use filename from Content-Disposition

        // Append to the document and trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        return filename;
      });
    },
  };
};

export default useGetProviderListItems;
