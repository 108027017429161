import React, { useEffect } from 'react';
import { Box } from '@mui/joy';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { GeoJsonLayer } from '@deck.gl/layers';
import type { GeoJSON } from 'geojson';

import { useMedScoutMap } from 'src/context';
import { MedMapDeckOverlay } from './components';
import { useGetGeoJsonData } from './hooks';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';

const MedMapContent = () => {
  const map = useMap();
  const drawing = useMapsLibrary('drawing');

  const {
    currentTerritory,
    isEditing,
    isCreating,
    drawingMode,
    territoryPolygons,
    setTerritoryPolygons,
    clickedFeatures,
    hoveredFeature,
    setHoveredFeature,
    setClickedFeatures,
    currentOverlay,
    setCurrentOverlay,
  } = useMedScoutMap();

  // Get the geojson data
  const { geoJsonData, existingGeoJsonData } = useGetGeoJsonData();

  useEffect(() => {
    if (drawingMode === 'DRAW') {
      handleDrawnTerritory();
    } else {
      currentOverlay?.setMap(null);
    }

    return () => {
      if (drawingMode === 'DRAW') {
        const maps = google?.maps;
        maps.event.clearInstanceListeners(drawing);
        // clear the map
        currentOverlay?.setMap(null);
      }
    };
  }, [drawingMode]);

  // Handles hovering over a feature
  const handleHover = (info) => {
    setHoveredFeature(info.object ? info.object.properties.id : null);
  };

  // Adds or removes the clicked feature from the list
  const handleClick = (info) => {
    // does info.object.properties.id exist in clickedFeatures, if yes remove it, if no add it
    const newClickedFeatures = clickedFeatures.includes(
      info.object.properties.id
    )
      ? clickedFeatures.filter((id) => id !== info.object.properties.id)
      : [...clickedFeatures, info.object.properties.id];

    // set the new clicked features
    setClickedFeatures(newClickedFeatures);

    // add to territory polygons if the id is not in the list
    const newTerritoryPolygons = territoryPolygons[drawingMode] || [];
    if (newTerritoryPolygons.includes(info.object.properties?.id)) {
      const index = newTerritoryPolygons.indexOf(info.object.properties?.id);
      newTerritoryPolygons.splice(index, 1);
    } else {
      newTerritoryPolygons.push(info.object.properties?.id);
    }

    // set the new territory polygons
    setTerritoryPolygons({
      [drawingMode]: newTerritoryPolygons,
    });
  };

  // The actual layer that gets rendered on the map
  const getDeckGlLayers = (data: GeoJSON | null) => {
    if (!data) return null;
    return [
      new GeoJsonLayer({
        id: 'geojson-layer',
        data,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        pointType: 'circle',
        lineWidthScale: 20,
        lineWidthMinPixels: 4,
        getFillColor: (d) => {
          const isHovered = hoveredFeature === d.properties.id;
          const isClicked = clickedFeatures.includes(d.properties.id);
          if (isClicked) return [77, 182, 172, 150];
          if (isHovered) return [0, 0, 0, 75];
          return [0, 0, 0, 45]; // Default color
        },
        getLineColor: [0, 0, 0, 100],
        getPointRadius: 200,
        getLineWidth: 1,
        getElevation: 30,
        pickable: isEditing || isCreating,
        onHover: handleHover,
        onClick: handleClick,
      }),
    ];
  };

  function hexToRgba(hex, alpha = 255): [number, number, number, number] {
    // Remove the # if it exists
    hex = hex.replace('#', '');

    // Check if the hex code is valid
    if (!/^([0-9A-Fa-f]{3}){1,2}$/.test(hex)) {
      throw new Error('Invalid hex color code');
    }

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }

    // Parse hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the rgba string
    return [r, g, b, alpha];
  }

  const getExistingTerritories = (data: GeoJSON | null) => {
    if (!data) return null;

    return [
      new GeoJsonLayer({
        id: 'existing-territory-layer',
        data,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        pointType: 'circle',
        lineWidthScale: 20,
        getFillColor: (d) => {
          const color = hexToRgba(
            d.properties.fillColor,
            d.properties.fillOpacity
          );
          // Convert the color to RGBA format if needed, for example:
          return color || [0, 0, 0, 100];
        },
        getLineColor: (d) => {
          const color = hexToRgba(
            d.properties.strokeColor,
            d.properties.strokeOpacity
          );
          return color || [0, 0, 0, 100];
        },
        lineWidthMinPixels: 4,
        getLineWidth: (d) => d.properties.strokeWidth || 3,
        getPointRadius: 200,
        getElevation: 30,
        pickable: isEditing || isCreating,
      }),
    ];
  };

  const handleDrawnTerritory = () => {
    if (!drawingMode || drawingMode !== 'DRAW') return;

    const maps = google?.maps;

    if (!maps) return;

    // Function to log updated coordinates
    const logUpdatedCoordinates = () => {
      const updatedCoords = [];
      overlay.getPaths().forEach((path) => {
        path.forEach((latLng) => {
          updatedCoords.push([latLng.lng(), latLng.lat()]);
        });
      });

      // Update the state with the updated polygon coordinates
      setTerritoryPolygons({
        [drawingMode]: updatedCoords,
      });
    };

    // Function to listen for changes in polygon paths
    const listenForPathChanges = (polygon) => {
      polygon.getPaths().forEach((path) => {
        path.addListener('set_at', logUpdatedCoordinates); // When a vertex is moved
        path.addListener('insert_at', logUpdatedCoordinates); // When a vertex is added
        path.addListener('remove_at', logUpdatedCoordinates); // When a vertex is removed
      });
    };

    const isNew = !!currentTerritory?.polygons;
    const paths = isEditing
      ? isNew
        ? currentTerritory.polygons
        : currentTerritory.old_polygons
      : [];

    // Create or update the overlay for the current territory

    const overlay = new google.maps.Polygon({
      paths: paths,
      editable: true,
      draggable: true, // Optional: allows dragging the entire polygon
      strokeColor: COLOR_MAP['blue-dark'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: COLOR_MAP['blue-dark'],
      fillOpacity: 0.25,
    });

    // If editing, set the existing polygon on the map
    if (isEditing) {
      setCurrentOverlay(overlay);
      overlay.setMap(map);
      listenForPathChanges(overlay);
      const updatedCoords = [];
      overlay.getPaths().forEach((path) => {
        path.forEach((latLng) => {
          updatedCoords.push([latLng.lng(), latLng.lat()]);
        });
      });

      // Update the state with the updated polygon coordinates
      setTerritoryPolygons({
        [drawingMode]: updatedCoords,
      });
    } else {
      // Initialize DrawingManager for creating a new polygon
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        drawingControlOptions: {
          position: maps.ControlPosition.TOP_CENTER,
          drawingModes: [maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          strokeColor: COLOR_MAP['blue-dark'],
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: COLOR_MAP['blue-dark'],
          fillOpacity: 0.25,
          editable: true,
          clickable: true,
        },
      });

      // Set DrawingManager on the map
      drawingManager.setMap(map);

      // Disable map dragging during drawing
      map.setOptions({ draggable: false });

      // Listen for the overlaycomplete event to handle polygon creation
      maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
        if (event.type === maps.drawing.OverlayType.POLYGON) {
          const newPoly = event.overlay;
          const tempCoords = [];

          // Capture the polygon's coordinates
          newPoly.getPaths().forEach((path) => {
            path.forEach((latLng) => {
              tempCoords.push([latLng.lng(), latLng.lat()]);
            });
          });

          if (tempCoords.length === 0) {
            newPoly.setMap(null); // Remove the incomplete polygon
            setTerritoryPolygons({ [drawingMode]: [] }); // Clear the polygon coordinates
            return;
          } else {
            // Add the new polygon coordinates to territoryPolygons['DRAW']
            setTerritoryPolygons({ [drawingMode]: tempCoords });

            // Keep the polygon on the map
            setCurrentOverlay(newPoly);
            newPoly.setMap(map);
            listenForPathChanges(newPoly);

            // Allow the polygon to be edited after creation
            newPoly.setEditable(true);
            newPoly.setDraggable(true);

            // Cleanup DrawingManager after creating a polygon
            drawingManager.setMap(null);
          }
        }
        drawingManager.setDrawingMode(null); // Disable drawing mode
      });
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {geoJsonData?.length > 0 && drawingMode !== 'DRAW' && (
        <MedMapDeckOverlay
          layers={
            getDeckGlLayers({
              type: 'FeatureCollection',
              features: geoJsonData,
            }) || []
          }
        />
      )}
      {existingGeoJsonData?.length > 0 && drawingMode !== 'DRAW' && (
        <MedMapDeckOverlay
          layers={
            getExistingTerritories({
              type: 'FeatureCollection',
              features: existingGeoJsonData,
            }) || []
          }
        />
      )}
    </Box>
  );
};

export default MedMapContent;
