import useSWR from 'swr';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from '../search/constants';
import { useMedScoutMap, useProspectSearch } from 'src/context';
import { getMapResults } from './searchApi';

const useNewGetSearchResults = () => {
  const { resultsListParams } = useMedScoutMap();

  const { prospectSearch } = useProspectSearch();

  const params = getSearchQueryParamsForAPI(resultsListParams);

  const hasProspectSearch = prospectSearch && prospectSearch.id;

  // only run if the prospect search is not empty
  return useSWR(
    hasProspectSearch ? [QUERY_KEYS.SEARCH_RESULTS, params] : null,
    async () => getMapResults(params)
  );
};

export default useNewGetSearchResults;
