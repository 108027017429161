import { Typography } from '@mui/joy';
import { handleNumericValue } from 'src/utils';
import { useProspectSearch } from 'src/context';
import { useTable } from 'src/context';

const TrendVolumeCell = ({ row, field }) => {
  // remove the option_ prefix from the field
  field = field.replace('option_', '');
  const { currentSearchIds } = useTable();

  const { prospectVolumeType } = useProspectSearch();

  if (currentSearchIds.length > 1) return;
  const currentSearchId = currentSearchIds[0];

  // Get the correct trends based on available data
  const correctTrends =
    row?.trends?.[prospectVolumeType]?.[currentSearchId]?.[Number(field)] ||
    row?.volume_trends?.[prospectVolumeType]?.[currentSearchId]?.[
      Number(field)
    ];

  return (
    <Typography level="title-sm">
      {handleNumericValue(correctTrends) || 0}
    </Typography>
  );
};

export default TrendVolumeCell;
