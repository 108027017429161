import React from 'react';
import { Box, IconButton, Typography } from '@mui/joy';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { grey } from '@mui/material/colors';

interface DashboardColumnHeaderProps {
  column: string;
  headerName: string;
  onColSort: (col: string, order: 'asc' | 'desc') => void;
  sort: { sort: string; order: 'asc' | 'desc' };
  flex?: number;
  width?: string;
  isSortable?: boolean;
}

const DashboardColumnHeader = ({ ...props }: DashboardColumnHeaderProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flex={props.flex}
      width={props.width}
      sx={{
        paddingLeft: '-16px',
        height: '100%',
        color: grey[500],
        '&:hover': {
          color: grey[700],
        },
      }}
      onClick={() =>
        props.isSortable &&
        props.onColSort(
          props.column,
          props.sort.order === 'asc' ? 'desc' : 'asc'
        )
      }
    >
      <Typography level="title-sm">{props.headerName}</Typography>
      {props.isSortable && (
        <IconButton
          size="md"
          variant="plain"
          color="primary"
          sx={{
            marginRight: '0.5rem',
            color: grey[300],
            transition: 'all 0.2s ease',
            '&:hover': {
              color: grey[900],
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => {
            if (props.sort.sort === props.column) {
              props.onColSort(
                props.column,
                props.sort.order === 'asc' ? 'desc' : 'asc'
              );
            } else {
              props.onColSort(props.column, 'asc');
            }
          }}
        >
          {props.sort.sort === props.column && props.sort.order === 'asc' ? (
            <ExpandMoreRoundedIcon />
          ) : (
            <ExpandLessRoundedIcon />
          )}
        </IconButton>
      )}
    </Box>
  );
};

export default DashboardColumnHeader;
