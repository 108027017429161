import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import {
  useAuth,
  useLogging,
  useMedScoutMap,
  useNotification,
} from 'src/context';
import {
  useGetAllMedTerritories,
  createTerritory,
  editTerritory,
  deleteTerritory,
} from 'src/hooks';
import DrawActionButtons from '../DrawActionButtons';
import { DeleteRounded } from '@mui/icons-material';
import {
  ControlledJoyInput,
  ControlledJoyCheckbox,
} from 'src/components/ControlledFields';
import { DISCOVERY_BLADE_NAMES } from 'src/modules/Discovery/constants';

const AddEditTerritory = ({
  showRepList,
  showTerritory,
  toggleDrawer,
  onToggleDrawer,
}) => {
  const { user, setUser } = useAuth();
  const log = useLogging();
  const { setNotification } = useNotification();

  const {
    currentOverlay,
    currentTerritory,
    drawingMode,
    isCreating,
    isEditing,
    setClickedFeatures,
    setCurrentTerritory,
    setDrawingMode,
    setIsCreating,
    setIsEditing,
    setTerritoryPolygons,
    territoryPolygons,
    adhocTerritory,
    setAdhocTerritory,
  } = useMedScoutMap();

  const values = {
    ...currentTerritory,
    user_id: currentTerritory?.owner?.id || user?.id,
    is_cononical: currentTerritory?.is_canonical || false,
  };

  const methods = useForm({
    values: isEditing
      ? values
      : {
          user: user?.id,
          is_canonical: false,
        },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  const { data: myTerritoryData, mutate: mutateAll } =
    useGetAllMedTerritories();

  const repValuesAndLabels = useMemo(() => {
    if (!myTerritoryData) return [];

    // get uniuqe reps from owner value is owner id and label is owner first name and last name
    // without lodash
    return myTerritoryData
      .map((territory) => {
        return {
          value: territory.owner.id,
          label: `${territory.owner.first_name} ${territory.owner.last_name}`,
        };
      })
      .filter(
        (rep, index, self) =>
          index === self.findIndex((t) => t.value === rep.value)
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [myTerritoryData]);

  useEffect(() => {
    if (!isEditing && !isCreating) {
      setAdhocTerritory(null);
      return;
    }

    const isValidTerritory = territoryPolygons[drawingMode].length > 0;

    if (isValidTerritory) {
      const data = isEditing
        ? {
            name: currentTerritory.name,
            user: currentTerritory.owner.id,
            is_canonical: currentTerritory.is_canonical,
          }
        : {
            name: 'Adhoc Territory',
            user: 'test_user_id',
            is_canonical: false,
          };

      if (drawingMode === 'DRAW') {
        const head = territoryPolygons[drawingMode][0];
        const tail =
          territoryPolygons[drawingMode][
            territoryPolygons[drawingMode].length - 1
          ];

        if (head[0] !== tail[0] || head[1] !== tail[1]) {
          territoryPolygons[drawingMode].push(head);
        }

        const formatedPoints = [
          territoryPolygons[drawingMode].map((point) => {
            return { lat: Number(point[1]), lng: Number(point[0]) };
          }),
        ];

        data['points'] = formatedPoints;

        if (!data['points']) return;
        setAdhocTerritory({
          ...data,
        });
      } else {
        data['boundary_ids'] = territoryPolygons[drawingMode];

        if (!data['boundary_ids']) return;

        setAdhocTerritory(data);
      }
    } else {
      setAdhocTerritory(null);
    }

    return () => {
      setAdhocTerritory(null);
    };
  }, [isEditing, isCreating, drawingMode, territoryPolygons]);

  const handleDelete = async () => {
    try {
      await deleteTerritory(currentTerritory.id);
      log.event('territoryDeleteSubmitted');
    } catch (err) {
      log.exception('Error deleting territory', {
        tags: {
          source: 'AddEditTerritory',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message: 'Error deleting territory',
        type: 'error',
      });
    } finally {
      setCurrentTerritory(null);
      handleCancel();
      setUser({
        ...user,
        last_territory: null,
      });
      mutateAll();
    }
  };

  const handleCancel = () => {
    setIsCreating(false);
    setIsEditing(false);
    setDrawingMode('STATE');
    setTerritoryPolygons({
      STATE: [],
      COUNTY: [],
      ZIPCODE: [],
      DRAW: [],
    });
    setAdhocTerritory(null);
    onToggleDrawer(DISCOVERY_BLADE_NAMES.RESULTS);
    reset();
  };

  const handleClear = () => {
    setTerritoryPolygons({
      STATE: [],
      COUNTY: [],
      ZIPCODE: [],
      DRAW: [],
    });
    setClickedFeatures([]);
    setAdhocTerritory(null);
    if (drawingMode === 'DRAW') {
      // clear the map
      currentOverlay?.setMap(null);
    }
  };

  const onSubmit = async (data: MedScout.Territory) => {
    if (!data) return;
    let territory = null;

    // drawing or other
    if (drawingMode === 'DRAW') {
      const head = territoryPolygons[drawingMode][0];
      const tail =
        territoryPolygons[drawingMode][
          territoryPolygons[drawingMode].length - 1
        ];

      if (head[0] !== tail[0] || head[1] !== tail[1]) {
        territoryPolygons[drawingMode].push(head);
      }

      const formatedPoints = [
        territoryPolygons[drawingMode].map((point) => {
          return { lat: Number(point[1]), lng: Number(point[0]) };
        }),
      ];

      data['points'] = formatedPoints;
    } else {
      data['geometry'] = territoryPolygons[drawingMode];
    }

    if (data['geometry'] === undefined && data['points'] === undefined) {
      setNotification({
        title: 'Error',
        message: 'There are no points or geometry to create the territory',
        type: 'error',
      });
      return;
    }

    try {
      if (isEditing) {
        territory = await editTerritory(currentTerritory.id, data);
      } else {
        territory = await createTerritory(data);
      }
      setCurrentTerritory(territory);
      setUser({
        ...user,
        last_territory: territory,
      });

      log.event(`Territory ${isEditing ? 'Updated' : 'Created'}`, {
        source: 'AddEditTerritory',
        territory,
      });

      setNotification({
        title: 'Success',
        message: `Territory ${isEditing ? 'updated' : 'created'} successfully`,
        type: 'success',
      });
    } catch (err) {
      log.exception(`Error ${isEditing ? 'updating' : 'creating'} Territory`, {
        tags: {
          source: 'AddEditTerritory',
          error: err,
        },
      });

      setNotification({
        title: 'Error',
        message: `Error ${isEditing ? 'updating' : 'creating'} Territory`,
        type: 'error',
      });
    } finally {
      handleCancel();
      mutateAll();
    }
  };

  const isManager = user?.permissions?.is_manager || user?.is_superuser;
  const isRepsList = toggleDrawer?.drawer !== DISCOVERY_BLADE_NAMES.REP_LIST;
  const isTerritory = toggleDrawer?.drawer !== DISCOVERY_BLADE_NAMES.TERRITORY;

  return (
    <FormProvider {...methods}>
      <Card>
        <CardContent>
          <Typography level="title-lg">
            {isEditing ? 'Edit' : 'Add'} Territory
          </Typography>
          <Typography level="body-sm">
            Click on the map to draw your territory.
          </Typography>
          <DrawActionButtons />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="body-xs" sx={{ fontWeight: 600 }}>
              Territory Name
            </Typography>
            <Button
              size="sm"
              variant="plain"
              onClick={() =>
                onToggleDrawer(DISCOVERY_BLADE_NAMES.REP_LIST, isRepsList)
              }
              sx={{ fontSize: '0.75rem' }}
            >
              {showRepList ? 'Hide' : 'Show'} Existing Territories
            </Button>
          </Box>
          <ControlledJoyInput name="name" placeholder="Territory Name" />
          <Typography level="body-xs" sx={{ fontWeight: 600 }}>
            Assigned Rep
          </Typography>
          <Controller
            name={isEditing ? 'user_id' : 'user'}
            control={control}
            render={({ field }) => {
              return (
                <Select
                  size="sm"
                  aria-label="Assigned Rep Select"
                  value={field.value}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                >
                  <Option value={user?.id}>
                    Assigned to Me ({user?.first_name} {user?.last_name})
                  </Option>
                  {repValuesAndLabels.map((rep) => {
                    if (rep.value === user?.id) return null;

                    return (
                      <Option key={rep.value} value={rep.value}>
                        {rep.label}
                      </Option>
                    );
                  })}
                </Select>
              );
            }}
          />
          {isManager && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem 0',
                gap: '0.5rem',
              }}
            >
              <ControlledJoyCheckbox size="sm" name="is_canonical" />
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Assigned Territory (Manager Editable Only)
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="sm"
              variant="plain"
              onClick={() =>
                onToggleDrawer(DISCOVERY_BLADE_NAMES.TERRITORY, isTerritory)
              }
              sx={{ fontSize: '0.75rem' }}
              disabled={!adhocTerritory}
            >
              {showTerritory ? 'Hide' : 'Show'} Territory Analytics
            </Button>
          </Box>
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Button
              size="sm"
              variant="outlined"
              color="danger"
              onClick={isEditing ? handleDelete : handleClear}
            >
              {isEditing ? <DeleteRounded fontSize="small" /> : 'Clear'}
            </Button>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="solid"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={!isEditing && (!isValid || !isDirty)}
              >
                {isEditing ? 'Update' : 'Create'} Territory
              </Button>
            </Box>
          </CardActions>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default AddEditTerritory;
