import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEY } from '../constants';

const useGetFieldDefinitions = ({ objectId }) => {
  return useQuery(
    [QUERY_KEY.MED_OBJECTS_LIST, objectId],
    () => {
      return request({
        url: `v1/medrm/object-definitions/${objectId}/fields/`,
        method: 'GET',
      });
    },
    {
      enabled: !!objectId,
    }
  );
};

export default useGetFieldDefinitions;
