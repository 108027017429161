import { useMutation } from 'react-query';
import { request } from 'src/api/request';

function useDeserializeTerritories() {
  return useMutation((data: File) => {
    if (!data) return Promise.reject(new Error('No file provided'));

    const formData = new FormData();
    formData.append('territory', data);

    return request({
      url: '/v1/accounts/territories/import/',
      method: 'POST',
      data: formData,
    });
  });
}

export default useDeserializeTerritories;
