import React from 'react';
import { Box, Select, Option } from '@mui/joy';
import { Pagination } from '@mui/material';

interface TablePaginationProps {
  count: number;
  sortValues: {
    page: number;
    pageSize: number;
  };
  setSortValues: React.Dispatch<
    React.SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
}

/**
 * @description     TablePagination component is used to display the pagination in the admin dashboard
 * @param param0    count, sortValues, setSortValues
 * @returns         TablePagination component
 */
const TablePagination = ({
  count,
  sortValues,
  setSortValues,
}: TablePaginationProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0.5rem',
        gap: '0.5rem',
        background: 'white',
      }}
    >
      <Select
        variant="plain"
        color="neutral"
        size="sm"
        sx={{
          background: 'transparent',
        }}
        value={sortValues.pageSize}
        onChange={(e, newValue) =>
          setSortValues({
            ...sortValues,
            pageSize: newValue,
          })
        }
      >
        <Option value={50}>50</Option>
        <Option value={75}>75</Option>
        <Option value={100}>100</Option>
      </Select>
      <Pagination
        size="small"
        count={Math.ceil(count / sortValues.pageSize)}
        page={sortValues.page}
        onChange={(e, page) =>
          setSortValues({
            ...sortValues,
            page,
          })
        }
      />
    </Box>
  );
};

export default TablePagination;
