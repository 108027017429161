import React from 'react';
import { hexToRgb } from 'src/components/ColorPicker/color.functions';
import {
  CircleRounded as CircleRoundedIcon,
  ArrowDropDownRounded as ArrowDropDownRoundedIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material';
import { MenuButton, Typography } from '@mui/joy';

interface StatusButtonProps {
  variant: 'compact' | 'regular';
  status: { label: string; color: string };
  loading: boolean;
  onClick: (e: any) => void;
}

interface RootProps {
  backgroundColor: string;
  backgroundHoverColor: string;
  btnColor: string;
}

const StyledMenuButton = styled(MenuButton, {
  shouldForwardProp: (prop) =>
    prop !== 'btnColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'backgroundHoverColor',
})<RootProps>(({ theme, backgroundColor, backgroundHoverColor, btnColor }) => ({
  background: backgroundColor,
  color: btnColor,
  borderRadius: '0.375rem',
  borderEndEndRadius: '1.5rem',
  '&:hover': {
    background: backgroundHoverColor,
  },
  whiteSpace: 'nowrap',
  width: '8.75rem',
  minWidth: '8.75rem',
  maxWidth: '8.75rem',
  height: '2rem',
  minHeight: '2rem',
  maxHeight: '2rem',
}));

/**
 * @description StatusButton component
 * @param variant compact or regular
 * @param status status object
 * @param loading loading state
 * @param onClick click handler
 * @returns React.Node
 */

const StatusButton = ({
  variant,
  status,
  loading,
  onClick,
}: StatusButtonProps) => {
  const noStatusColor = '#a7a7a7';
  const backgroundColor = status?.color
    ? hexToRgb(status?.color, 0.1)
    : noStatusColor;
  const backgroundHoverColor = status?.color
    ? hexToRgb(status?.color, 0.2)
    : noStatusColor;

  const isRegular = variant === 'regular';

  return (
    <StyledMenuButton
      variant="soft"
      size="sm"
      onClick={onClick}
      loading={loading}
      startDecorator={
        <CircleRoundedIcon
          sx={{
            fontSize: '0.875rem',
            color: 'inherit',
          }}
        />
      }
      endDecorator={
        <ArrowDropDownRoundedIcon
          sx={{
            fontSize: '1.125rem',
            color: 'inherit',
          }}
        />
      }
      backgroundColor={backgroundColor}
      backgroundHoverColor={backgroundHoverColor}
      btnColor={status?.color || noStatusColor}
    >
      {isRegular && (
        <Typography
          level="body-sm"
          sx={{
            color: status?.color ? 'inherit' : noStatusColor,
            fontWeight: 600,
            lineHeight: '1.25rem',
            width: '100%',
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {status.label}
        </Typography>
      )}
    </StyledMenuButton>
  );
};

export default StatusButton;
