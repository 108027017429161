import React from 'react';
import { Box, Typography, TypographySystem } from '@mui/joy';
import { grey } from '@mui/material/colors';

interface TablePrimaryHeaderProps {
  subTitle?: string;
  title: string;
  level?: keyof TypographySystem;
}

const TablePrimaryHeader = ({
  subTitle = '',
  title,
  level = 'h4',
}: TablePrimaryHeaderProps) => {
  function upperFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box>
        <Typography
          level={level}
          sx={{
            color: '#171A1C',
          }}
        >
          {upperFirst(title)}
        </Typography>
        {subTitle && (
          <Typography
            level="title-sm"
            sx={{
              color: '#555E68',
            }}
          >
            {subTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TablePrimaryHeader;
