import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TableColumn } from 'src/components';
import { useProspectSearch, useTable } from 'src/context';

interface UseTableColumnsProps {
  variant?: string;
  baseColumns: any[];
  volumeColumns: any[];
  currentYears?: any[];
  columnVisibilityModel: any;
  setColumnVisibilityModel?: any;
  hasScroll?: boolean;
  hasVolume?: boolean;
}

/**
 * @description Custom hook to get the columns for the table
 * @param param0
 * @returns columns and pinned columns
 */
function useTableColumns({
  variant = '',
  baseColumns = [],
  volumeColumns = [],
  currentYears = [],
  columnVisibilityModel = {},
  setColumnVisibilityModel,
  hasScroll = true,
  hasVolume = true,
}: UseTableColumnsProps) {
  const { prospectSearch } = useProspectSearch();
  const { secondaryHeaderWidth, setSecondaryHeaderWidth } = useTable();
  const SCROLLBAR_OFFSET = hasScroll ? 16 : 0;
  const TRENDING_COLUMN_WIDTH = 65;

  const columns: GridColDef[] = useMemo(() => {
    const dynamicColumns = [
      ...baseColumns,
      variant === 'smart' &&
        TableColumn.volumeShifterValue('TODOTODO', 'Volume Trend'),
      hasVolume && {
        ...TableColumn.searchVolume(
          `volume`,
          prospectSearch?.search_name || prospectSearch?.name || 'Saved Search'
        ),
        width: 225,
        disableColumnMenu: true,
      },
      ...volumeColumns,
    ].filter((n) => n);

    return dynamicColumns;
  }, [baseColumns, variant, hasVolume, prospectSearch, volumeColumns]);

  const pinnedColumns = useMemo(() => {
    if (!volumeColumns.length) return { left: [], right: [] };
    const visibleVolumeColumns = columns.filter(
      (col) =>
        (col.field === 'volume' ||
          volumeColumns.map((v) => v?.field).includes(col?.field)) &&
        columnVisibilityModel[col?.field]
    );

    if (visibleVolumeColumns.length > 0) {
      const columnHeaders = visibleVolumeColumns.map((col) => col.field);

      if (columnHeaders.length > 0) {
        return {
          left: [],
          right: columnHeaders,
        };
      }
    } else {
      return {
        left: [],
        right: [],
      };
    }
  }, [columns, volumeColumns, columnVisibilityModel]);

  const newColumns = useMemo(() => {
    const visibleVolumeColumns = columns.filter(
      (col) =>
        (col.field === 'volume' ||
          volumeColumns.map((v) => v?.field).includes(col?.field)) &&
        columnVisibilityModel[col?.field]
    );

    let yearColumnsWidth = 0;
    const nonYearVolumeColumns = [];

    for (const col of visibleVolumeColumns) {
      if (currentYears.includes(Number(col.field))) {
        col.width = TRENDING_COLUMN_WIDTH;
        yearColumnsWidth += TRENDING_COLUMN_WIDTH;
      } else {
        nonYearVolumeColumns.push(col);
      }
    }

    const remainingWidth =
      secondaryHeaderWidth - yearColumnsWidth - SCROLLBAR_OFFSET;

    if (nonYearVolumeColumns.length > 0) {
      const newWidth = Math.floor(remainingWidth / nonYearVolumeColumns.length);

      return columns.map((col) =>
        nonYearVolumeColumns.includes(col) ? { ...col, width: newWidth } : col
      );
    }

    return columns;
  }, [
    columns,

    SCROLLBAR_OFFSET,
    volumeColumns,
    columnVisibilityModel,
    currentYears,
  ]);

  return { columns: newColumns, pinned: pinnedColumns };
}

export default useTableColumns;
