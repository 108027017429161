import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

interface ResolveDataIssue {
  dataIssueId: string;
  newData: object;
  status: string;
}

const useResolveDataIssue = () => {
  return useMutation(
    [QUERY_KEYS.resolveDataIssue],
    async (data: ResolveDataIssue) => {
      return await request({
        url: `/v1/account/data-issues/${data.dataIssueId}/`,
        method: 'PATCH',
        data: {
          data_issue_id: data.dataIssueId,
          new_data: data.newData,
          status: data.status,
        },
      });
    }
  );
};

export default useResolveDataIssue;
