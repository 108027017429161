import React from 'react';
import { Box } from '@mui/joy';
import { TablePagination } from '@mui/material';
import { grey } from '@mui/material/colors';

interface TableFooterProps {
  page: number;
  pageSize: number;
  rowCount: number;
  rowsPerPageOptions?: number[];
  onPageChange: (e, newPage: number) => void;
  onPageSizeChange: (e) => void;
}

const TableFooter = ({
  page,
  pageSize,
  rowCount,
  rowsPerPageOptions = [50, 75, 100],
  onPageChange,
  onPageSizeChange,
}: TableFooterProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0.5rem',
        borderTop: `1px solid ${grey[300]}`,
        width: '100%',
        height: '3.438rem',
        background: '#fff',
      }}
    >
      <TablePagination
        component="div"
        count={rowCount}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={onPageSizeChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </Box>
  );
};

export default TableFooter;
