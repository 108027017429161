import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Textarea, FormHelperText, TextareaProps } from '@mui/joy';

interface ControlledJoyTextareaProps extends TextareaProps {
  rules?: any;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  helperText?: string;
  showLabel?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
}

const ControlledJoyTextarea = ({ ...props }: ControlledJoyTextareaProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!props) return null;

  const errorMessage = props?.helperText || 'This field is required';
  const required = props.required || props.rules?.required;

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: required ? errorMessage : false,
        ...props.rules,
      }}
      render={({ field }) => {
        return (
          <>
            <Textarea
              {...field}
              {...props}
              value={field.value || ''}
              onChange={field.onChange}
              error={!!errors[props.name]}
              minRows={props.min || 1}
              maxRows={props.max || 4}
              sx={{
                width: '100%',
              }}
            />
            <FormHelperText sx={{ ml: 0 }}>
              {!!errors[props.name] && (errors[props.name]?.message as string)}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default ControlledJoyTextarea;
