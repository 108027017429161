import {
  Box,
  useTheme,
  styled,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { BrandIcon } from 'src/components';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

// Regular button styles
const StyledCRMButton = styled(LoadingButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTadius: 0,
  borderBottomLeftRadius: '0.95rem',
  whiteSpace: 'nowrap',
  lineHeight: '1.25rem',
  marginRight: '-1px',
  gap: '0.25rem',
}));

const StyledCRMWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '1rem',
  backgroundColor: 'white',
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  paddingLeft: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',
}));

const StyledText = styled('span')(({ theme }) => ({
  fontSize: '0.65rem',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  lineHeight: '1.25rem',
  marginRight: '-1px',
  width: '100%',
  paddingInline: '0.15rem',
}));

// Small button styles
const SmallButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'loading' && prop !== 'hasLinks',
})<{ loading: boolean; hasLinks: boolean }>(({ theme, loading, hasLinks }) => ({
  px: '0.5rem',
  border: `1px solid ${loading ? grey[300] : theme.palette.primary.main}`,
  borderTadius: 0,
  borderBottomLeftRadius: '0.95rem',
  borderTopRightRadius: '0.95rem',
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
}));

const PushToCrm: React.FC<{
  hasLinks?: boolean;
  integration?: string;
  sx?: SystemStyleObject;
  isLoading?: boolean;
  label: string;
  variant?: 'compact' | 'regular';
  onSubmit: (e) => void;
}> = ({
  hasLinks = false,
  integration = 'salesforce',
  sx = {},
  isLoading = false,
  label = 'Push to CRM',
  variant = 'regular',
  onSubmit,
}) => {
  const theme = useTheme();

  switch (variant) {
    case 'compact':
      return (
        <Tooltip title={label} disableHoverListener={!hasLinks}>
          <span>
            <SmallButton
              variant={hasLinks ? 'outlined' : 'contained'}
              size="small"
              loading={isLoading}
              onClick={onSubmit}
              hasLinks={hasLinks}
              sx={{
                px: '0.5rem',
              }}
            >
              {isLoading ? (
                <CircularProgress size={15} />
              ) : (
                <>
                  <BrandIcon
                    brand={integration?.toLowerCase() || 'salesforce'}
                    width="1.1rem"
                    height="1rem"
                    invert={!hasLinks}
                  />
                  {hasLinks && <Icon icon={faChevronDown} size="sm" />}
                </>
              )}
            </SmallButton>
          </span>
        </Tooltip>
      );
      break;
    case 'regular':
      return (
        <StyledCRMWrapper
          onClick={onSubmit}
          sx={{
            border: `1px solid ${
              isLoading ? grey[300] : theme.palette.primary.main
            }`,
            height: '1.75rem',
            ...sx,
          }}
        >
          <BrandIcon
            brand={integration?.toLowerCase() || 'salesforce'}
            width="25px"
            height="20px"
          />
          <Tooltip title={label} disableHoverListener={!hasLinks}>
            <span>
              <StyledCRMButton
                variant={hasLinks ? 'outlined' : 'contained'}
                size="small"
                sx={{
                  px: '0.5rem',
                }}
                loading={isLoading}
              >
                <StyledText>{label}</StyledText>
                {hasLinks && <Icon icon={faChevronDown} size="sm" />}
              </StyledCRMButton>
            </span>
          </Tooltip>
        </StyledCRMWrapper>
      );
    default:
      break;
  }
};

export default PushToCrm;
