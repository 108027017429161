import { Box, Textarea } from '@mui/joy';
import { SelectorLabel } from './DataIssueStyles';

interface DataIssueCommentsProps {
  comments: string;
  setComments?: (value: string) => void;
}

const DataIssueComments = ({
  comments,
  setComments,
}: DataIssueCommentsProps) => {
  return (
    <Box sx={{ mt: 2 }}>
      <SelectorLabel>Comments:</SelectorLabel>
      <Textarea
        disabled={!setComments}
        placeholder="Provide any additional information here"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        sx={{ width: '100%', minHeight: '100px', maxHeight: '100px' }}
      />
    </Box>
  );
};

export { DataIssueComments };
