import useSWR from 'swr';
import { request } from 'src/api/request';

const fetcher = async (url, allTerritories = []) => {
  const response = await request({
    url,
    data: {},
    method: 'GET',
  });

  allTerritories.push(...response.results);

  if (response.next) {
    // we only want the path after the api/ part
    const url = response.next.split('api/')[1];
    // If there's a next page, recursively fetch territories from it
    await fetcher(url, allTerritories);
  }

  return allTerritories;
};

function useGetAllTerritories() {
  return useSWR('v1/accounts/territories/?page=1&pageSize=1000', fetcher, {
    revalidateOnFocus: false,
  });
}

export default useGetAllTerritories;
