import React, { useState } from 'react';
import { useLogging, useNotification, useDashboard } from 'src/context';
import { useGetUser, useCompanyUsers, deleteUser, updateUser } from 'src/hooks';
import {
  Modal,
  ModalClose,
  ModalDialog,
  Button,
  Box,
  Typography,
} from '@mui/joy';

interface DeactivateDialogProps {
  open: boolean;
  onClose: () => void;
}

const DeactivateDialog = ({ open, onClose }: DeactivateDialogProps) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { showConfirmDeleteModal } = useDashboard();
  const { mutate: mutateAll } = useCompanyUsers(
    showConfirmDeleteModal?.company
  );
  const { mutate } = useGetUser(
    showConfirmDeleteModal?.company,
    showConfirmDeleteModal?.user?.id?.toString()
  );

  const isActive = showConfirmDeleteModal?.user?.is_active;

  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivate = async () => {
    setIsLoading(true);
    try {
      await updateUser(
        showConfirmDeleteModal?.company,
        showConfirmDeleteModal?.user?.id?.toString(),
        {
          is_active: !isActive,
        }
      );
      mutate();
      mutateAll();

      log.event(`User Activation`, {
        source: 'DeactivateDialog',
        user: showConfirmDeleteModal?.user,
        active: !isActive,
      });

      setNotification({
        title: 'Success',
        message: `User ${isActive ? 'deactivated' : 'activated'} successfully`,
        type: 'success',
      });
    } catch (err) {
      log.exception(`User Activation Error`, {
        tags: {
          source: 'DeactivateDialog',
          error: err,
          active: !isActive,
        },
      });
      setNotification({
        title: 'Error',
        message: `Error ${isActive ? 'deactivating' : 'activating'} user`,
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose onClick={onClose} />
        <Typography level="title-md">
          {isActive ? 'Deactivate' : 'Activate'} User
        </Typography>
        <Box sx={{ padding: '0.5rem 0' }}>
          Are you sure you want to {isActive ? 'deactivate' : 'activate'}{' '}
          {`${showConfirmDeleteModal?.user?.first_name} ${showConfirmDeleteModal?.user?.last_name}`}
          ?
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color={isActive ? 'danger' : 'success'}
            onClick={handleDeactivate}
            loading={isLoading}
          >
            {isActive ? 'Deactivate' : 'Activate'}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default DeactivateDialog;
