import React from 'react';
import { Box } from '@mui/joy';
import { grey } from '@mui/material/colors';
import { hexToRgb } from './color.functions';

interface ColorItemProps {
  color: string;
  onClick: () => void;
  selected: boolean;
}

const ColorItem = ({ color, onClick, selected }: ColorItemProps) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '0.25rem',
        backgroundColor: color,
        cursor: 'pointer',
        position: 'relative',
        transform: selected ? 'scale(1.1)' : 'none',
        transition: 'transform 0.2s, box-shadow 0.2s',
        boxShadow: selected ? `0 0 0.3rem ${hexToRgb(grey[800], 0.5)}` : 'none',
        '&::after': selected
          ? {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: hexToRgb(grey[800], 0.5),
              opacity: 0.7,
              borderRadius: '0.25rem',
              border: `1px solid ${hexToRgb(grey[800], 0.5)}`,
            }
          : {},
      }}
    />
  );
};

export default ColorItem;
