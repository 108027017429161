import React, { useState } from 'react';
import { Blade } from 'src/components';
import { Box, styled } from '@mui/material';
import RepsTerritoriesAndLists from 'src/pages/manager/components/RepsTerritoriesAndLists';

interface RepsListBladeProps {
  open: boolean;
  onClose: () => void;
  width?: number;
}

const RepsListContainer = styled(Box)(({ theme }) => ({
  overFlowY: 'scroll',
  backgroundColor: 'white',
  height: '100%',
  width: `100%`,
}));

const RepsListBlade = ({ open, onClose, width }: RepsListBladeProps) => {
  const [formattedProspectLists, setFormattedProspectLists] = useState([]);

  return (
    <Blade
      open={open}
      onClose={onClose}
      title="Existing Territories"
      width={width}
      minWidth={width}
      maxWidth={width}
      variant={open ? 'persistent' : 'temporary'}
      hideBackdrop={true}
    >
      <RepsListContainer width={width}>
        <RepsTerritoriesAndLists
          viewSource="searchPageMap"
          setFormattedProspectLists={setFormattedProspectLists}
        />
      </RepsListContainer>
    </Blade>
  );
};

export default RepsListBlade;
