import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEY } from '../constants';

interface FieldDefinition {
  id: string;
  label: string;
  description: string;
  input_type: string;
  nullable: boolean;
  optons: MedScout.Status[];
}

const useUpdateFieldDefinitionDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      data,
      objectId,
      id,
    }: {
      data: FieldDefinition[];
      objectId: string;
      id: string;
    }) => {
      return request({
        url: `v1/medrm/object-definitions/${objectId}/fields/${id}/`,
        method: 'PUT',
        data: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.MED_GET_FIELD);
      },
    }
  );
};

export default useUpdateFieldDefinitionDetails;
