import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

interface StartOneTimeSyncParams {
  mappingId: number | string;
  direction: string;
}

const useStartOneTimeSync = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [INTEGRATION_KEYS.START_ONE_TIME_SYNC],
    async ({ mappingId, direction }: StartOneTimeSyncParams) => {
      return await request({
        url: `/v1/integrations/mappings/${mappingId}/one-time-sync-trackers/`,
        method: 'POST',
        data: {
          mapping: mappingId,
          direction,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INTEGRATION_KEYS.GET_SYNC_PULL_HISTORY]);
        queryClient.invalidateQueries([INTEGRATION_KEYS.GET_SYNC_PUSH_HISTORY]);
      },
    }
  );
};

export default useStartOneTimeSync;
