import React from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { colorPalette } from 'src/components/ColorPicker/constants';
import { Box, Typography } from '@mui/joy';
import { hexToRgb } from 'src/components/ColorPicker/color.functions';

const GridStatusFilterMenuHeader = ({ bucket }) => {
  const bucketMap = {
    no_status: {
      label: 'No Status',
      color: colorPalette?.grey?.main,
      icon: null,
      borderStyle: 'none',
    },
    todo: {
      label: 'To Do',
      color: colorPalette?.grey?.main,
      icon: null,
      borderStyle: 'dotted',
    },
    in_progress: {
      label: 'In Progress',
      color: colorPalette?.blue?.main,
      icon: (
        <PlayArrowRoundedIcon
          fontSize="inherit"
          sx={{ color: colorPalette?.blue?.main }}
        />
      ),
      borderStyle: 'solid',
    },
    done: {
      label: 'Done',
      color: colorPalette?.green?.main,
      icon: (
        <DoneRoundedIcon
          fontSize="inherit"
          sx={{ color: colorPalette?.green?.main }}
        />
      ),
      borderStyle: 'solid',
    },
  };

  const isNoStatus = bucket === 'no_status';
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
      }}
    >
      <Box
        sx={{
          width: '1.1rem',
          height: '1.1rem',
          padding: '0.25rem',
          borderRadius: '50%',
          border: `2px ${bucketMap[bucket]?.borderStyle} ${bucketMap[bucket]?.color}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isNoStatus
            ? hexToRgb(bucketMap[bucket]?.color, 0.4)
            : 'transparent',
        }}
      >
        {bucketMap[bucket]?.icon}
      </Box>
      <Typography level="title-sm" sx={{ color: bucketMap[bucket]?.color }}>
        {bucketMap[bucket]?.label}
      </Typography>
    </Box>
  );
};

export default GridStatusFilterMenuHeader;
