import useSwr from 'swr';
import { getUser } from './usersApi';
import { QUERY_KEY } from '../constants';

const useGetUser = (companyId: string, userId: string) => {
  return useSwr([QUERY_KEY.USER, companyId, userId], () =>
    getUser(companyId, userId)
  );
};

export default useGetUser;
