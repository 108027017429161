import { Typography, Box, styled } from '@mui/material';
import { useGetCrmObjects } from 'src/hooks';
import { useFormContext } from 'react-hook-form';
import { sentenceCase } from 'src/utils';

const Row = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  width: '100%',
}));

interface ControlledRowProps {
  name: string;
  label: string;
  options?: any[];
  crmType?: string;
  integration?: string;
  writableOnly?: boolean;
}

const ControlledRow = ({ ...props }: ControlledRowProps) => {
  const { watch } = useFormContext();
  const isExtraOptions = props?.name === 'extra.options';

  const optionNameMap = {
    auto_populate: 'Autopopulate',
    user_input_lookup: 'Lookup',
    user_input_custom: 'User Input',
  };

  const { data: crmObjects } = useGetCrmObjects({
    crmType: props.crmType,
    crm: props.integration,
    writable_only: props.writableOnly,
  });

  const value = watch(props.name);

  if (!value) return null;

  const rowOptionsMap = {
    crm_api_code: crmObjects,
    'extra.search_label': crmObjects,
    'extra.value_field': crmObjects,
    attribute_code: props.options,
    'extra.crm_object_type': props.options,
    input_type: props.options,
  };

  const isInOptions = rowOptionsMap[props.name]?.find(
    (option) => option.value === value || option.code === value
  );

  const isDateRange = props.name === 'secondary_inputs.year_range';
  const isSavedSearch = props.name === 'secondary_inputs.saved_search';

  const displayValue =
    isDateRange && value?.gte && value?.lte
      ? `${value?.gte} - ${value?.lte}`
      : isSavedSearch
      ? props.options?.find((option) => option.value === value)?.label
      : isInOptions?.label || optionNameMap[value] || sentenceCase(value);

  return (
    <Row>
      <Typography variant="h5" sx={{ fontWeight: 500, fontSize: '0.9rem' }}>
        {props.label}
      </Typography>
      {isExtraOptions ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '0.25rem',
          }}
        >
          {value?.map((option, index) => {
            const isLast = index === value.length - 1;
            return (
              <span key={index}>
                {[option?.value, option?.label]?.filter(Boolean).join(': ')}
                {!isLast && ','}
              </span>
            );
          })}
        </Box>
      ) : (
        <Typography>{displayValue}</Typography>
      )}
    </Row>
  );
};

export default ControlledRow;
