import { Box } from '@mui/joy';

const EmailCell = ({ row, field }) => {
  if (!row) return null;

  const email = row[field] || row.email;

  if (!email) return null;

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        {email}
      </a>
    </Box>
  );
};

export default EmailCell;
