import { useEffect, useState, useRef } from 'react';

/**
 * @description Detects if the table has a scrollbar
 * @param apiRef reference to the grid api
 * @param grid actual grid from classname
 * @returns Boolean
 */
function useTableScrollDetection(apiRef, grid) {
  const [hasScroll, setHasScroll] = useState(false);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    if (!grid || !apiRef?.current || !apiRef.current.rootElementRef?.current)
      return;

    const rootElement = apiRef.current.rootElementRef.current;
    const viewport = rootElement.querySelector('.MuiDataGrid-virtualScroller');
    const viewportContent = rootElement.querySelector(
      '.MuiDataGrid-virtualScrollerContent'
    );

    if (!viewport || !viewportContent) return;

    let previousScrollState = false;

    const handleScrollChange = () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        // debounceTimeout.current = null;
        const isVerticalScrollbarPresent =
          viewport.scrollHeight > viewport.clientHeight;
        const areScrollbarsHidden =
          areScrollbarsHiddenInDataGrid(viewportContent);

        const isScrollPresent =
          isVerticalScrollbarPresent && !areScrollbarsHidden;

        if (previousScrollState !== isScrollPresent) {
          setHasScroll(isScrollPresent);
          previousScrollState = isScrollPresent;
        }
      }, 100);
    };

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(handleScrollChange);
    });
    resizeObserver.observe(viewport);
    resizeObserver.observe(viewportContent);

    function areScrollbarsHiddenInDataGrid(element) {
      const originalOverflow = element.style.overflow;

      element.style.overflow = 'scroll';
      const withScrollbars = element.clientWidth;

      element.style.overflow = 'hidden';
      const withoutScrollbars = element.clientWidth;

      element.style.overflow = originalOverflow;

      return withoutScrollbars === withScrollbars;
    }

    handleScrollChange();
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      resizeObserver.disconnect();
    };
  }, [apiRef, grid]);

  return hasScroll;
}

export default useTableScrollDetection;
