import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

export const getSearch = async (searchTerm: string) => {
  if (searchTerm.length <= 2) return [];
  try {
    const queryParams = getSearchQueryParamsForAPI({ q: searchTerm });
    const response = await request({
      url: `/v1/search/providers/${queryParams}`,
      data: {},
      method: 'GET',
    });
    return response.providers;
  } catch (error) {
    return [];
  }
};
