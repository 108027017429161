import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEY } from 'src/hooks/medrm/constants';

const useDeleteFieldDefinition = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, objectId }: { id: string; objectId: string }) => {
      return request({
        url: `v1/medrm/object-definitions/${objectId}/fields/${id}/`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries(QUERY_KEY.MED_GET_FIELD);
      },
    }
  );
};

export default useDeleteFieldDefinition;
