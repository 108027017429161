export const QUERY_KEY = {
  MED_OBJECTS_LIST: 'med-objects-list',
  MED_OBJECTS_DETAIL: 'med-objects-detail',
  MED_UPDATE_OBJECT: 'med-update-object',
  MED_GET_FIELD: 'med-get-field',
  MED_GET_FIELD_DETAILS: 'med-get-field-details',
  MED_ADD_FIELD: 'med-add-field',
  MED_UPDATE_FIELD: 'med-update-field',
  MED_DELETE_FIELD: 'med-delete-field',
};
