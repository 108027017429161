import React, { useEffect, useState } from 'react';
import { Box, Slider, Typography } from '@mui/joy';
import { useDebounce } from 'use-debounce';

interface SmoothnessControlProps {
  onSubmit: (smooth: number[]) => void;
}

const SmoothnessControl = ({ onSubmit }: SmoothnessControlProps) => {
  const [smoothness, setSmoothness] = useState<number[]>([1]);
  const [debouncedSmoothness] = useDebounce(smoothness, 500);

  useEffect(() => {
    if (debouncedSmoothness !== smoothness) return;
    // submit form with debounce value
    onSubmit(debouncedSmoothness);
  }, [debouncedSmoothness]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setSmoothness(newValue as number[]);
  };

  return (
    <Box sx={{ marginBottom: '-1.75rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography level="body-sm">Smoothness</Typography>
        <Typography level="body-sm">{smoothness}%</Typography>
      </Box>
      <Slider
        variant="solid"
        color="primary"
        onChange={(e, newValue) => {
          handleChange(e, newValue);
        }}
        defaultValue={1}
        min={1}
        max={100}
        step={1}
        valueLabelDisplay="auto"
        size="sm"
      />
    </Box>
  );
};

export default SmoothnessControl;
