import { useMemo } from 'react';
import { useProspectSearch } from 'src/context';
import { useGetProfileDetails } from 'src/api/profiles/profiles';
import { useGetSavedSearches } from 'src/hooks';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { Typography } from '@mui/joy';
import { handleNumericValue } from 'src/utils';
import { useRouter } from 'next/router';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';

const HtmlToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f5f5f9',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '220px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[6],
  },
}));

const SearchVolumeCell = ({ row, searchId }) => {
  const router = useRouter();
  const { id, type } = router.query as {
    id: string;
    type: string;
  };
  const {
    prospectMode,
    prospectSearch,
    prospectVolumeType,
    prospectRelationship,
  } = useProspectSearch();
  const { data: profileDetails } = useGetProfileDetails({
    id: id,
    contentType: type,
  });

  const { data: savedSearches = [] } = useGetSavedSearches();

  const isSending = prospectMode === 'sending';
  const isCodeVolume = prospectMode === 'code_volume';
  const isReceiving = prospectMode === 'receiving';
  const isInbound = !prospectRelationship || prospectRelationship === 'inbound'; // Default to inbound
  const isOutbound = prospectRelationship === 'outbound';

  // Current search could be represented by just "currentSearch" or a saved search "id"
  // If it's "currentSearch" then we use the prospectSearch context to get the current search
  const currentSearch = useMemo(() => {
    if (!searchId) return null;

    return searchId === 'volume'
      ? prospectSearch
      : savedSearches.find((search) => search.id?.toString() === searchId);
  }, [searchId, prospectSearch, savedSearches]);

  function toolTipTitle() {
    let message = ``;
    const profileName = profileDetails?.details?.name;
    const searchName = currentSearch?.search_name || currentSearch?.name;
    const patients = handleNumericValue(row.patients?.[currentSearch?.id]);
    const claims = handleNumericValue(row.claims?.[currentSearch?.id]);
    const isClaims = prospectVolumeType === 'claims';

    if (isSending && isInbound) {
      message += profileName
        ? `${row?.name}  has referred ${patients} patients into ${profileName} for ${searchName}`
        : `${row?.name} has referred ${patients} patients for ${searchName}`;
    } else if (isReceiving && isOutbound) {
      message += `${profileName} has referred ${patients} patients out to ${row?.name} for ${searchName}`;
    } else if ((isInbound || isOutbound) && isCodeVolume && isClaims) {
      message += `${row?.name} has a total claims volume of ${claims} for ${searchName}`;
    } else if ((isInbound || isOutbound) && isCodeVolume && !isClaims) {
      message += `${row?.name} has a total patients volume of ${patients} for ${searchName}`;
    }

    return (
      <Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>
        {message}
      </Typography>
    );
  }

  if (!row[prospectVolumeType]?.[currentSearch?.id]) {
    return (
      <Typography level="body-lg">
        <QueryBuilderRoundedIcon fontSize="small" />
      </Typography>
    );
  }

  return (
    <HtmlToolTip title={toolTipTitle()}>
      <Typography level="body-lg">
        {handleNumericValue(row[prospectVolumeType]?.[currentSearch?.id])}
      </Typography>
    </HtmlToolTip>
  );
};

export default SearchVolumeCell;
