'use client';
import React, { useState, useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Dropdown, IconButton, Menu } from '@mui/joy';
import ColorizeIcon from '@mui/icons-material/Colorize';
import ColorItem from './ColorItem';
import { colorPalette } from './constants';
import { useOnClickOutside } from 'src/hooks';
import { hexToRgb } from './color.functions';

interface ColorPickerProps {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  name: string;
}

const ColorPicker = ({ name, setSelectedColor }: ColorPickerProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { control } = useFormContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  const colorArray = Object.values(colorPalette).flatMap((color) =>
    Object.values(color)
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Dropdown>
              <IconButton
                size="md"
                variant="soft"
                onClick={handleClick}
                sx={{
                  backgroundColor: hexToRgb(field.value, 0.1),
                }}
              >
                <ColorizeIcon
                  sx={{
                    color: field.value || 'purple',
                  }}
                />
              </IconButton>
              <Menu
                ref={menuRef}
                placement="bottom-end"
                size="sm"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(12, 1fr)',
                    gap: '0.5rem',
                    padding: '0.5rem',
                  }}
                >
                  {colorArray.map((color) => (
                    <ColorItem
                      key={color}
                      color={color}
                      onClick={() => {
                        setSelectedColor(color);
                        field.onChange(color);
                      }}
                      selected={field.value === color}
                    />
                  ))}
                </Box>
              </Menu>
            </Dropdown>
          );
        }}
      />
    </Box>
  );
};

export default ColorPicker;
