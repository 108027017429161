import useSWR from 'swr';
import { QUERY_KEYS } from './constants';
import { getSearch } from './searchApi';

const useGetSearch = (q: string) => {
  return useSWR([QUERY_KEYS.GET_SEARCH, q], () => getSearch(q), {
    keepPreviousData: true,
  });
};

export default useGetSearch;
