import { useMemo } from 'react';
import { useTable } from 'src/context';

/**
 * @description Get the current table and years from the context
 * @param tableName name of the table to get from the context
 * @returns currentTable and currentYears
 */
const useCurrentTable = (tableName) => {
  const { dateRange } = useTable();

  const currentTable = dateRange?.every_table
    ? dateRange?.every_table
    : dateRange[tableName];

  const currentYears = useMemo(() => {
    if (!currentTable || !currentTable?.gte || !currentTable?.lte) return [];

    const years = [];
    const { gte, lte } = currentTable;
    for (let year = Number(gte); year <= Number(lte); year++) {
      years.push(year);
    }

    return years;
  }, [currentTable]);

  return { currentTable, currentYears };
};

export default useCurrentTable;
