import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Table, Box, Typography } from '@mui/joy';
import { CircleRounded, CheckRounded } from '@mui/icons-material';
import {
  DashboardToolbar,
  DashboardColumnHeader,
  TablePagination,
  TableRow,
  DashboardLoading,
  ActionButtons,
} from 'src/components';
import { useDashboard, useAuth } from 'src/context';
import { useCompany, useCompanyUsers } from 'src/hooks';
import { grey } from '@mui/material/colors';

const UserTable = () => {
  const { user } = useAuth();
  const router = useRouter();
  const { id } = router.query as { id: string };

  const { userQueryParams, setUserQueryParams } = useDashboard();
  const { data: company } = useCompany(id);
  const { data: users, isLoading } = useCompanyUsers(id);

  const [isHovered, setIsHovered] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<{ sort: string; order: 'asc' | 'desc' }>({
    sort: 'first_name',
    order: 'asc',
  });

  useEffect(() => {
    return () => {
      setUserQueryParams({
        q: '',
        order: 'asc',
        page: 1,
        pageSize: 50,
        sort: 'first_name',
        active_only: 1,
      });
    };
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    setUserQueryParams({ ...userQueryParams, q: value });
  };

  const handleColSort = (col: string, order: 'asc' | 'desc') => {
    setSort({ sort: col, order });
    setUserQueryParams({ ...userQueryParams, sort: col, order });
  };

  const handleRowClick = (user: MedScout.User) => {
    router.push(`/admin/companies/${id}/users/${user.id}`);
  };

  const isMedScoutUser = user?.is_superuser;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <DashboardToolbar
        search={search}
        onSearch={handleSearch}
        showUserControls
        isMedScoutUser={isMedScoutUser}
        supportUser={company?.support_user_id?.toString()}
      />
      <Box
        sx={{
          border: `1px solid ${grey[300]}`,
          borderRadius: '0.33rem',
          height: `calc(100vh - 310px)`,
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Table
          noWrap
          stickyHeader
          stickyFooter
          sx={{
            height: '100%',
            tableLayout: 'auto',
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: 'left', width: '1rem' }}></th>
              <th style={{ textAlign: 'left', width: '5%' }}>
                <DashboardColumnHeader
                  column="is_active"
                  headerName="Active"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left', width: '10%' }}>
                <DashboardColumnHeader
                  column="first_name"
                  headerName="First Name"
                  sort={sort}
                  onColSort={handleColSort}
                  isSortable
                />
              </th>
              <th style={{ textAlign: 'left', width: '10%' }}>
                <DashboardColumnHeader
                  column="last_name"
                  headerName="Last Name"
                  sort={sort}
                  onColSort={handleColSort}
                  isSortable
                />
              </th>
              <th style={{ textAlign: 'left', flex: 1 }}>
                <DashboardColumnHeader
                  column="username"
                  headerName="Username"
                  sort={sort}
                  onColSort={handleColSort}
                  isSortable
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="manager"
                  headerName="Manager"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="is_manager"
                  headerName="Is Manager"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="is_lir_eligible"
                  headerName="LIR Eligible"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="is_integration_admin"
                  headerName="Integration Admin"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left' }}>
                <DashboardColumnHeader
                  column="last_login"
                  headerName="Last Login"
                  sort={sort}
                  onColSort={handleColSort}
                />
              </th>
              <th style={{ textAlign: 'left', width: '10%' }}></th>
            </tr>
          </thead>
          <tbody>
            {users?.results?.map((user) => {
              let userManager = null;
              if (user.managers.length > 0) {
                userManager = `${user.managers[0].first_name} ${user.managers[0].last_name}`;
              }
              return (
                <TableRow
                  key={user.id}
                  onClick={() => handleRowClick(user)}
                  onMouseOver={() => setIsHovered(user.id)}
                  onMouseOut={() => setIsHovered(null)}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  <td style={{ textAlign: 'left' }}></td>
                  <td style={{ textAlign: 'left' }}>
                    <CircleRounded
                      sx={{
                        width: '0.9rem',
                        height: '0.9rem',
                      }}
                      color={user.is_active ? 'success' : 'error'}
                    />
                  </td>
                  <td style={{ textAlign: 'left' }}>{user.first_name}</td>
                  <td style={{ textAlign: 'left' }}>{user.last_name}</td>
                  <td style={{ textAlign: 'left' }}>{user.username}</td>
                  <td style={{ textAlign: 'left' }}>
                    {userManager && (
                      <Typography level="body-sm">{userManager}</Typography>
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user?.permissions?.is_manager && (
                      <CheckRounded fontSize="small" />
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user.is_lir_eligible && <CheckRounded fontSize="small" />}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user.permissions?.is_integration_admin && (
                      <CheckRounded fontSize="small" />
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {user.last_login && (
                      <Typography level="body-sm">
                        {new Date(user.last_login).toLocaleString()}
                      </Typography>
                    )}
                  </td>
                  <td
                    style={{ textAlign: 'right', width: 0 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ActionButtons
                      showImpersonate
                      selectedUser={user}
                      isHovered={isHovered === user.id}
                    />
                  </td>
                </TableRow>
              );
            })}
            <tr></tr>
          </tbody>
          <tfoot
            style={{
              background: 'white',
            }}
          >
            <tr
              style={{
                background: 'white',
              }}
            >
              <td
                colSpan={12}
                style={{
                  background: 'white',
                }}
              >
                <TablePagination
                  count={users?.count || 0}
                  sortValues={userQueryParams}
                  setSortValues={setUserQueryParams}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
        {isLoading && <DashboardLoading />}
      </Box>
    </Box>
  );
};

export default UserTable;
