import React from 'react';
import LineChart from './LineChart';
import { Grid } from '@mui/joy';

interface TerritoryChartColumnProps {
  chartData: any;
  searchValues: any;
  width?: string;
}

const TerritoryChartColumn = ({
  chartData,
  searchValues,
  width,
}: TerritoryChartColumnProps) => {
  return (
    <Grid width={width} data-testid="line-chart">
      {chartData.map((data, index) => {
        const max = Math.max(
          ...data.results.flatMap((r) => r.data.map((d) => Number(d.y)))
        );

        return (
          <LineChart
            key={index}
            data={data}
            searchValues={searchValues}
            yScale={{
              type: 'linear',
              min: 0,
              max: max + 1000,
            }}
          />
        );
      })}
    </Grid>
  );
};

export default TerritoryChartColumn;
