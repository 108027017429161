import { Box, CircularProgress } from '@mui/joy';
import React from 'react';
/**
 * @description DashboardLoading component is used to display the loading spinner in the admin dashboard
 * @returns DashboardLoading component
 */
const DashboardLoading = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress color="neutral" size="md" />
    </Box>
  );
};

export default DashboardLoading;
