import { MutableRefObject, useEffect } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

const useSyncTableScroll = (
  ref1: MutableRefObject<GridApiPremium>,
  ref2: MutableRefObject<GridApiPremium>
) => {
  useEffect(() => {
    if (!ref1.current || !ref2.current) return;

    const grid1 = ref1.current?.rootElementRef?.current?.querySelector(
      '.MuiDataGrid-virtualScroller'
    );
    const grid2 = ref2.current?.rootElementRef?.current?.querySelector(
      '.MuiDataGrid-virtualScroller'
    );

    const syncScroll = (sourceGrid, targetGrid) => {
      if (!sourceGrid || !targetGrid) return;
      targetGrid.scrollTop = sourceGrid.scrollTop;
    };

    const handleScroll1 = () => syncScroll(grid1, grid2);
    const handleScroll2 = () => syncScroll(grid2, grid1);

    grid1?.addEventListener('scroll', handleScroll1);
    grid2?.addEventListener('scroll', handleScroll2);

    return () => {
      grid1?.removeEventListener('scroll', handleScroll1);
      grid2?.removeEventListener('scroll', handleScroll2);
    };
  }, [ref1?.current, ref2?.current]);
};

export default useSyncTableScroll;
