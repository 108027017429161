import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';


interface GetDataIssues
  extends MedScout.Pagination,
    MedScout.Sortable,
    MedScout.Searchable {}

const useGetDataIssues = ({
  q,
  sort,
  order,
  page,
  pageSize,
}: GetDataIssues) => {
  const queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    page: page + 1,
    pageSize,
    q,
  });

  return useQuery(
    [QUERY_KEYS.dataIssues, queryParams],
    () => {
      return request({
        url: `/v1/account/data-issues/${queryParams}`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
};

export default useGetDataIssues;
