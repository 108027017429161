import { Typography, Tooltip } from '@mui/joy';
import React from 'react';

const HeaderVolume = ({ field, ...props }) => {
  return (
    <Tooltip
      size="sm"
      color="neutral"
      title={props?.colDef?.headerName}
      placement="top"
      arrow
    >
      <Typography
        level="title-md"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: '0.5rem',
        }}
      >
        {props?.colDef?.headerName}
      </Typography>
    </Tooltip>
  );
};

export default HeaderVolume;
