import useSWR from 'swr';
import { request } from 'src/api/request';
import { QUERY_KEY } from '../constants';
import { useDashboard } from 'src/context';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

/**
 * @description Recursive hook to get all users since we don't return them from the API
 */

const fetcher = async (url: string, items: any[] = []) => {
  const response = await request({
    url,
    method: 'GET',
  });

  const newItems = [...items, ...response.results];

  if (response.next) {
    // Split the URL to remove the base URL
    const next = response.next.split('api/')[1];
    return fetcher(next, newItems);
  }

  return newItems;
};

const useGetAllUsers = (companyId: string) => {
  const { userQueryParams } = useDashboard();
  const searchParams = getSearchQueryParamsForAPI(userQueryParams);
  return useSWR(
    QUERY_KEY.ALL_USERS,
    () => fetcher(`v1/accounts/companies/${companyId}/users/${searchParams}`),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
      dedupingInterval: 30000,
    }
  );
};

export default useGetAllUsers;
