import useSWR from 'swr';
import { getTerritory } from './territoryApi';
import { QUERY_KEY } from './constants';

const useGetMedTerritory = (id: string) => {
  const shouldFetch =
    Boolean(id) && id !== 'addNew' && id !== 'USE_MAP_NO_TERRITORY';
  return useSWR(
    () => (shouldFetch ? [QUERY_KEY.GET_TERRITORY, id] : null),
    () => getTerritory(id)
  );
};

export default useGetMedTerritory;
