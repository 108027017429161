import React from 'react';
import { DeleteRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/joy';
import { useDashboard } from 'src/context';
import { useRouter } from 'next/router';

interface DeactivateButtonProps {
  selectedUser: MedScout.User;
  variant?: 'regular' | 'compact' | 'icon';
}

const DeactivateButton = ({
  selectedUser,
  variant = 'regular',
}: DeactivateButtonProps) => {
  const router = useRouter();
  const { id } = router.query as { id: string };
  const { setShowConfirmDeleteModal } = useDashboard();

  const handleOpenDialog = () => {
    setShowConfirmDeleteModal({ show: true, company: id, user: selectedUser });
  };

  const isActive = selectedUser?.is_active;
  switch (variant) {
    case 'regular': {
      return (
        <Button
          size="sm"
          variant="outlined"
          color={isActive ? 'danger' : 'success'}
          onClick={handleOpenDialog}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </Button>
      );
    }

    case 'compact': {
      return (
        <IconButton
          size="sm"
          variant="soft"
          color={isActive ? 'danger' : 'success'}
          onClick={handleOpenDialog}
        >
          <DeleteRounded sx={{ fontSize: '1.1rem' }} />
        </IconButton>
      );
    }
    case 'icon': {
      return (
        <IconButton
          onClick={handleOpenDialog}
          sx={{
            width: '1.75rem',
            height: '1.75rem',
            minWidth: '1.75rem',
            minHeight: '1.75rem',
            maxWidth: '1.75rem',
            maxHeight: '1.75rem',
            backgroundColor: 'transparent',
            color: '#636B74',
            '&:hover': {
              color: 'var(--joy-palette-danger-500, #C41C1C)',
              backgroundColor: 'transparent',
            },
          }}
        >
          <DeleteRounded sx={{ fontSize: '1.1rem' }} />
        </IconButton>
      );
    }

    default:
      return null;
      break;
  }
};

export default DeactivateButton;
