import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useTestPushToCRM = () => {
  return useMutation(
    [INTEGRATION_KEYS.TEST_PUSH_TO_CRM],
    (input: {
      data: Omit<MedScout.CRMObject, 'object_id' | 'object_type'>;
      isDryRun: boolean;
    }) => {
      const dryRun = input.isDryRun ? '?dry_run=TRUE' : '';

      return request({
        url: `/v1/integrations/test-push-to-crm/${dryRun}`,
        method: 'POST',
        data: {
          ...input.data,
        },
      });
    }
  );
};

export default useTestPushToCRM;
