import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Input, FormHelperText, InputProps } from '@mui/joy';

interface ControlledJoyInputProps extends InputProps {
  rules?: any;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  helperText?: string;
  showLabel?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
}

const ControlledJoyInput = ({ ...props }: ControlledJoyInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!props) return null;

  const errorMessage = props?.helperText || 'This field is required';
  const required = props.required || props.rules?.required;

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: required ? errorMessage : false,
        ...props.rules,
      }}
      render={({ field }) => {
        return (
          <>
            <Input
              {...field}
              {...props}
              value={field.value || ''}
              onChange={field.onChange}
              error={!!errors[props.name]}
              slotProps={{
                input: {
                  min: props.min || 0,
                  max: props.max || 1000,
                  step: 1,
                },
              }}
            />
            <FormHelperText sx={{ ml: 0 }}>
              {!!errors[props.name] && (errors[props.name]?.message as string)}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default ControlledJoyInput;
