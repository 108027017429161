import { request } from 'src/api/request';

export const getManagers = async (companyId: string) => {
  if (!companyId) return;

  return await request({
    url: `v1/accounts/companies/${companyId}/managers/`,
    method: 'GET',
  });
};

export const getManagersReports = async (
  companyId: string,
  managerId: string
) => {
  if (!companyId || !managerId) return;

  return await request({
    url: `v1/accounts/companies/${companyId}/managers/${managerId}`,
    method: 'GET',
  });
};

export const updateManagersReports = async (
  companyId: string,
  managerId: string,
  data: any
) => {
  if (!companyId || !managerId || !data) return;
  // {
  //     "direct_reports": ['user_id', 'user_id',...]
  // }
  return await request({
    url: `v1/accounts/companies/${companyId}/managers/${managerId}`,
    method: 'PUT',
    data,
  });
};

export const deleteManagerReports = async (
  companyId: string,
  managerId: string
) => {
  if (!companyId || !managerId) return;

  return await request({
    url: `v1/accounts/companies/${companyId}/managers/${managerId}`,
    method: 'DELETE',
  });
};
