import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/joy';
import TerritoryChartColumn from './TerritoryChartColumn';
import { useMediaQuery } from '@mui/material';

interface TerritoryChartBodyProps {
  chartData: any;
  searchValues: any;
}

const TerritoryChartBody = ({
  chartData,
  searchValues,
}: TerritoryChartBodyProps) => {
  const [columnCount, setColumnCount] = useState(2);
  const [chartWidth, setChartWidth] = useState('50%');
  const xsScreen = useMediaQuery('(max-width:600px)');
  const smScreen = useMediaQuery('(min-width:600px)');
  const mdScreen = useMediaQuery('(min-width:1000px)');

  useEffect(() => {
    setColumnCount(smScreen && !mdScreen ? 1 : 2);
    setChartWidth(xsScreen || (smScreen && !mdScreen) ? '100%' : '50%');
  }, [smScreen, mdScreen, xsScreen]);

  const charts = chartData.filter(
    (result) => result.card_name !== 'Total Volume'
  );
  const claimsCharts = charts.filter((chart) =>
    chart.card_name?.includes('Claims')
  );
  const patientCharts = charts.filter((chart) =>
    chart.card_name?.includes('Patient')
  );
  const providerCharts = charts.filter((chart) =>
    chart.card_name?.includes('Provider')
  );

  return (
    <>
      <Grid container columns={columnCount}>
        <TerritoryChartColumn
          chartData={claimsCharts}
          searchValues={searchValues}
          width={chartWidth}
        />
        <TerritoryChartColumn
          chartData={patientCharts}
          searchValues={searchValues}
          width={chartWidth}
        />
      </Grid>
      <TerritoryChartColumn
        chartData={providerCharts}
        searchValues={searchValues}
      />
    </>
  );
};

export default TerritoryChartBody;
