import { useQuery } from 'react-query';
import { request } from 'src/api/request';

import { QUERY_KEYS } from './constants';

async function fetchTerritories(url, allTerritories = []) {
  const response = await request({
    url,
    data: {},
    method: 'GET',
  });

  allTerritories.push(...response.results);

  if (response.next) {
    // we only want the path after the api/ part
    const url = response.next.split('api/')[1];
    // If there's a next page, recursively fetch territories from it
    await fetchTerritories(url, allTerritories);
  }

  return allTerritories;
}

function useGetAllTerritories() {
  return useQuery(
    [QUERY_KEYS.territories],
    () => fetchTerritories('v1/accounts/territories/?page=1&pageSize=1000'),
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
    }
  );
}

export default useGetAllTerritories;
