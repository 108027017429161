import useSwr from 'swr';
import { getUsers } from './usersApi';
import { QUERY_KEY } from '../constants';
import { useDashboard } from 'src/context';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const useCompanyUsers = (companyId: string) => {
  const { userQueryParams } = useDashboard();
  if (userQueryParams.managers) {
    delete userQueryParams.managers;
  }
  const searchParams = getSearchQueryParamsForAPI(userQueryParams);
  return useSwr(
    [QUERY_KEY.USERS, searchParams, companyId],
    () => getUsers(companyId, searchParams),
    {
      keepPreviousData: true,
    }
  );
};

export default useCompanyUsers;
