import { Box, List, ListItem, Typography } from '@mui/joy';
import { format as formatDate } from 'date-fns';
import { hasContent } from 'src/utils';

const NotesCell = ({ row }) => {
  if (!hasContent(row.notes)) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <List size="sm">
        {row.notes.map((note) => (
          <ListItem key={note.id}>
            <Typography
              component={'time'}
              dateTime={note.created_at}
              sx={{
                fontSize: '0.75em',
                fontStyle: 'italic',
                display: 'block',
              }}
            >
              {formatDate(new Date(note.created_at), 'PPp')}:
            </Typography>
            <Typography>&ldquo;{note.note}&rdquo;</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NotesCell;
