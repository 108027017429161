import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Select, MenuItem, FormHelperText } from '@mui/material';
import ControlLabel from './ControlLabel';

interface ControlledSelectProps {
  name: string;
  options: any[];
  label?: string;
  rules?: any;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  value?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  helperText?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
}

const ControlledSelect = ({ ...props }: ControlledSelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!props) return null;
  const errorMessage = props?.helperText || 'This field is required';
  const required = props?.required || props.rules?.required;
  if (!props.options) return null;
  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: props.required ? errorMessage : false,
        ...props.rules,
      }}
      render={({ field }) => {
        return (
          <ControlLabel
            label={props?.label || ''}
            required={required}
            hasTooltip={props.hasTooltip}
            tooltipText={props.tooltipText}
          >
            <Select
              {...field}
              displayEmpty
              error={!!errors[props.name]}
              value={field.value || ''}
              onChange={field.onChange}
              variant="standard"
              fullWidth
              required={required}
              size={props.size || 'medium'}
              disabled={props.disabled}
            >
              <MenuItem value="" disabled>
                <em>{props?.placeholder || 'Select...'}</em>
              </MenuItem>
              {props.options?.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error sx={{ ml: 0 }}>
              {!!errors[props.name] && (errors[props.name]?.message as string)}
            </FormHelperText>
          </ControlLabel>
        );
      }}
    />
  );
};

export default ControlledSelect;
