import { useEffect, useState } from 'react';
import { Box, Button, styled, Menu, Dropdown, Grid } from '@mui/joy';
import { ClickAwayListener } from '@mui/material';
import {
  ReferringTreatingToggle,
  VolumeTypeToggle,
  VolumeDateToggle,
  VolumeEntityTypeToggle,
} from './components';

const VolumeSelectorsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexShrink: 0,
  overflow: 'auto',
}));

interface NewVolumeSelectorsProps {
  showTreatingReferring?: boolean;
  showEntityType?: boolean;
  table?: string | null;
  enabledSearchIds?: number[] | string[];
  isMobile?: boolean;
}

// TODO: Joy UI dropdown does not work on Disocvery page, no ideas.. yet
const NewVolumeSelectors = ({
  showTreatingReferring = false,
  showEntityType = false,
  table = null,
  enabledSearchIds = [],
  isMobile = false,
}: NewVolumeSelectorsProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!anchorEl) return;
    setAnchorEl(null);
  }, [isMobile]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        flex: 1,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      }}
    >
      {isMobile ? (
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Dropdown>
              <Button
                variant="outlined"
                color="neutral"
                onClick={handleClick}
                fullWidth
              >
                Volume Selectors
              </Button>
              <Menu
                placement="bottom-start"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  padding: 2,
                  maxWidth: 400,
                  zIndex: 1000,
                }}
              >
                <Grid container spacing={2}>
                  <Grid xs={6}>
                    {showTreatingReferring && (
                      <ReferringTreatingToggle
                        enabledSearchIds={enabledSearchIds}
                      />
                    )}
                  </Grid>
                  {showEntityType && (
                    <Grid xs={6}>
                      <VolumeEntityTypeToggle />
                    </Grid>
                  )}
                  <Grid xs={6}>
                    <VolumeTypeToggle
                      table={table}
                      enabledSearchIds={enabledSearchIds}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <VolumeDateToggle
                      table={table}
                      enabledSearchIds={enabledSearchIds}
                    />
                  </Grid>
                </Grid>
              </Menu>
            </Dropdown>
          </Box>
        </ClickAwayListener>
      ) : (
        <VolumeSelectorsContainer
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          {showTreatingReferring && (
            <ReferringTreatingToggle enabledSearchIds={enabledSearchIds} />
          )}
          {showEntityType && <VolumeEntityTypeToggle />}
          <VolumeTypeToggle table={table} enabledSearchIds={enabledSearchIds} />
          <VolumeDateToggle table={table} enabledSearchIds={enabledSearchIds} />
        </VolumeSelectorsContainer>
      )}
    </Box>
  );
};

export default NewVolumeSelectors;
