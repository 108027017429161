import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import {
  AnalyticsProvider,
  AuthProvider,
  LayoutControlProvider,
  ProspectProvider,
  NotificationProvider,
  TableProvider,
  CRMProvider,
} from 'src/context';
import { DashboardProvider } from './providers/DashboardProvider';

export const GlobalProvider = ({ children, pageProps }) => {
  const router = useRouter();
  const queryClientState = new QueryClient();
  const [queryClient] = useState(queryClientState);

  function ErrorFallback({ resetErrorBoundary }) {
    resetErrorBoundary();
    return null;
  }

  function errorHandler(error) {
    Sentry.captureMessage('App page error boundary hit');
    Sentry.captureException(error);
  }

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={errorHandler}
              onReset={() => {
                router.reload();
              }}
            >
              <LayoutControlProvider>
                <NotificationProvider>
                  <ProspectProvider>
                    <CRMProvider>
                      <DashboardProvider>
                        <TableProvider>{children}</TableProvider>
                      </DashboardProvider>
                    </CRMProvider>
                  </ProspectProvider>
                </NotificationProvider>
              </LayoutControlProvider>
            </ErrorBoundary>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};
