import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useGetSearchCRM } from 'src/hooks';
import { useDebounce } from 'use-debounce';
import { Controller } from 'react-hook-form';

interface CRMLookupProps {
  crmType: string;
  crm: string;
  option: MedScout.UserInputOption;
  control: any;
  rules?: any;
}

const CRMLookup = ({
  crmType,
  crm,
  option,
  control,
  rules = [],
}: CRMLookupProps) => {
  const [search, setSearch] = useState('');

  const [searchTerm] = useDebounce(search, 500);
  const { data: searchCRM } = useGetSearchCRM({
    crmType,
    q: searchTerm,
    option,
    crm,
  });

  return (
    <Controller
      name={option.crm_api_code}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          loading={!searchCRM}
          value={searchCRM?.find((item) => item.value === field.value) || null}
          onChange={(e, value) => {
            field.onChange(value?.value || '');
          }}
          inputValue={search}
          onInputChange={(e, value) => {
            setSearch(value);
          }}
          id="crm-lookup"
          options={searchCRM || []}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.label}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search..."
              variant="standard"
              aria-label="CRM Lookup"
            />
          )}
        />
      )}
    />
  );
};

export default CRMLookup;
