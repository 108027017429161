import { useState, useRef, useEffect, FC } from 'react';
import {
  useGetProviderLists,
  useAddItemsToProviderList,
  useAddProviderList,
} from 'src/hooks';
import { useLogging, useAuth, useNotification } from 'src/context';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  CircularProgress,
  OutlinedInput,
} from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { SubmitHandler, useForm } from 'react-hook-form';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

/**
 * Add one or more selected opportunities to a list
 *
 * @param {*} {
 *   onHide,
 *   newProviders,
 * }
 * @return {*}
 */

type AddProviderDialogProps = {
  onHide: () => void;
  newProviders: {
    providers: any;
    ui_fromMap: boolean;
    onSuccess: () => void;
  };
};

const AddProviderDialog: FC<AddProviderDialogProps> = ({
  onHide,
  newProviders,
}) => {
  const { setNotification } = useNotification();
  const { data: providerLists, isLoading: providerListsLoading } =
    useGetProviderLists();
  const { mutateAsync: addProvidersToList, isLoading: addProvidersLoading } =
    useAddItemsToProviderList();
  const [, setInlineError] = useState('');
  const [selectedProviderListId, setSelectedProviderListId] =
    useState<string>(null);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const radioGroupRef = useRef<HTMLElement>(null);
  const log = useLogging();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm();
  const { mutateAsync } = useAddProviderList();

  const handleAddProviders = async (newListId?: string) => {
    try {
      // remove providers that are already in the list
      const providers = await handleDuplicateRemoval();

      if (providers.length === 0) {
        setNotification({
          title: 'No providers added',
          message: 'All selected providers are already in the list.',
          type: 'info',
        });
        onHide();
        return;
      }
      await addProvidersToList({
        listId: newListId ?? selectedProviderListId,
        providers,
      });

      if (newProviders?.onSuccess) {
        newProviders.onSuccess();
      }

      log.event('addProvider', {
        source: 'searchView',
        view: newProviders.ui_fromMap ? 'map' : 'table',
        count: providers?.length,
      });

      setNotification({
        title: 'Providers added',
        message: `Added ${providers.length} ${
          providers.length === 1 ? 'provider' : 'providers'
        } to the list.`,
        type: 'success',
      });

      onHide();
    } catch (err) {
      setInlineError(err.message);
      log.event('addProvider', {
        source: 'searchView',
        view: newProviders.ui_fromMap ? 'map' : 'table',
        count: newProviders?.providers?.length,
        error: err.message,
      });

      setNotification({
        title: 'Error',
        message:
          err.message ||
          'An error occurred while adding providers to the list.',
        type: 'error',
      });
    }
  };

  const handleDuplicateRemoval = () => {
    // Filter out providers that are already in the selected provider list
    return newProviders.providers.filter((provider) => {
      // Check if the provider's provider_lists contain the selectedProviderListId
      if (!provider.provider_lists || provider.provider_lists.length === 0)
        return true; // Return true if provider_lists is empty or undefined

      // Return false if the provider has the selectedProviderListId in any of its provider_lists
      return !provider.provider_lists.some(
        (list) => list.id?.toString() === selectedProviderListId
      );
    });
  };

  const handleSelectList = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProviderListId(e.target.value);
    setIsCreatingList(false);
  };

  const handleAddList = async () => {
    setIsCreatingList(true);
    setSelectedProviderListId('new-list-id');
  };

  const onSubmit: SubmitHandler<{ listName: string }> = async (data) => {
    const { listName } = data;
    if (!listName) {
      setError('listName', { message: 'List name is required' });
      log.exception('Error creating new list: name not provided');
      return;
    }

    try {
      const createdList = await mutateAsync(listName);
      setIsCreatingList(false);
      await setSelectedProviderListId(createdList.id);
      log.event('List created', { name: listName });
      reset({ listName: '' });

      await handleAddProviders(createdList.id);
    } catch (err: any) {
      log.exception(`Error creating new list: ${err}`);
    }
  };

  useEffect(() => {
    if (!providerLists?.results?.length) return;

    const mostRecentProviderList = providerLists.results
      ?.filter((list) => list.owner.id === user.id)
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )[0];

    if (!mostRecentProviderList) return;

    setSelectedProviderListId(mostRecentProviderList.id.toString());
  }, [providerLists, user]);

  return (
    <Dialog open>
      <DialogTitle>Add to List</DialogTitle>
      <DialogContent sx={{ maxHeight: '25rem', overflow: 'auto' }}>
        {providerListsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size="4rem" />
          </Box>
        ) : (
          <RadioGroup
            ref={radioGroupRef}
            aria-label="provider list"
            name="providerList"
            value={selectedProviderListId}
            onChange={handleSelectList}
          >
            {providerLists?.results
              ?.filter((list) => list.owner.id === user.id)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((list) => (
                <FormControlLabel
                  key={list.id}
                  value={list.id}
                  control={<Radio />}
                  label={list.name}
                />
              )) || 'No lists available'}
            {isCreatingList ? (
              <FormControlLabel
                key={'new-list-id'}
                value={'new-list-id'}
                control={<Radio />}
                label={
                  <OutlinedInput
                    size="small"
                    placeholder="List Name"
                    {...register('listName', { required: true })}
                    error={!!errors.listName}
                    autoFocus
                  />
                }
              />
            ) : null}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          startIcon={<Icon color={'primary'} icon={faPlus} />}
          variant="text"
          onClick={handleAddList}
        >
          New List
        </Button>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="outlined" onClick={onHide}>
            Cancel
          </Button>
          <LoadingButton
            loading={addProvidersLoading}
            disabled={
              isCreatingList ? !isValid || !isDirty : !selectedProviderListId
            }
            variant="contained"
            onClick={
              isCreatingList
                ? handleSubmit(onSubmit)
                : () => handleAddProviders()
            }
            aria-label="Add To List"
          >
            {isCreatingList ? 'Create List and Add' : 'Add to List'}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddProviderDialog;
