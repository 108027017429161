import { Box, Typography } from '@mui/joy';
import React from 'react';

const GroupsCell = ({ row }) => {
  if (!row.groups) return null;

  function renderGroups(row) {
    return row.groups
      ?.filter((group) => group.name)
      ?.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <div>
      {renderGroups(row)?.map((group, index) => {
        return (
          <Typography
            key={index}
            level="body-sm"
            sx={{ fontSize: '0.65rem', whiteSpace: 'break-spaces' }}
          >
            {group.name}
          </Typography>
        );
      })}
    </div>
  );
};

export default GroupsCell;
