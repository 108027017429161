import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEY } from '../constants';

const useGetObjectDefinitionsList = ({ id }) => {
  return useQuery(
    [QUERY_KEY.MED_OBJECTS_DETAIL, id],
    () => {
      return request({
        url: `v1/medrm/object-definitions/${id}`,
        method: 'GET',
      });
    },
    {
      enabled: !!id,
    }
  );
};

export default useGetObjectDefinitionsList;
