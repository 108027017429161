import { Paper, Box, Grid } from '@mui/material';
import { useProspectSearch, useLogging } from 'src/context';
import { SearchBox } from '..';

const allCategories = {
  spec: true,
  system: true,
  name: true,
  cpt: true,
  icd: true,
  drug: true,
  hcpcs: true,
  drg: true,
  apc: true,
  icdp: true,
  payments: true,
  tags: true,
  combo_volume: true,
  payer: true,
  site: true,
  year: true,
};
type SearchCategory = {
  context: string;
  allowedWith?: typeof allCategories;
  requiredContext?: string[];
  combinedCategories?: string[];
};

const code_context = ['cpt', 'icd', 'icdp', 'hcpcs', 'dme', 'drg', 'apc'];

const categoryFields: SearchCategory[] = [
  {
    context: 'combo_volume',
    allowedWith: allCategories,
    combinedCategories: code_context,
  },
  { context: 'drug', allowedWith: allCategories },
  { context: 'spec', allowedWith: allCategories },
  { context: 'system', allowedWith: allCategories },
  { context: 'name', allowedWith: allCategories },
  { context: 'payments', allowedWith: allCategories },
  { context: 'tags', allowedWith: allCategories },
  {
    context: 'payer',
    allowedWith: allCategories,
    requiredContext: [...code_context, 'drug'],
  },
  {
    context: 'site',
    allowedWith: allCategories,
    requiredContext: code_context,
  },
  {
    context: 'year',
    allowedWith: allCategories,
    requiredContext: [...code_context, 'drug'],
  },
];

const FilterForm = () => {
  const log = useLogging();
  const { prospectType, prospectFilters, setProspectFilters } =
    useProspectSearch();

  const applyFilter = (filter) => {
    let newFilterSet = [...prospectFilters];

    log.event('applyFilters', {
      context: newFilterSet.map((f) => f.title),
    });

    if (filter.context === 'year') {
      newFilterSet = newFilterSet.filter((f) => f.context !== 'year');
    }

    setProspectFilters([...newFilterSet, filter]);
  };

  const removeFilter = (targetFilter) => {
    setProspectFilters(
      prospectFilters.filter(
        (filter) =>
          filter.context != targetFilter.context ||
          filter.value != targetFilter.value
      )
    );
  };

  const fieldSet = categoryFields.map(({ context, ...categories }) => {
    if (context === 'name') return null;
    return (
      <Grid item sm={12} md={6} lg={4} key={`${prospectType}-${context}`}>
        <SearchBox
          type={prospectType}
          context={context}
          applyFilter={applyFilter}
          removeFilter={removeFilter}
          {...categories}
        />
      </Grid>
    );
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          mt: '0.75rem',
          borderRadius: 0,
          flexGrow: 1,
          background: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF9F 40%)',
          paddingBottom: '1rem',
        }}
      >
        <Grid container spacing={2} sx={{ px: 2, mt: 0 }}>
          {fieldSet}
        </Grid>
      </Paper>
    </Box>
  );
};

export default FilterForm;
