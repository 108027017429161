import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import {
  getSearchQueryParamsForAPI,
  serializeQueryArray,
} from 'src/utils/url.helpers';
import { useMemo } from 'react';
import _ from 'lodash';

const REQ_MAP = {
  years: 'year',
  categories: 'cat',
  codes: 'code',
};

interface AffiliationsDashboardProps extends MedScout.Sortable {
  providerId: number;
  mode?: 'code_volume' | 'sending' | 'receiving';
  enabledSearchIds?: string[];
  type: string;
  id: string;
  filters: any;
  dateRange?: {
    lte: string;
    gte: string;
  };
}

const useAffiliationsDashboard = ({
  providerId,
  mode = 'code_volume',
  enabledSearchIds = [],
  sort,
  order,
  sort_search,
  sort_value,
  type,
  id,
  filters,
  dateRange,
}: AffiliationsDashboardProps) => {
  const filterParams = useMemo(() => {
    if (!filters) return '';
    // TODO: We need to fix the keys in the app. Category vs Categories vs Cat. REQ_MAP is a stop gap
    const paramMap = _.reduce(
      filters,
      (acc, v: any, k) => {
        // TODO: This is a hack. Fix this too :( (see above)
        const key = REQ_MAP[k] || k;
        const value = Object.keys(v.selectedDict)
          .filter((key) => v.selectedDict[key])
          .map((key) => (isNaN(Number(key)) ? key : Number(key)));
        if (value) acc[key] = value;

        return acc;
      },
      {}
    );

    return paramMap;
  }, [filters]);

  let queryParams = getSearchQueryParamsForAPI({
    mode,
    sort,
    order,
    sort_search,
    sort_value,
    dateRange,
    ...filterParams,
  });

  const searchIdParams = enabledSearchIds
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    )
    .join('');

  queryParams += searchIdParams;

  const query = useQuery(
    [QUERY_KEYS.affiliations, providerId, type, mode, queryParams],
    () => {
      return request({
        url: `/v1/${type}/${id}/affiliations${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: !!id && !!type,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 60_000,
    }
  );
  return {
    ...query,
    getExport: async (file_format: 'csv' | 'xlsx', columns: string[]) => {
      const columnSelection = `?${serializeQueryArray('columns', columns)}`;
      const searchIdParams = enabledSearchIds
        .map(
          (id) =>
            `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
        )
        .join('');
      let queryParams = getSearchQueryParamsForAPI(
        {
          sort,
          order,
          sort_search,
          mode,
          file_format,
          dateRange,
        },
        columnSelection
      );

      queryParams += searchIdParams;

      return await request({
        url: `/v1/${type}/${id}/affiliations/export/${queryParams}`,
        method: 'GET',
        responseType: 'blob',
        rawResponse: true,
      }).then((response) => {
        // Extract filename from Content-Disposition header
        const filename = response?.headers['content-disposition']
          ?.split('filename=')[1]
          ?.split(';')[0]
          ?.replace(/["']/g, '');

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create an anchor element and set attributes for downloading
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename || 'export.csv'); // Use filename from Content-Disposition

        // Append to the document and trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        return filename;
      });
    },
  };
};

export default useAffiliationsDashboard;
