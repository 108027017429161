import {
  TextField,
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useController } from 'react-hook-form';
/**
 * @description Builds a dynamic input component
 * @returns HTMLElement
 */
interface DynamicInputProps {
  placeholder?: string;
  value?: any;
  disabled?: boolean;
  name: string;
  type: string;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  required?: boolean;
  hidden?: boolean;
  options?: Option[];
  min?: number;
  max?: number;
  read_only?: boolean;
  control: any;
  rules?: any;
  label?: string;
  size?: 'small' | 'medium';
}

interface Option {
  value?: string;
  label: string;
  code?: string;
}

const DynamicInput = ({ ...props }: DynamicInputProps) => {
  const { field } = useController({
    name: props.name,
    control: props.control,
    rules: {
      ...props.rules,
    },
    defaultValue: props.value,
  });

  switch (props.type) {
    case 'string':
      return (
        <TextField {...props} {...field} value={field.value || ''} fullWidth />
      );

    case 'integer':
      return (
        <TextField
          {...props}
          {...field}
          type="number"
          value={field?.value || 0}
          onChange={field.onChange}
          fullWidth
          InputProps={{
            inputProps: {
              min: props?.min || 0,
              max: props?.max || null,
            },
          }}
        />
      );
    case 'selector': {
      return (
        <Select
          {...props}
          variant={props.variant || 'standard'}
          {...field}
          displayEmpty
          value={field.value || ''} // Select does not like undefined values
          fullWidth
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                maxWidth: 200,
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            Select...
          </MenuItem>
          {props?.options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option?.label}
            </MenuItem>
          ))}
        </Select>
      );
    }
    case 'string_with_suggestions': {
      const value =
        props?.options?.find(
          (item) => item?.value === field.value || item?.code === field.value
        ) || null;
      return (
        <Autocomplete
          {...props}
          {...field}
          options={props?.options}
          // filterOptions={(x) => x}
          value={value || null}
          onChange={(event, value) => {
            field.onChange(value?.code || value?.value || null);
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.label === value?.label;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                fullWidth
                placeholder={props.placeholder || 'Select...'}
                variant={props.variant || 'standard'}
              />
            );
          }}
        />
      );
    }
    case 'boolean':
      return (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={field.value || false}
              onChange={field.onChange}
              disabled={props.disabled || false}
              size={props.size || 'small'}
            />
          }
          label={props.label}
        />
      );
    default:
      return <Typography aria-label="default case">{props.value}</Typography>;
  }
};

export default DynamicInput;
