import { useEffect, useState } from 'react';
import { useGetDataIssueSuggestions } from 'src/hooks';
import {
  Radio,
  RadioGroup,
  List,
  ListItem,
  FormLabel,
  FormHelperText,
  Chip,
  Grid,
} from '@mui/joy';
import { DataIssueAddressSearch } from './DataIssueAddressSearch';
import { SelectorLabel } from './DataIssueStyles';
import { DataIssueTypes, AddressType } from './types';

const SuggestionItem = ({ item }) => {
  return (
    <ListItem variant="outlined" key={item} sx={{ boxShadow: 'xs' }}>
      <Radio
        overlay
        value={item.id}
        slotProps={{
          action: ({ checked }) => ({
            sx: (theme) => ({
              ...(checked && {
                inset: -1,
                border: '2px solid',
                borderColor: theme.vars.palette.primary[500],
              }),
            }),
          }),
        }}
      />
      {
        {
          address: <AddressSuggestion addressObject={item} />,
          other: <OtherSuggestion />,
        }[item.suggestionType]
      }
    </ListItem>
  );
};

const AddressSuggestion = ({ addressObject }) => {
  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <Grid xs={6} md={8}>
        <FormLabel>{addressObject?.practice_name}</FormLabel>
        <FormLabel>{addressObject?.address_line_1}</FormLabel>
        {addressObject?.address_line_2 && (
          <FormHelperText>{addressObject?.address_line_2}</FormHelperText>
        )}
        <FormHelperText>
          {addressObject?.city}, {addressObject?.state}{' '}
          {addressObject?.zip_code}
        </FormHelperText>
      </Grid>
      <Grid xs={6} md={4}>
        <Grid container justifyContent="end">
          {addressObject?.is_primary && (
            <Chip variant="soft" color="primary" size="sm">
              Current Address
            </Chip>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const OtherSuggestion = () => {
  return (
    <div>
      <FormLabel>Other</FormLabel>
      <FormHelperText>Please specify below</FormHelperText>
    </div>
  );
};

interface DataIssueAddressSuggestionsProps {
  issueType: DataIssueTypes;
  providerId: string;
  providerType: string;
  dataIssue?: any;
  setAddressData: (value: any) => void;
}

const DataIssueAddressSuggestions = ({
  issueType,
  providerId,
  providerType,
  dataIssue,
  setAddressData,
}: DataIssueAddressSuggestionsProps) => {
  const { data = [], isLoading } = useGetDataIssueSuggestions({
    issueType,
    providerId,
    providerType,
  });

  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  const [addressId, setAddressId] = useState('');

  useEffect(() => {
    if (isLoading) return;

    if (dataIssue) {
      // if we have a data issue supplied, try to use it
      // otherwise select other
      if (dataIssue?.newData?.id) {
        setAddressId(dataIssue.newData.id);
      } else {
        setAddressId('other');
      }
    } else if (data.length > 0) {
      // no data issue supplied, preselect first suggestion
      setAddressId(data[0].id);
    } else {
      // no data issue supplied and no suggestions, select other
      setAddressId('other');
    }
  }, [dataIssue, isLoading, data]);

  // Update suggestedAddresses when data is loaded
  useEffect(() => {
    if (!isLoading) {
      setSuggestedAddresses(data);
    }
  }, [data, isLoading]);

  // when an address is selected, alert the parent to the change
  const handleChange = (selectionId, updatedAddresses = suggestedAddresses) => {
    let selectedAddress;

    if (selectionId !== 'other') {
      selectedAddress = updatedAddresses.find(
        (item) => String(item.id) === String(selectionId)
      );
    }
    setAddressId(selectionId);

    if (selectedAddress) {
      setAddressData({
        id: selectedAddress.id,
        address_line_1: selectedAddress.address_line_1,
        address_line_2: selectedAddress.address_line_2,
        city: selectedAddress.city,
        state: selectedAddress.state,
        zip_code: selectedAddress.zip_code,
        lat: selectedAddress.lat,
        lng: selectedAddress.lng,
        address_key: selectedAddress.address_key,
      } as AddressType);
    } else {
      setAddressData(null);
    }
  };

  // when an additional address is selected from search,
  // add it to the result list and select it
  const setAdditionalAddress = (addressData) => {
    const updatedAddresses = [...suggestedAddresses, addressData];
    setSuggestedAddresses(updatedAddresses);
    handleChange(addressData.id, updatedAddresses);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <SelectorLabel>Select or enter a correction:</SelectorLabel>
      <RadioGroup
        aria-label="Data Issue Suggestion Selector"
        name="suggestion"
        onChange={(e) => handleChange(e.target.value)}
        value={addressId}
      >
        <List
          sx={{
            minWidth: 240,
            '--List-gap': '0.5rem',
            '--ListItem-paddingY': '1rem',
            '--ListItem-radius': '8px',
            '--ListItemDecorator-size': '32px',
          }}
        >
          {suggestedAddresses.map((item, index) => (
            <SuggestionItem
              key={index}
              item={{ ...item, suggestionType: issueType }}
            />
          ))}
          <SuggestionItem item={{ id: 'other', suggestionType: 'other' }} />
        </List>
      </RadioGroup>
      {addressId === 'other' && (
        <DataIssueAddressSearch setAddressData={setAdditionalAddress} />
      )}
    </>
  );
};

export { DataIssueAddressSuggestions };
