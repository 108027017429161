import { Tags } from 'src/components';
import { Box } from '@mui/material';

const TagCell = ({ row }) => {
  const tags = row?.tags ? row.tags : row?.provider?.tags;
  if (!tags?.length) return null;

  const serializedTags = tags?.map((tag) => ({
    bgColor: tag.bg_color,
    fullText: tag.description,
    ...tag,
  }));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tags tags={serializedTags || []} tagType="tag" size="small" />
    </Box>
  );
};

export default TagCell;
