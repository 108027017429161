import React, { useCallback, useRef } from 'react';
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { Box, Dropdown, Menu, Typography } from '@mui/joy';
import { useOnClickOutside } from 'src/hooks';
import Tags, { ProviderListTags } from 'src/components/Tags';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';

type TreeMarkerProps = {
  feature: any;
  position: any;
  featureId: number;
  size: number;
  sizeAsText: string;
  isHovered?: boolean;
  onMarkerClick?: (marker: any, featureId: number) => void;
  onMouseOver?: (clusterId: number) => void;
  onMouseOut?: () => void;
  addToList?: (details: any) => void;
};

const FeatureMarker = ({
  feature,
  position,
  featureId,
  size,
  sizeAsText,
  isHovered = false,
  onMarkerClick,
  onMouseOut,
  onMouseOver,
  addToList,
}: TreeMarkerProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isPoint =
    feature.geometry?.type === 'Point' &&
    feature?.details &&
    !feature.properties.count;

  const handleClick = useCallback(
    () => onMarkerClick && onMarkerClick(marker!, featureId),
    [onMarkerClick, marker, featureId]
  );

  const handleHoverMenu = (event: React.MouseEvent<HTMLElement>, featureId) => {
    onMouseOver(featureId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onMouseOut();
    setAnchorEl(null);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <AdvancedMarker
      ref={markerRef}
      zIndex={size}
      position={position}
      onClick={() => {
        handleClick();
      }}
      style={{
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
    >
      <Box
        onMouseOver={(e) => handleHoverMenu(e, featureId)}
        onMouseLeave={onMouseOut}
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isHovered ? '#2391d9' : '#693cbd',
          color: 'white',
          border: '2px solid white',
        }}
      >
        {sizeAsText}
      </Box>
      <Dropdown>
        <Menu
          ref={menuRef}
          placement="top"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && isHovered && isPoint}
          sx={{
            boxShadow: 'sm',
            flexGrow: 0,
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0.5rem',
              borderBottom: '1px solid #dadde9',

              '&:last-of-type': {
                borderBottom: 'none',
              },
            }}
          >
            <Box>
              <a
                href={`/profiles/${feature?.details?.content_type}/${feature?.details?.provider_id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  width: '100%',
                }}
              >
                <Typography
                  level="title-sm"
                  color="primary"
                  sx={{
                    textDecoration: 'none',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                    '&:hover': {
                      color: 'primary.700',
                    },
                  }}
                >
                  {feature?.details?.name}
                  <Icon icon={faExternalLinkAlt} size="sm" color="inherit" />
                </Typography>
              </a>
              <Typography level="body-xs">
                {feature?.details?.specialty}
              </Typography>
              <Typography level="body-xs">
                {feature?.details?.address_line_1}
              </Typography>
              <Typography level="body-xs">
                {feature?.details?.address_line_2}
              </Typography>
              <Typography level="body-xs">
                {feature?.details?.city}, {feature?.details?.state}
              </Typography>
              <Tags tags={feature?.details?.tags} />
              <ProviderListTags lists={feature?.details?.provider_lists} />
              <Typography
                level="title-sm"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  addToList(feature?.details);
                }}
                sx={{
                  textDecoration: 'none',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.25rem',
                  '&:hover': {
                    cursor: 'pointer',
                    color: 'primary.700',
                  },
                }}
              >
                Add to List
              </Typography>
            </Box>
          </Box>
        </Menu>
      </Dropdown>
    </AdvancedMarker>
  );
};

export default FeatureMarker;
