import { styled, useTheme, Button } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Switch, SwitchProps, SwitchSlots } from '@mui/joy';
import { IconButton } from 'src/components';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

interface StyledSwitchProps extends SwitchProps {
  mode?: 'dark' | 'light';
}

const StyledSwitch = styled((props: StyledSwitchProps) => (
  <Switch {...props} />
))(({ theme, mode }) => ({
  padding: 0,
  margin: 4,
  width: '4rem',
  '& .MuiSwitch-track': {
    padding: '2px',
    width: '100%',
    border: `2px solid ${
      mode === 'light' ? theme.palette.action.hover : theme.palette.grey[400]
    }`,
    backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#39393D',
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.action.hover,
    transitionDuration: '300ms',
    transform:
      'translate(-var(--Switch-thumbWidth), -var(--Switch-thumbWidth))',
  },
  '&.Mui-checked .MuiSwitch-thumb': {
    transform:
      'translate(var(--Switch-trackWidth), -var(--Switch-thumbWidth)/2))',
  },
  '&.MuiSwitch-sizeMd': {
    '--Switch-trackWidth': '66px',
    '--Switch-thumbWidth': '22px',
    '--Switch-thumbHeight': '22px',
    '--Switch-thumbOffset': '5px',
    '& .MuiSwitch-thumb': {
      height: 'var(--Switch-thumbHeight)',
    },
    '& .MuiSwitch-track': {
      height: '32px',
    },
  },
  '&.MuiSwitch-sizeSm': {
    '--Switch-trackWidth': '45px',
    '--Switch-thumbWidth': '18px',
    '--Switch-thumbHeight': '18px',
    '--Switch-thumbOffset': '4px',
    '& .MuiSwitch-thumb': {
      height: 'var(--Switch-thumbHeight)',
    },
    '& .MuiSwitch-track': {
      height: '28px',
    },
  },
}));

const ToggleComponent = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem;
`;

interface ToggleProps {
  leftIcon: IconDefinition;
  rightIcon: IconDefinition;
  leftLabel: string;
  rightLabel: string;
  onLeft: () => void;
  onRight: () => void;
  showLabels?: boolean;
  status?: 'left' | 'right';
  mode?: 'dark' | 'light';
  compact?: boolean;
  slots?: SwitchSlots;
  sx?: SystemStyleObject;
}

/**
 * Toggle component used for switching between two binary states.
 *
 * params:
 * - props: ToggleProps
 */
const Toggle: React.FC<ToggleProps> = ({
  leftIcon,
  rightIcon,
  leftLabel,
  rightLabel,
  onLeft,
  onRight,
  showLabels = false,
  status = 'left',
  mode = 'light',
  compact = false,
  slots = {},
  sx = {},
}) => {
  const theme = useTheme();
  const isRight = status === 'right';

  const activeColor = mode === 'light' ? theme.palette.action.hover : 'white';
  const inactiveColor =
    mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[400];

  function toggleView(value: boolean) {
    if (value) {
      onRight();
    } else {
      onLeft();
    }

    return value;
  }

  return (
    <ToggleComponent>
      {showLabels ? (
        <Button
          endIcon={
            leftIcon ? (
              <Icon
                icon={leftIcon}
                color={!isRight ? activeColor : inactiveColor}
                size={compact ? 'xs' : 'sm'}
              />
            ) : null
          }
          onClick={() => toggleView(false)}
          sx={{
            color: !isRight ? activeColor : inactiveColor,
            px: 0,
            py: 0.25,
            fontSize: compact ? '0.75rem' : '0.85rem',
          }}
          aria-label={leftLabel}
        >
          {leftLabel}
        </Button>
      ) : (
        <IconButton
          icon={leftIcon || null}
          onClick={() => toggleView(false)}
          sx={{ color: !isRight ? activeColor : inactiveColor }}
          label={leftLabel}
          size={compact ? 'small' : 'medium'}
        />
      )}
      <StyledSwitch
        onChange={(e) => toggleView(e.target.checked)}
        checked={isRight}
        slots={slots}
        mode={mode}
        size={compact ? 'sm' : 'md'}
        sx={sx}
      />
      {showLabels ? (
        <Button
          startIcon={
            rightIcon ? (
              <Icon
                icon={rightIcon}
                color={isRight ? activeColor : inactiveColor}
                size={compact ? 'xs' : 'sm'}
              />
            ) : null
          }
          onClick={() => toggleView(true)}
          sx={{
            color: isRight ? activeColor : inactiveColor,
            px: 0,
            py: 0.25,
            fontSize: compact ? '0.75rem' : '0.85rem',
          }}
          aria-label={rightLabel}
        >
          {rightLabel}
        </Button>
      ) : (
        <IconButton
          icon={rightIcon || null}
          onClick={() => toggleView(true)}
          sx={{ color: isRight ? activeColor : inactiveColor }}
          label={rightLabel}
          size={compact ? 'small' : 'medium'}
        />
      )}
    </ToggleComponent>
  );
};

export default Toggle;
