import { useQuery } from 'react-query';
import { request } from 'src/api/request';

const fetchItem = async (id) => {
  const data = await request({
    url: `v1/accounts/territories/${id}`,
    method: 'GET',
  });
  return data;
};

const recursiveFetch = async (
  itemIds: string[],
  currentIndex = 0,
  accumulatedData = []
) => {
  if (currentIndex === itemIds.length) {
    return accumulatedData;
  }

  const data = await fetchItem(itemIds[currentIndex]);

  return recursiveFetch(itemIds, currentIndex + 1, [...accumulatedData, data]);
};

const useGetTerritoriesRecursive = (itemIds: string[]) => {
  return useQuery(['territories', itemIds], async () => {
    return recursiveFetch(itemIds);
  });
};

export default useGetTerritoriesRecursive;
