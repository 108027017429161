export const PROSPECT_OVERVIEW_QUERY_KEYS = {
  all_rep_prospects: 'all_rep_prospects',
  individual_rep_prospects: 'individual_rep_prospects',
  rep_prospect_lists: 'rep_prospect_lists',
  add_to_team: 'add_rep_to_team',
  remove_from_team: 'remove_rep_from_team',
};

export { default as useGetRepProspectLists } from './useGetRepProspectLists';

export const LISTS_QUERY_MAP = new Map([
  ['my_lists', 'my_lists'],
  ['prospect_lists', 'prospect_lists'],
  ['all_normalized_prospects', 'all_normalized_prospects'],
  ['notes', 'notes'],
]);
