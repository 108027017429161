import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControl } from '@mui/joy';

interface ControlledJoyCheckboxProps extends CheckboxProps {
  rules?: any;
  errorText?: string;
  disabled?: boolean;
}

const ControlledJoyCheckbox = ({ ...props }: ControlledJoyCheckboxProps) => {
  const { control } = useFormContext();

  if (!props) return null;

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => {
        return (
          <Checkbox
            {...props}
            checked={field.value || false}
            onChange={field.onChange}
            slotProps={{
              input: {
                'aria-label': `checkbox-${props.name}`,
              },
            }}
          />
        );
      }}
    />
  );
};

export default ControlledJoyCheckbox;
