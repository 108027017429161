import useSwr from 'swr';
import { getManagersReports } from './managersApi';
import { QUERY_KEY } from '../constants';

const useGetReports = ({
  companyId,
  managerId,
}: {
  companyId: string;
  managerId: string;
}) => {
  return useSwr(
    [QUERY_KEY.MANAGERS, companyId, managerId],
    () => getManagersReports(companyId, managerId),
    {
      keepPreviousData: true,
    }
  );
};

export default useGetReports;
