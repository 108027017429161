import { request } from 'src/api/request';

export const getLists = async () => {
  return await request({
    url: `/v1/provider-lists/lists/?pageSize=1000`, // accepts user_id
    method: 'GET',
  });
};

export const createList = async (listName: string) => {
  if (!listName) throw new Error('List name is required');

  return await request({
    url: `/v1/provider-lists/lists/`,
    method: 'POST',
    data: {
      name: listName,
    },
  });
};

export const addItemsToList = async (
  listId: number,
  providers: MedScout.Opportunity[]
) => {
  return await request({
    url: `/v1/provider-lists/lists/${listId}/items/`,
    method: 'POST',
    data: providers.map(({ provider_id, content_type }) => ({
      provider_id,
      type: content_type,
    })),
  });
};

//TODO: UPDATE and DELETE Functions
