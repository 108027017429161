import useSWR from 'swr';
import { useMedScoutMap } from 'src/context';
import { getBoundaries } from './medscoutmapapi';
import { QUERY_KEY } from './constants';

const useGetMedBoundaries = () => {
  const { bounds, drawingMode, isEditing, isCreating } = useMedScoutMap();

  const bounding_box = bounds;
  const shouldFetch = (!!isEditing || !!isCreating) && !!drawingMode;
  return useSWR(
    () =>
      shouldFetch
        ? [QUERY_KEY.GET_BOUNDARIES, bounding_box, drawingMode]
        : null,
    () =>
      getBoundaries({
        bounding_box,
        drawingMode,
      }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    }
  );
};

export default useGetMedBoundaries;
