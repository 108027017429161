import { styled, Typography } from '@mui/joy';

const SelectorLabel = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.125rem' /* 18px */,
  }));


export { SelectorLabel };