import React, { useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  Box,
  styled,
  Typography,
  Slide,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { ProspectSearchResult } from 'src/components';

// Hooks
import { useLogging } from 'src/context';
import { CircularProgress } from '@mui/joy';

const StyledListItem = styled(ListItem)({
  color: grey[400],
  paddingInline: 0,
  borderBottom: `1px solid ${grey[300]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: grey[300],
  },
});

const SearchResults = ({ results, isLoading, isSearching }) => {
  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  const hasValue = results?.length > 0 && isSearching && !isLoading;

  const theme = useTheme();
  const log = useLogging();

  useEffect(() => {
    hasValue && scrollRef.current.scrollTo(0, 0);
  }, [results, isSearching]);

  return (
    <Box ref={containerRef} sx={{ display: 'flex' }}>
      <Slide
        container={containerRef.current}
        in={isSearching}
        timeout={200}
        easing="cubic-bezier(0,0.75,0.25,1)"
      >
        <Box
          ref={scrollRef}
          sx={{
            display: isSearching ? 'flex' : 'none',
            flex: 1,
            position: 'absolute',
            right: 0,
            marginTop: '0.2rem',
            minWidth: '100%',
            maxHeight: '20rem',
            overflowY: 'auto',
            boxShadow: theme.shadows[6],
            backgroundColor: theme.palette.primary.light,
            borderTop: '6px solid rgb(51,213,192)',
            borderRadius: '0 0 0.25rem 0.25rem',
          }}
        >
          <List dense sx={{ height: '100%', flex: 1, paddingTop: '0.2rem' }}>
            {isLoading && isSearching && (
              <StyledListItem>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <CircularProgress size="sm" />
                </Box>
              </StyledListItem>
            )}
            {hasValue
              ? results.map((result, idx) => (
                  <StyledListItem
                    key={`searchResult-${idx}`}
                    onClick={() => {
                      log.event('Global Search Result Clicked', {
                        provider: result,
                      });
                    }}
                  >
                    <ProspectSearchResult result={result} variant="compact" />
                  </StyledListItem>
                ))
              : !isLoading && (
                  <StyledListItem>
                    <Typography
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '0.7rem',
                        color: grey[600],
                      }}
                    >
                      No results found
                    </Typography>
                  </StyledListItem>
                )}
          </List>
        </Box>
      </Slide>
    </Box>
  );
};

export default SearchResults;
