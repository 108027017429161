import { useEffect } from 'react';
import { Option, Select, Box, styled, Typography } from '@mui/joy';
import { useProspectSearch, useLogging } from 'src/context';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/pro-solid-svg-icons';

const SelectorLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

const VolumeEntityTypeToggle = () => {
  const log = useLogging();
  const { prospectType, setProspectType, prospectMode, setProspectMode } =
    useProspectSearch();
  useEffect(() => {
    // If coming back from a profile, etc and the mode is 'receiving', switch it to 'sending'
    // Receiving is not available here.
    if (prospectMode === 'receiving') setProspectMode('sending');
  });

  useEffect(() => {
    if (prospectType === 'C') setProspectMode('code_volume');
  }, [prospectType]);

  return (
    <Box sx={{ width: '100%' }}>
      <SelectorLabel>Entity Type</SelectorLabel>
      <Select
        defaultValue="HCP"
        value={prospectType === 'C' ? 'C' : 'HCP'}
        renderValue={(value) => {
          return (
            <Typography
              level="body-sm"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.25rem',
              }}
            >
              {value.value === 'HCP' ? (
                <>
                  <Icon
                    icon={faUserMd}
                    color="inherit"
                    style={{ marginLeft: '0.25rem', marginRight: '0.25rem' }}
                  />
                  <span>Providers</span>
                </>
              ) : (
                <>
                  <LocationCityRoundedIcon color="inherit" />
                  <span>Sites</span>
                </>
              )}
            </Typography>
          );
        }}
        onChange={(e, newValue) => {
          setProspectType(newValue as 'HCP' | 'C');
          log.event('Entity Type Changed', {
            value: newValue,
          });
        }}
        sx={{ width: '100%', minWidth: '140px', borderRadius: '0.5rem' }}
        slotProps={{
          listbox: {
            sx: {
              zIndex: 9999, // This would not show any other way on the result lists
            },
          },
        }}
        aria-label="Entity Type"
      >
        <Option value="HCP" aria-label="Providers">
          <Icon
            icon={faUserMd}
            color="inherit"
            style={{ marginLeft: '0.25rem', marginRight: '0.25rem' }}
          />
          Providers
        </Option>
        <Option value="C" aria-label="Sites">
          <LocationCityRoundedIcon /> Sites
        </Option>
      </Select>
    </Box>
  );
};

export default VolumeEntityTypeToggle;
