import { UseQueryResult, useQuery } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetAutopopulateOptionsParams {
  mappingId: string;
  sort?: string;
  order?: string;
}

interface GetAutoPopulateOptionsResponse extends MedScout.PaginatedResponse {
  results: MedScout.AutopopulateOption[];
}

const useGetMappingOptions = ({
  mappingId,
  sort,
  order,
  page,
  pageSize,
}: GetAutopopulateOptionsParams & MedScout.Pagination) => {
  const queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    page: page + 1,
    pageSize,
  });
  return useQuery(
    [INTEGRATION_KEYS.GET_AUTOPOPULATE_OPTIONS, mappingId, queryParams],
    () => {
      return request({
        url: `/v1/integrations/mappings/${mappingId}/options/${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: !!mappingId,
      refetchOnWindowFocus: false,
    }
  ) as UseQueryResult<GetAutoPopulateOptionsResponse>;
};

export default useGetMappingOptions;
