import React from 'react';
import { Box } from '@mui/joy';

const VolumeHeader = () => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'none',
      }}
    >
      {/* just leaving empty for now. */}
    </Box>
  );
};

export default VolumeHeader;
