import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField, FormHelperText } from '@mui/material';
import ControlLabel from './ControlLabel';

interface ControlledAutocompleteProps {
  name: string;
  label?: string;
  options?: any[];
  required?: boolean;
  rules?: any;
  placeholder?: string;
  loading?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  helperText?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  size?: 'small' | 'medium';
}

const ControlledAutocomplete = ({ ...props }: ControlledAutocompleteProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  if (!props) return null;
  const errorMessage = props?.helperText || 'This field is required';
  const required = props?.required || props.rules?.required;
  const watchValue = watch(props.name);
  const selectedValue = watchValue
    ? props.options?.find(
        (option) => option?.code === watchValue || option?.value === watchValue
      )
    : null;

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: props.required ? errorMessage : false,
        ...props.rules,
      }}
      render={({ field }) => {
        return (
          <ControlLabel
            label={props?.label || ''}
            required={required}
            hasTooltip={props.hasTooltip}
            tooltipText={props.tooltipText}
          >
            <Autocomplete
              {...field}
              loading={props.loading}
              size={props.size || 'medium'}
              options={props.options || []}
              value={selectedValue}
              onChange={(event, value) => {
                field.onChange(value?.code || value?.value || null);
              }}
              isOptionEqualToValue={(option, value) => {
                return (
                  option?.code === value?.code || option?.value === value?.value
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={props.variant || 'standard'}
                  fullWidth
                  placeholder={props.placeholder}
                  required={required}
                  error={!!errors[props.name]}
                />
              )}
            />
            <FormHelperText error sx={{ ml: 0 }}>
              {!!errors[props.name] && (errors[props.name]?.message as string)}
            </FormHelperText>
          </ControlLabel>
        );
      }}
    />
  );
};

export default ControlledAutocomplete;
