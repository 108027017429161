import _ from 'lodash';

export default class MapService {
  static getBoundsFromMapState(mapState) {
    let bounds = null;
    if (Array.isArray(mapState?.bounds)) return mapState?.bounds;
    if (!mapState || !mapState.bounds) return bounds;

    const isMapStateValid = Object.values(mapState?.bounds).every(
      (bound: any) => {
        return !_.isNil(bound.lng) && !_.isNil(bound.lat);
      }
    );

    if (isMapStateValid) {
      // repeat nw at the end to have a valid circular line string
      bounds = _.map(['nw', 'ne', 'se', 'sw', 'nw'], (directionKey) => {
        const { lng, lat } = mapState.bounds[directionKey];

        return [_.round(lng, 4), _.round(lat, 4)];
      });
    }

    return bounds;
  }
}
