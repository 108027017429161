import { useEffect, RefObject } from 'react';

function useClickOutside(
  menuRef: RefObject<HTMLElement>,
  anchorEl: HTMLElement | null,
  handleClose: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        anchorEl &&
        !anchorEl.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    if (anchorEl) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl, menuRef, handleClose]);
}

export default useClickOutside;
