import { formatPhoneNumber } from 'src/utils';

const PhoneCell = ({ row }) => {
  const formattedNumber = row.phone ? formatPhoneNumber(row.phone) : '';

  if (!formattedNumber) return null;

  return <a href={`tel:${formattedNumber}`}>{formattedNumber}</a>;
};

export default PhoneCell;
