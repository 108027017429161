import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  Dispatch,
} from 'react';

const CRMActionTypes: { [key: string]: string } = {
  SET_SELECTED_BUCKETS: 'SET_SELECTED_BUCKETS',
  SET_STATUS_FILTER_OPTIONS: 'SET_STATUS_FILTER_OPTIONS',
};

type CRMState = {
  selectedBuckets: string[];
  statusFilterOptions: string[];
};

type CRMActions = {
  type: keyof typeof CRMActionTypes;
  payload: {
    selectedBuckets?: CRMState['selectedBuckets'];
    statusFilterOptions?: CRMState['statusFilterOptions'];
  };
};

const initialState: CRMState = {
  selectedBuckets: [],
  statusFilterOptions: [],
};

const CRMContext = createContext<{
  state: CRMState;
  dispatch: Dispatch<CRMActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const crmReducer = (state: CRMState, action: CRMActions) => {
  switch (action.type) {
    case CRMActionTypes.SET_SELECTED_BUCKETS: {
      return {
        ...state,
        selectedBuckets: action.payload.selectedBuckets,
      };
    }

    case CRMActionTypes.SET_STATUS_FILTER_OPTIONS: {
      return {
        ...state,
        statusFilterOptions: action.payload.statusFilterOptions,
      };
    }

    default:
      return state;
  }
};

export const CRMProvider = ({ children }) => {
  const [state, dispatch] = useReducer(crmReducer, initialState);

  return (
    <CRMContext.Provider value={{ state, dispatch }}>
      {children}
    </CRMContext.Provider>
  );
};

export const useCRM = () => {
  const context = useContext(CRMContext);
  if (!context) {
    throw new Error('useCRM must be used within a CRMProvider');
  }
  const {
    state: { selectedBuckets, statusFilterOptions },
    dispatch,
  } = context;

  const setSelectedBuckets = (selectedBuckets: string[]) => {
    dispatch({
      type: CRMActionTypes.SET_SELECTED_BUCKETS,
      payload: { selectedBuckets },
    });
  };

  const setStatusFilterOptions = (statusFilterOptions: string[]) => {
    dispatch({
      type: CRMActionTypes.SET_STATUS_FILTER_OPTIONS,
      payload: { statusFilterOptions },
    });
  };

  return {
    selectedBuckets,
    statusFilterOptions,
    setSelectedBuckets,
    setStatusFilterOptions,
  };
};
