/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useReducer,
  useContext,
  ReactElement,
  useCallback,
} from 'react';
import { mapReducer } from './reducers';

export enum MAP_ACTIONS {
  ADD_TEMP_SELECTED_STATE = 'ADD_TEMP_SELECTED_STATE',
  ADD_TEMP_SELECTED_COUNTY = 'ADD_TEMP_SELECTED_COUNTY',
  ADD_TEMP_SELECTED_ZIP_CODE = 'ADD_TEMP_SELECTED_ZIP_CODE',
  ADD_TEMP_TERRITORY_COORDINATE = 'ADD_TEMP_TERRITORY_COORDINATE',
  EDIT_TERRITORY = 'EDIT_TERRITORY',
  REMOVE_TEMP_SELECTED_STATE = 'REMOVE_TEMP_SELECTED_STATE',
  REMOVE_TEMP_SELECTED_COUNTY = 'REMOVE_TEMP_SELECTED_COUNTY',
  REMOVE_TEMP_SELECTED_ZIP_CODE = 'REMOVE_TEMP_SELECTED_ZIP_CODE',
  REMOVE_TEMP_TERRITORY_COORDINATE = 'REMOVE_TEMP_TERRITORY_COORDINATE',
  SET_DRAWING_MANAGER_DRAW_MODE = 'SET_DRAWING_MANAGER_DRAW_MODE',
  SET_SELECT_TERRITORIES = 'SET_SELECT_TERRITORIES',
  TOGGLE_EDIT_TERRITORY_MODE = 'TOGGLE_EDIT_TERRITORY_MODE',
  CLEAR_OVERLAY = 'CLEAR_OVERLAY',
  BOUNDARIES_LOADING = 'BOUNDARIES_LOADING',
  TERRITORIES_LOADING = 'TERRITORIES_LOADING',
  SET_EXISTING_TERRITORIES = 'SET_EXISTING_TERRITORIES',
}

export type MapStateType = {
  createNewTerritoryMode: boolean;
  drawingManagerDrawMode: string;
  editingTerritory: MedScout.Territory;
  selectedTerritories: MedScout.Territory[];
  existingTerritories: MedScout.Territory[];
  tempSelectedStates: string[];
  tempSelectedCounties: string[];
  tempSelectedZipCodes: string[];
  tempTerritoryCoordinates: string[];
  clearOverlay: boolean;
  boundariesLoading?: boolean;
  territoriesLoading?: boolean;
};

export type TempObjectType = {
  items?: string[] | any[]; // I know this is not right, but because of coordinates..
  removeAll?: boolean;
};

export type ReducerAction = {
  type: MAP_ACTIONS;
  payload?: any;
};

export interface UseMapType {
  clearOverlay: boolean;
  createNewTerritoryMode: boolean;
  drawingManagerDrawMode: string;
  editingTerritory: MedScout.Territory;
  selectedTerritories: MedScout.Territory[];
  existingTerritories: MedScout.Territory[];
  tempSelectedStates: string[];
  tempSelectedCounties: string[];
  tempSelectedZipCodes: string[];
  tempTerritoryCoordinates: string[] | any[];
  boundariesLoading?: boolean;
  territoriesLoading?: boolean;
  setSelectTerritories: (
    territories: MedScout.Territory[],
    preventEditingAndCreationReset?: boolean
  ) => void;
  setExistingTerritories?: (territories: MedScout.Territory[]) => void;
  addTempSelectedStates: ({ items }: TempObjectType) => void;
  addTempSelectedCounties: ({ items }: TempObjectType) => void;
  addTempSelectedZipCodes: ({ items }: TempObjectType) => void;
  addTempTerritoryCoordinates: ({ items }: TempObjectType) => void;
  editTerritory: (territory: MedScout.Territory) => void;
  removeTempSelectedStates: ({ items, removeAll }: TempObjectType) => void;
  removeTempSelectedCounties: ({ items, removeAll }: TempObjectType) => void;
  removeTempSelectedZipCodes: ({ items, removeAll }: TempObjectType) => void;
  removeTempTerritoryCoordinates: ({
    items,
    removeAll,
  }: TempObjectType) => void;
  toggleCreateTerritoryMode: (enabled: boolean) => void;
  setDrawingManagerDrawMode: (value: string) => void;
  setClearOverlay: (value: boolean) => void;
  setBoundariesLoading?: (value: boolean) => void;
  setTerritoriesLoading?: (value: boolean) => void;
}

export const initialState: MapStateType = {
  createNewTerritoryMode: false,
  drawingManagerDrawMode: 'MOVE',
  editingTerritory: null,
  selectedTerritories: [],
  existingTerritories: [],
  tempSelectedStates: [],
  tempSelectedCounties: [],
  tempSelectedZipCodes: [],
  tempTerritoryCoordinates: [],
  clearOverlay: false,
  boundariesLoading: false,
  territoriesLoading: false,
};

const {
  ADD_TEMP_SELECTED_STATE,
  ADD_TEMP_SELECTED_COUNTY,
  ADD_TEMP_SELECTED_ZIP_CODE,
  ADD_TEMP_TERRITORY_COORDINATE,
  EDIT_TERRITORY,
  REMOVE_TEMP_SELECTED_STATE,
  REMOVE_TEMP_SELECTED_COUNTY,
  REMOVE_TEMP_SELECTED_ZIP_CODE,
  REMOVE_TEMP_TERRITORY_COORDINATE,
  SET_DRAWING_MANAGER_DRAW_MODE,
  SET_SELECT_TERRITORIES,
  TOGGLE_EDIT_TERRITORY_MODE,
  CLEAR_OVERLAY,
  BOUNDARIES_LOADING,
  TERRITORIES_LOADING,
  SET_EXISTING_TERRITORIES,
} = MAP_ACTIONS;

const useMapContext = () => {
  const [state, dispatch] = useReducer(mapReducer, initialState);

  const setDrawingManagerDrawMode = useCallback(
    (value) =>
      dispatch({ type: SET_DRAWING_MANAGER_DRAW_MODE, payload: value }),
    []
  );

  const setSelectTerritories = useCallback(
    (
      territories: MedScout.Territory[],
      preventEditingAndCreationReset = false
    ) =>
      dispatch({
        type: SET_SELECT_TERRITORIES,
        payload: { territories, preventEditingAndCreationReset },
      }),
    []
  );

  const setExistingTerritories = useCallback(
    (territories: MedScout.Territory[]) =>
      dispatch({ type: SET_EXISTING_TERRITORIES, payload: territories }),
    []
  );

  const addTempTerritoryCoordinates = useCallback(
    ({ items = [] }) =>
      dispatch({
        type: ADD_TEMP_TERRITORY_COORDINATE,
        payload: items,
      }),
    []
  );

  const removeTempTerritoryCoordinates = useCallback(
    ({ items = [], removeAll = false }) =>
      dispatch({
        type: REMOVE_TEMP_TERRITORY_COORDINATE,
        payload: { items, removeAll },
      }),
    []
  );

  const addTempSelectedStates = useCallback(
    ({ items = [] }) =>
      dispatch({
        type: ADD_TEMP_SELECTED_STATE,
        payload: items,
      }),
    []
  );

  const removeTempSelectedStates = useCallback(
    ({ items = [], removeAll = false }) =>
      dispatch({
        type: REMOVE_TEMP_SELECTED_STATE,
        payload: { items, removeAll },
      }),
    []
  );

  const addTempSelectedCounties = useCallback(
    ({ items = [] }) =>
      dispatch({
        type: ADD_TEMP_SELECTED_COUNTY,
        payload: items,
      }),
    []
  );

  const removeTempSelectedCounties = useCallback(
    ({ items = [], removeAll = false }) =>
      dispatch({
        type: REMOVE_TEMP_SELECTED_COUNTY,
        payload: { items, removeAll },
      }),
    []
  );

  const addTempSelectedZipCodes = useCallback(
    ({ items = [] }) =>
      dispatch({
        type: ADD_TEMP_SELECTED_ZIP_CODE,
        payload: items,
      }),
    []
  );

  const removeTempSelectedZipCodes = useCallback(
    ({ items = [], removeAll = false }) =>
      dispatch({
        type: REMOVE_TEMP_SELECTED_ZIP_CODE,
        payload: { items, removeAll },
      }),
    []
  );

  const toggleCreateTerritoryMode = useCallback(
    (enabled: boolean) =>
      dispatch({
        type: TOGGLE_EDIT_TERRITORY_MODE,
        payload: enabled,
      }),
    []
  );

  const editTerritory = useCallback(
    (territory: MedScout.Territory) =>
      dispatch({ type: EDIT_TERRITORY, payload: territory }),
    []
  );

  const setClearOverlay = useCallback((value) => {
    dispatch({ type: CLEAR_OVERLAY, payload: value });
  }, []);

  const setBoundariesLoading = useCallback((value: boolean) => {
    dispatch({ type: BOUNDARIES_LOADING, payload: value });
  }, []);

  const setTerritoriesLoading = useCallback((value: boolean) => {
    dispatch({ type: TERRITORIES_LOADING, payload: value });
  }, []);

  return {
    state,
    addTempSelectedStates,
    addTempSelectedCounties,
    addTempSelectedZipCodes,
    addTempTerritoryCoordinates,
    editTerritory,
    removeTempSelectedStates,
    removeTempSelectedCounties,
    removeTempSelectedZipCodes,
    removeTempTerritoryCoordinates,
    setDrawingManagerDrawMode,
    setSelectTerritories,
    toggleCreateTerritoryMode,
    setClearOverlay,
    setBoundariesLoading,
    setTerritoriesLoading,
    setExistingTerritories,
  };
};

type UseMapContextType = ReturnType<typeof useMapContext>;

const initContextState: UseMapContextType = {
  state: initialState,
  addTempSelectedStates: () => {},
  addTempSelectedCounties: () => {},
  addTempSelectedZipCodes: () => {},
  addTempTerritoryCoordinates: () => {},
  editTerritory: () => {},
  removeTempSelectedStates: () => {},
  removeTempSelectedCounties: () => {},
  removeTempSelectedZipCodes: () => {},
  removeTempTerritoryCoordinates: () => {},
  setDrawingManagerDrawMode: () => {},
  setSelectTerritories: () => {},
  toggleCreateTerritoryMode: () => {},
  setClearOverlay: () => {},
  setBoundariesLoading: () => {},
  setTerritoriesLoading: () => {},
  setExistingTerritories: () => {},
};

export const MapContext = createContext<UseMapContextType>(initContextState);

export const MapProvider = ({ children }): ReactElement => {
  return (
    <MapContext.Provider value={useMapContext()}>
      {children}
    </MapContext.Provider>
  );
};

// Hook: Where the magic happens
export const useDiscoveryMap = (): UseMapType => {
  const context = useContext(MapContext);
  if (!context) throw new Error('useMap must be wrapped in a MapProvider');
  const { state, ...actions } = context;
  return {
    ...actions,
    createNewTerritoryMode: state.createNewTerritoryMode,
    drawingManagerDrawMode: state.drawingManagerDrawMode,
    editingTerritory: state.editingTerritory,
    selectedTerritories: state.selectedTerritories,
    tempSelectedStates: state.tempSelectedStates,
    tempSelectedCounties: state.tempSelectedCounties,
    tempSelectedZipCodes: state.tempSelectedZipCodes,
    tempTerritoryCoordinates: state.tempTerritoryCoordinates,
    clearOverlay: state.clearOverlay,
    boundariesLoading: state.boundariesLoading,
    territoriesLoading: state.territoriesLoading,
    existingTerritories: state.existingTerritories,
  };
};
