import { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useGetAllUsers } from 'src/hooks';
import { Controller } from 'react-hook-form';
import { useAuth } from 'src/context';

interface UserSelectorProps {
  control;
  id: string;
  allUsers?: boolean;
}

const UserSelector = ({ control, id }: UserSelectorProps) => {
  const { user } = useAuth();

  const { data: companyUsers, isLoading } = useGetAllUsers(
    user?.company?.id?.toString()
  );
  const team: { id; label }[] = useMemo(() => {
    if (!companyUsers) return [];

    return companyUsers
      ?.filter((u) => u.id !== user?.id)
      ?.map(({ id, first_name, last_name }) => ({
        id,
        label: `${first_name} ${last_name}`,
      }));
  }, [companyUsers, user]);

  return (
    <Controller
      name={id}
      control={control}
      render={({ formState: { errors }, field: { onChange, onBlur } }) => (
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              label={isLoading ? 'Loading...' : 'Select a team member'}
              helperText={errors[id]?.message}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <li {...props} key={option.id} value={option.id}>
              {option.label}
            </li>
          )}
          options={team}
          onChange={(e: React.ChangeEvent<HTMLLIElement>) => {
            onChange(e.target.value);
          }}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default UserSelector;
