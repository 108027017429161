import React, { useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Modal,
  ModalClose,
  Sheet,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Checkbox,
} from '@mui/joy';
import {
  ControlledJoyInput,
  ControlledJoyCheckbox,
  ControlledJoySelect,
} from 'src/components/ControlledFields';
import { useOnClickOutside } from 'src/hooks';
import { useLogging, useNotification, useDashboard } from 'src/context';
import { useGetManagers, createUser, updateUser } from 'src/hooks';
import { grey } from '@mui/material/colors';

interface AddUserDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * @description     AddUserDialog component is used to display the add/edit user dialog in the admin dashboard
 * @param param0    open, onClose, user, companyId
 * @returns         AddUserDialog component
 */
const AddUserDialog = ({ ...props }: AddUserDialogProps) => {
  const menuRef = useRef(null);
  const log = useLogging();
  const { setNotification } = useNotification();
  const { showAddEditUserModal } = useDashboard();

  const { data: managers, mutate } = useGetManagers(
    showAddEditUserModal?.company
  );

  const sortedManagers = useMemo(() => {
    if (!managers?.results) return;
    return managers?.results?.sort((a, b) => {
      if (a.first_name < b.first_name) {
        return -1;
      }
      if (a.first_name > b.first_name) {
        return 1;
      }
      return;
    });
  }, [managers?.results]);

  const [isLoading, setIsLoading] = useState(false);

  // get value label pairs for user select
  const userIdValues = useMemo(() => {
    return managers?.results?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });
  }, [managers]);

  const methods = useForm({
    values: {
      first_name: '',
      last_name: '',
      username: '',
      is_manager: false,
      managers: [],
      is_lir_eligible: false,
      is_integration_admin: false,
      invite_now: false,
      is_able_to_view_all_territories: false,
    },
  });

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty, isValid },
  } = methods;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async (data: MedScout.User) => {
    setIsLoading(true);
    try {
      await createUser(showAddEditUserModal?.company, data);
      mutate();

      log.event('User created', {
        source: 'AddUserDialog',
        companyId: showAddEditUserModal?.company,
        data,
      });
      setNotification({
        title: 'Success',
        message: 'User created',
        type: 'success',
      });
    } catch (err) {
      log.exception('Error creating user', err);
      setNotification({
        title: 'Error',
        message: 'Error creating user',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      handleCancel();
    }
  };

  const handleCancel = () => {
    reset();
    props.onClose();
  };

  const handleChange = (e, field, user) => {
    const currentUserManagers = getValues('managers');
    field.onChange(
      e.target.checked
        ? [...currentUserManagers, user.id]
        : currentUserManagers.filter((report) => report !== user.id)
    );
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={showAddEditUserModal?.show}
      onClose={props.onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h4" sx={{ mb: 2 }}>
              {showAddEditUserModal?.user ? 'Edit' : 'Add'} User
            </Typography>
            <Box sx={{ display: 'flex', gap: '0.5rem', mb: '0.5rem' }}>
              <Box>
                <Typography
                  level="body-sm"
                  sx={{ mb: '0.5rem', fontWeight: 500 }}
                >
                  Firstname
                </Typography>
                <ControlledJoyInput
                  name="first_name"
                  placeholder="Firstname"
                  rules={{ required: 'This field is required' }}
                  fullWidth
                />
              </Box>
              <Box>
                <Typography
                  level="body-sm"
                  sx={{ mb: '0.5rem', fontWeight: 500 }}
                >
                  Lastname
                </Typography>
                <ControlledJoyInput
                  name="last_name"
                  placeholder="Lastname"
                  rules={{ required: 'This field is required' }}
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: '0.5rem',
              }}
            >
              <Typography
                level="body-sm"
                sx={{ mb: '0.5rem', fontWeight: 500 }}
              >
                Email (username)
              </Typography>
              <ControlledJoyInput
                name="username"
                placeholder="Email"
                rules={{ required: 'This field is required' }}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: '0.5rem',
              }}
            >
              <Typography
                level="body-sm"
                sx={{ mb: '0.5rem', fontWeight: 500 }}
              >
                Managers
              </Typography>
              <Box
                sx={{
                  height: '10rem',
                  maxHeight: '100%',
                  overflow: 'hidden',
                  overflowY: 'auto',
                  border: `1px solid ${grey[300]}`,
                  borderRadius: 'md',
                  padding: '0.5rem',
                }}
                aria-label="managers"
              >
                {sortedManagers?.map((user, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0.125rem 0',
                    }}
                  >
                    <Typography level="body-sm">
                      {`${user.first_name} ${user.last_name}`}
                    </Typography>
                    <Controller
                      name="managers"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Checkbox
                            size="sm"
                            checked={field.value?.includes(user.id)}
                            onChange={(e) => handleChange(e, field, user)}
                          />
                        );
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ padding: '0.25rem 0', mb: '0.5rem' }}>
              <List
                size="sm"
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  border: `1px solid ${grey[300]}`,
                  boxShadow: 'xs',
                }}
              >
                <ListItem>
                  <ControlledJoyCheckbox size="sm" name="is_manager" />
                  <Typography level="body-sm" sx={{ fontWeight: 500 }}>
                    Is Manager
                  </Typography>
                </ListItem>
                <ListItem>
                  <ControlledJoyCheckbox size="sm" name="is_lir_eligible" />
                  <Typography level="body-sm" sx={{ fontWeight: 500 }}>
                    LIR Eligible
                  </Typography>
                </ListItem>

                <ListItem>
                  <ControlledJoyCheckbox
                    size="sm"
                    name="is_integration_admin"
                  />
                  <Typography level="body-sm" sx={{ fontWeight: 500 }}>
                    Integration Admin
                  </Typography>
                </ListItem>
                <ListItem>
                  <ControlledJoyCheckbox size="sm" name="invite_now" />
                  <Typography level="body-sm" sx={{ fontWeight: 500 }}>
                    Invite now
                  </Typography>
                </ListItem>
                <ListItem>
                  <ControlledJoyCheckbox
                    size="sm"
                    name="is_able_to_view_all_territories"
                  />
                  <Typography level="body-sm" sx={{ fontWeight: 500 }}>
                    View all territories
                  </Typography>
                </ListItem>
              </List>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
                gap: '0.5rem',
              }}
            >
              <Button variant="outlined" color="neutral" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                loading={isLoading}
                type="submit"
                variant="solid"
                color="primary"
                disabled={!isDirty || !isValid}
              >
                {showAddEditUserModal?.user ? 'Update User' : 'Create User'}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Sheet>
    </Modal>
  );
};

export default AddUserDialog;
