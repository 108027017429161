import useSwr from 'swr';
import { getManagers } from './managersApi';
import { QUERY_KEY } from '../constants';

const useGetManagers = (companyId: string) => {
  return useSwr([QUERY_KEY.MANAGERS, companyId], () => getManagers(companyId), {
    keepPreviousData: true,
  });
};

export default useGetManagers;
